const locationsData = [
  {
    service_id: 'beijingyizhuangdeliveryhubnew',
    title: '北京亦庄交付中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 3056,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D北京市大兴区亦庄经济开发区中和街9号特斯拉交付中心',
    city: '北京',
    province: '北京',
    country_code: 'CN',
    map_address: '北京市大兴区亦庄经济开发区中和街9号特斯拉交付中心',
    address_line_1: '北京市大兴区亦庄经济开发区中和街9号特斯拉交付中心',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '北京亦庄交付中心',
    metro_area: '',
  },
  {
    service_id: 'bejingxiedaodelivery',
    title: '北京蟹岛特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 16509,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D北京市朝阳区金盏乡蟹岛路一号4号展厅',
    city: '北京',
    province: '北京',
    country_code: 'CN',
    map_address: '北京市朝阳区金盏乡蟹岛路一号4号展厅',
    address_line_1: '北京市朝阳区金盏乡蟹岛路一号4号展厅',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '北京蟹岛特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'shanghaiminhangdelivery',
    title: '上海闵行交付中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 16899,
    gmaps_url: 'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D上海市闵行区曲吴路600号',
    city: '上海',
    province: '上海',
    country_code: 'CN',
    map_address: '上海市闵行区曲吴路600号',
    address_line_1: '上海市闵行区曲吴路600号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '上海闵行交付中心',
    metro_area: '',
  },
  {
    service_id: 'shanghaisenlandelivery',
    title: '上海森兰交付中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 14441,
    gmaps_url: 'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D上海市浦东新区津行路580号',
    city: '上海',
    province: '上海',
    country_code: 'CN',
    map_address: '上海市浦东新区津行路580号',
    address_line_1: '上海市浦东新区津行路580号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery'],
    chinese_title: '上海森兰交付中心',
    metro_area: '',
  },
  {
    service_id: 'dongguandelivery',
    title: '东莞交付中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 16898,
    gmaps_url: 'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D广东省东莞市高埗镇高埗大道296号',
    city: '东莞',
    province: '广东',
    country_code: 'CN',
    map_address: '广东省东莞市高埗镇高埗大道296号',
    address_line_1: '广东省东莞市高埗镇高埗大道296号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '东莞交付中心',
    metro_area: '',
  },
  {
    service_id: 'foshanshundedelivery',
    title: '佛山顺德特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 15920,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D广东省佛山市顺德区大良广珠公路新松路段新协力汽车城',
    city: '佛山',
    province: '广东',
    country_code: 'CN',
    map_address: '广东省佛山市顺德区大良广珠公路新松路段新协力汽车城',
    address_line_1: '广东省佛山市顺德区大良广珠公路新松路段新协力汽车城',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '佛山顺德特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'guangzhoukexuecheng',
    title: '广州科学城特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 8914,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D广东省广州市黄埔区科学城南翔一路50号新银河世纪跨境电商产业园南门',
    city: '广州',
    province: '广东',
    country_code: 'CN',
    map_address: '广东省广州市黄埔区科学城南翔一路50号新银河世纪跨境电商产业园南门',
    address_line_1: '广东省广州市黄埔区科学城南翔一路50号新银河世纪跨境电商产业园南门',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '广州科学城特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'huizhouhuichengdelivery',
    title: '惠州惠城特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 14980,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D广东省惠州市惠城区汝湖镇东亚过沥村（惠民大道广惠高速汝湖出口）',
    city: '惠州',
    province: '广东',
    country_code: 'CN',
    map_address: '广东省惠州市惠城区汝湖镇东亚过沥村（惠民大道广惠高速汝湖出口）',
    address_line_1: '广东省惠州市惠城区汝湖镇东亚过沥村（惠民大道广惠高速汝湖出口）',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '惠州惠城特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'jiangmenpengjiangdelivery',
    title: '江门蓬江特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 14979,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D广东省江门市蓬江区建设三路143号2幢',
    city: '江门',
    province: '广东',
    country_code: 'CN',
    map_address: '广东省江门市蓬江区建设三路143号2幢',
    address_line_1: '广东省江门市蓬江区建设三路143号2幢',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '江门蓬江特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'shantougaoxindelivery',
    title: '汕头高新特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 17793,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D广东省汕头市高新区东片13DE地块（科技西路16号）',
    city: '汕头',
    province: '广东',
    country_code: 'CN',
    map_address: '广东省汕头市高新区东片13DE地块（科技西路16号）',
    address_line_1: '广东省汕头市高新区东片13DE地块（科技西路16号）',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '汕头高新特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'shenzhenchiwandelivery',
    title: '深圳赤湾交付中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 11873,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D广东省深圳市南山区招商街道赤湾社区赤湾四路三号A5库（赤湾一号）A07',
    city: '深圳',
    province: '广东',
    country_code: 'CN',
    map_address: '广东省深圳市南山区招商街道赤湾社区赤湾四路三号A5库（赤湾一号）A07',
    address_line_1: '广东省深圳市南山区招商街道赤湾社区赤湾四路三号A5库（赤湾一号）A07',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '深圳赤湾交付中心',
    metro_area: '',
  },
  {
    service_id: 'shenzhenlonggangdelivery',
    title: '深圳龙岗交付中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 18020,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D广东省深圳市龙岗区吉华街道甘李二路11号中海信创新产业城19栋B1-B2',
    city: '深圳',
    province: '广东',
    country_code: 'CN',
    map_address: '广东省深圳市龙岗区吉华街道甘李二路11号中海信创新产业城19栋B1-B2',
    address_line_1: '广东省深圳市龙岗区吉华街道甘李二路11号中海信创新产业城19栋B1-B2',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '深圳龙岗交付中心',
    metro_area: '',
  },
  {
    service_id: 'zhanjiangmazhangdelivery',
    title: '湛江特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 409348,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D广东省湛江市麻章区瑞云北路101号之十六',
    city: '湛江',
    province: '广东',
    country_code: 'CN',
    map_address: '广东省湛江市麻章区瑞云北路101号之十六',
    address_line_1: '广东省湛江市麻章区瑞云北路101号之十六',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '湛江特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'zhongshanchengnandelivery',
    title: '中山城南特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 4814,
    gmaps_url: 'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D广东省中山市城南四路1号',
    city: '中山',
    province: '广东',
    country_code: 'CN',
    map_address: '广东省中山市城南四路1号',
    address_line_1: '广东省中山市城南四路1号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '中山城南特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'zhuhaijinwandelivery',
    title: '珠海金湾特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 28284,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D广东省珠海市金湾区红旗镇珠海大道6698号',
    city: '珠海',
    province: '广东',
    country_code: 'CN',
    map_address: '广东省珠海市金湾区红旗镇珠海大道6698号',
    address_line_1: '广东省珠海市金湾区红旗镇珠海大道6698号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '珠海金湾特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'hangzhounorthdelivery',
    title: '杭州城北交付中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 12167,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D浙江省杭州市余杭区莫干山路1803号',
    city: '杭州',
    province: '浙江',
    country_code: 'CN',
    map_address: '浙江省杭州市余杭区莫干山路1803号',
    address_line_1: '浙江省杭州市余杭区莫干山路1803号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '杭州城北交付中心',
    metro_area: '',
  },
  {
    service_id: 'hangzhouxiaoshandelivery',
    title: '杭州萧山交付中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 20553,
    gmaps_url: 'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D浙江省杭州市萧山区宁围镇宁新村',
    city: '杭州',
    province: '浙江',
    country_code: 'CN',
    map_address: '浙江省杭州市萧山区宁围镇宁新村',
    address_line_1: '浙江省杭州市萧山区宁围镇宁新村',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '杭州萧山交付中心',
    metro_area: '',
  },
  {
    service_id: 'huzhouwuxingdelivery',
    title: '湖州吴兴特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 13860,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D浙江省湖州市吴兴区杭长桥路1158号',
    city: '湖州',
    province: '浙江',
    country_code: 'CN',
    map_address: '浙江省湖州市吴兴区杭长桥路1158号',
    address_line_1: '浙江省湖州市吴兴区杭长桥路1158号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '湖州吴兴特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'jiaxingnanhudelivery',
    title: '嘉兴南湖特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 12911,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D浙江省嘉兴市南湖区中环南路汽车商贸园南江路1402号',
    city: '嘉兴',
    province: '浙江',
    country_code: 'CN',
    map_address: '浙江省嘉兴市南湖区中环南路汽车商贸园南江路1402号',
    address_line_1: '浙江省嘉兴市南湖区中环南路汽车商贸园南江路1402号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '嘉兴南湖特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'ningboyinzhoudelivery',
    title: '宁波鄞州特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 15681,
    gmaps_url: 'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D浙江省宁波市鄞州区下应北路717号',
    city: '宁波',
    province: '浙江',
    country_code: 'CN',
    map_address: '浙江省宁波市鄞州区下应北路717号',
    address_line_1: '浙江省宁波市鄞州区下应北路717号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '宁波鄞州特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'shaoxingpaojiangdelivery',
    title: '绍兴袍江特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 14632,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D浙江省绍兴市越城区斗门街道群贤东路9号6幢101号',
    city: '绍兴',
    province: '浙江',
    country_code: 'CN',
    map_address: '浙江省绍兴市越城区斗门街道群贤东路9号6幢101号',
    address_line_1: '浙江省绍兴市越城区斗门街道群贤东路9号6幢101号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '绍兴袍江特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'taizhoufanglindelivery',
    title: '台州路桥特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 8860,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D浙江省台州市路桥区路南街道方林汽车城东展示厅1-9号',
    city: '台州',
    province: '浙江',
    country_code: 'CN',
    map_address: '浙江省台州市路桥区路南街道方林汽车城东展示厅1-9号',
    address_line_1: '浙江省台州市路桥区路南街道方林汽车城东展示厅1-9号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '台州路桥特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'wenzhoulongwandelivery',
    title: '温州龙湾特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 4026,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D浙江省温州市龙湾区蒲州街道高一路178号',
    city: '温州',
    province: '浙江',
    country_code: 'CN',
    map_address: '浙江省温州市龙湾区蒲州街道高一路178号',
    address_line_1: '浙江省温州市龙湾区蒲州街道高一路178号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '温州龙湾特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'yiwubeiyuandelivery',
    title: '义乌北苑特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 8859,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D浙江省金华市义乌市北苑街道北苑路351号',
    city: '金华',
    province: '浙江',
    country_code: 'CN',
    map_address: '浙江省金华市义乌市北苑街道北苑路351号',
    address_line_1: '浙江省金华市义乌市北苑街道北苑路351号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '义乌北苑特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'changzhouwujindelivery',
    title: '常州武进特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 26202,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D江苏省常州市武进区湖塘镇武进汽车城C区',
    city: '常州',
    province: '江苏',
    country_code: 'CN',
    map_address: '江苏省常州市武进区湖塘镇武进汽车城C区',
    address_line_1: '江苏省常州市武进区湖塘镇武进汽车城C区',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '常州武进特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'nanjinglukoudelivery',
    title: '南京禄口交付中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 16512,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D江苏省南京市江宁区空港区博爱路向东20米',
    city: '南京',
    province: '江苏',
    country_code: 'CN',
    map_address: '江苏省南京市江宁区空港区博爱路向东20米',
    address_line_1: '江苏省南京市江宁区空港区博爱路向东20米',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '南京禄口交付中心',
    metro_area: '',
  },
  {
    service_id: 'nantongshenchengdelivery',
    title: '南通申成交付中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 26071,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D江苏省南通市开发区东方汽车城驰程路11号',
    city: '南通',
    province: '江苏',
    country_code: 'CN',
    map_address: '江苏省南通市开发区东方汽车城驰程路11号',
    address_line_1: '江苏省南通市开发区东方汽车城驰程路11号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '南通申成交付中心',
    metro_area: '',
  },
  {
    service_id: 'suzhouxiangchengdelivery',
    title: '苏州相城交付中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 17639,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D江苏省苏州市相城区元和街道太阳路2266号4号楼、6号楼',
    city: '苏州',
    province: '江苏',
    country_code: 'CN',
    map_address: '江苏省苏州市相城区元和街道太阳路2266号4号楼、6号楼',
    address_line_1: '江苏省苏州市相城区元和街道太阳路2266号4号楼、6号楼',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '苏州相城交付中心',
    metro_area: '',
  },
  {
    service_id: 'taizhouyongdingdelivery',
    title: '泰州永定特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 14633,
    gmaps_url: 'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D江苏省泰州市海陵区永定东路299号',
    city: '泰州',
    province: '江苏',
    country_code: 'CN',
    map_address: '江苏省泰州市海陵区永定东路299号',
    address_line_1: '江苏省泰州市海陵区永定东路299号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '泰州永定特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'wuxijianghaiwestroaddelivery',
    title: '无锡江海西路特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 26206,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D江苏省无锡市惠山区江海西路1105号',
    city: '无锡',
    province: '江苏',
    country_code: 'CN',
    map_address: '江苏省无锡市惠山区江海西路1105号',
    address_line_1: '江苏省无锡市惠山区江海西路1105号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '无锡江海西路特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'xuzhouguloudelivery',
    title: '徐州鼓楼特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 14753,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D江苏省徐州市徐州经济技术开发区三环东路32号',
    city: '徐州',
    province: '江苏',
    country_code: 'CN',
    map_address: '江苏省徐州市徐州经济技术开发区三环东路32号',
    address_line_1: '江苏省徐州市徐州经济技术开发区三环东路32号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '徐州鼓楼特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'yanchengtinghudelivery',
    title: '盐城亭湖特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 27781,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D江苏省盐城市经济技术开发区希望大道南路9号13幢',
    city: '盐城',
    province: '江苏',
    country_code: 'CN',
    map_address: '江苏省盐城市经济技术开发区希望大道南路9号13幢',
    address_line_1: '江苏省盐城市经济技术开发区希望大道南路9号13幢',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '盐城亭湖特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'yangzhouheyedelivery',
    title: '扬州荷叶特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 14634,
    gmaps_url: 'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D江苏省扬州市邗江区荷叶东路128号',
    city: '扬州',
    province: '江苏',
    country_code: 'CN',
    map_address: '江苏省扬州市邗江区荷叶东路128号',
    address_line_1: '江苏省扬州市邗江区荷叶东路128号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '扬州荷叶特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'zhenjiangdingmaodelivery',
    title: '镇江丁卯特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 27784,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D江苏省镇江市镇江新区丁卯武将路8号',
    city: '镇江',
    province: '江苏',
    country_code: 'CN',
    map_address: '江苏省镇江市镇江新区丁卯武将路8号',
    address_line_1: '江苏省镇江市镇江新区丁卯武将路8号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '镇江丁卯特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'chengdulongquandelivery',
    title: '成都龙泉交付中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 14962,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D四川省成都市龙泉驿区经开区南五路66号',
    city: '成都',
    province: '四川',
    country_code: 'CN',
    map_address: '四川省成都市龙泉驿区经开区南五路66号',
    address_line_1: '四川省成都市龙泉驿区经开区南五路66号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '成都龙泉交付中心',
    metro_area: '',
  },
  {
    service_id: 'leshansujidelivery',
    title: '乐山苏稽特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 415164,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D四川省乐山市市中区苏稽镇乐峨路906号',
    city: '乐山',
    province: '四川',
    country_code: 'CN',
    map_address: '四川省乐山市市中区苏稽镇乐峨路906号',
    address_line_1: '四川省乐山市市中区苏稽镇乐峨路906号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '乐山苏稽特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'nanchonggaopingdelivery',
    title: '南充高坪特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 416711,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D四川省南充市高坪区东顺路三段220号',
    city: '南充',
    province: '四川',
    country_code: 'CN',
    map_address: '四川省南充市高坪区东顺路三段220号',
    address_line_1: '四川省南充市高坪区东顺路三段220号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '南充高坪特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'fuzhoucangshandelivery',
    title: '福州仓山特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 12923,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D福建省福州市仓山区盖山投资区高旺路6号',
    city: '福州',
    province: '福建',
    country_code: 'CN',
    map_address: '福建省福州市仓山区盖山投资区高旺路6号',
    address_line_1: '福建省福州市仓山区盖山投资区高旺路6号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '福州仓山特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'quanzhounanhuandelivery',
    title: '泉州南环特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 26552,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D福建省泉州市南安市霞美镇霞美东路168号',
    city: '泉州',
    province: '福建',
    country_code: 'CN',
    map_address: '福建省泉州市南安市霞美镇霞美东路168号',
    address_line_1: '福建省泉州市南安市霞美镇霞美东路168号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '泉州南环特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'xiamenjimeidelivery',
    title: '厦门集美交付中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 8868,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D福建省厦门市集美区岩隆路汽车城5号楼',
    city: '厦门',
    province: '福建',
    country_code: 'CN',
    map_address: '福建省厦门市集美区岩隆路汽车城5号楼',
    address_line_1: '福建省厦门市集美区岩隆路汽车城5号楼',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '厦门集美交付中心',
    metro_area: '',
  },
  {
    service_id: 'chongqinglijiadelivery',
    title: '重庆礼嘉交付中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 16555,
    gmaps_url: 'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D重庆市两江新区礼洁路10号',
    city: '重庆',
    province: '重庆',
    country_code: 'CN',
    map_address: '重庆市两江新区礼洁路10号',
    address_line_1: '重庆市两江新区礼洁路10号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '重庆礼嘉交付中心',
    metro_area: '',
  },
  {
    service_id: 'xiandelivery',
    title: '西安交付中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 16552,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D陕西省西安市长安区普丰路620号1幢',
    city: '西安',
    province: '陕西',
    country_code: 'CN',
    map_address: '陕西省西安市长安区普丰路620号1幢',
    address_line_1: '陕西省西安市长安区普丰路620号1幢',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '西安交付中心',
    metro_area: '',
  },
  {
    service_id: 'jinanfengqiroaddelivery',
    title: '济南凤歧路特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 28787,
    gmaps_url: 'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D山东省济南市历城区凤岐路西100米',
    city: '济南',
    province: '山东',
    country_code: 'CN',
    map_address: '山东省济南市历城区凤岐路西100米',
    address_line_1: '山东省济南市历城区凤岐路西100米',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '济南凤歧路特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'jiningjishuiroaddelivery',
    title: '济宁济水大道特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 35248,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D山东省济宁市任城区内环高架路美恒R26济宁国际汽车博览城',
    city: '济宁',
    province: '山东',
    country_code: 'CN',
    map_address: '山东省济宁市任城区内环高架路美恒R26济宁国际汽车博览城',
    address_line_1: '山东省济宁市任城区内环高架路美恒R26济宁国际汽车博览城',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '济宁济水大道特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'linyishuanglingroaddelivery',
    title: '临沂双岭路特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 35738,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D山东省临沂市兰山区西中环与双岭路交汇处南1公里路东远东新能源汽车市场9号门',
    city: '临沂',
    province: '山东',
    country_code: 'CN',
    map_address: '山东省临沂市兰山区西中环与双岭路交汇处南1公里路东远东新能源汽车市场9号门',
    address_line_1: '山东省临沂市兰山区西中环与双岭路交汇处南1公里路东远东新能源汽车市场9号门',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '临沂双岭路特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'qingdaoyinchuanwestroaddelivery',
    title: '青岛银川西路特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 12916,
    gmaps_url: 'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D山东省青岛市市南区银川西路29号',
    city: '青岛',
    province: '山东',
    country_code: 'CN',
    map_address: '山东省青岛市市南区银川西路29号',
    address_line_1: '山东省青岛市市南区银川西路29号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '青岛银川西路特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'weifangdelivery',
    title: '潍坊特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 14625,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D山东省潍坊市潍城经济开发区北宫西街7007号',
    city: '潍坊',
    province: '山东',
    country_code: 'CN',
    map_address: '山东省潍坊市潍城经济开发区北宫西街7007号',
    address_line_1: '山东省潍坊市潍城经济开发区北宫西街7007号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '潍坊特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'yantaidelivery',
    title: '烟台特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 9542,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D山东省烟台市芝罘区卧龙工业园卧龙中路',
    city: '烟台',
    province: '山东',
    country_code: 'CN',
    map_address: '山东省烟台市芝罘区卧龙工业园卧龙中路',
    address_line_1: '山东省烟台市芝罘区卧龙工业园卧龙中路',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '烟台特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'ziboshanquanroaddelivery',
    title: '淄博山泉路特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 30922,
    gmaps_url: 'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D山东省淄博市张店区山泉路68号',
    city: '淄博',
    province: '山东',
    country_code: 'CN',
    map_address: '山东省淄博市张店区山泉路68号',
    address_line_1: '山东省淄博市张店区山泉路68号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '淄博山泉路特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'wuhanzhongfaxinchengdelivery',
    title: '武汉中法新城交付中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 16996,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D湖北省武汉市蔡甸区蔡甸街石洋、幺铺村嘉禾集团蔡甸产业园6号车间',
    city: '武汉',
    province: '湖北',
    country_code: 'CN',
    map_address: '湖北省武汉市蔡甸区蔡甸街石洋、幺铺村嘉禾集团蔡甸产业园6号车间',
    address_line_1: '湖北省武汉市蔡甸区蔡甸街石洋、幺铺村嘉禾集团蔡甸产业园6号车间',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '武汉中法新城交付中心',
    metro_area: '',
  },
  {
    service_id: 'yichangwujiagangdelivery',
    title: '宜昌伍家岗特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 404156,
    gmaps_url: 'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D湖北省宜昌市伍家岗区东艳路48号',
    city: '宜昌',
    province: '湖北',
    country_code: 'CN',
    map_address: '湖北省宜昌市伍家岗区东艳路48号',
    address_line_1: '湖北省宜昌市伍家岗区东艳路48号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '宜昌伍家岗特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'tianjindeliveryhub',
    title: '天津旺港特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 3516,
    gmaps_url: 'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D天津市津南区旺港路5号',
    city: '天津',
    province: '天津',
    country_code: 'CN',
    map_address: '天津市津南区旺港路5号',
    address_line_1: '天津市津南区旺港路5号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery'],
    chinese_title: '天津旺港特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'luoyangjiansheroaddelivery',
    title: '洛阳建设路特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 56441,
    gmaps_url: 'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D河南省洛阳市涧西区建设路152号',
    city: '洛阳',
    province: '河南',
    country_code: 'CN',
    map_address: '河南省洛阳市涧西区建设路152号',
    address_line_1: '河南省洛阳市涧西区建设路152号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '洛阳建设路特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'zhengzhouqianchengdelivery',
    title: '郑州前程交付中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 18523,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D河南省郑州市经济技术开发区前程大道339号7号厂房B区',
    city: '郑州',
    province: '河南',
    country_code: 'CN',
    map_address: '河南省郑州市经济技术开发区前程大道339号7号厂房B区',
    address_line_1: '河南省郑州市经济技术开发区前程大道339号7号厂房B区',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '郑州前程交付中心',
    metro_area: '',
  },
  {
    service_id: 'changshaqingzhuhudelivery',
    title: '长沙青竹湖交付中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 16995,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D湖南省长沙市开福区青竹湖国际会展中心399号',
    city: '长沙',
    province: '湖南',
    country_code: 'CN',
    map_address: '湖南省长沙市开福区青竹湖国际会展中心399号',
    address_line_1: '湖南省长沙市开福区青竹湖国际会展中心399号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '长沙青竹湖交付中心',
    metro_area: '',
  },
  {
    service_id: 'dalianxishanhudelivery',
    title: '大连西山湖特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 8888,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D辽宁省大连市甘井子区红旗西路棠梨工业园9号',
    city: '大连',
    province: '辽宁',
    country_code: 'CN',
    map_address: '辽宁省大连市甘井子区红旗西路棠梨工业园9号',
    address_line_1: '辽宁省大连市甘井子区红旗西路棠梨工业园9号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '大连西山湖特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'shenyanglianhevrl',
    title: '沈阳联合路特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 8866,
    gmaps_url: 'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D辽宁省沈阳市大东区联合路187号',
    city: '沈阳',
    province: '辽宁',
    country_code: 'CN',
    map_address: '辽宁省沈阳市大东区联合路187号',
    address_line_1: '辽宁省沈阳市大东区联合路187号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '沈阳联合路特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'nanningwuxiangdelivery',
    title: '南宁五象交付中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 16510,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D广西壮族自治区南宁市良庆区五象新区东风路50号',
    city: '南宁',
    province: '广西',
    country_code: 'CN',
    map_address: '广西壮族自治区南宁市良庆区五象新区东风路50号',
    address_line_1: '广西壮族自治区南宁市良庆区五象新区东风路50号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '南宁五象交付中心',
    metro_area: '',
  },
  {
    service_id: 'ganzhougannandelivery',
    title: '赣州赣南特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 31161,
    gmaps_url: 'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D江西省赣州市南康区赣南汽车城',
    city: '赣州',
    province: '江西',
    country_code: 'CN',
    map_address: '江西省赣州市南康区赣南汽车城',
    address_line_1: '江西省赣州市南康区赣南汽车城',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '赣州赣南特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'nanchanghengwangdelivery',
    title: '南昌恒望特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 8898,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D江西省南昌市新建区望城新区新兴西大道139号',
    city: '南昌',
    province: '江西',
    country_code: 'CN',
    map_address: '江西省南昌市新建区望城新区新兴西大道139号',
    address_line_1: '江西省南昌市新建区望城新区新兴西大道139号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '南昌恒望特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'haikoulonghuadelivery',
    title: '海口龙华特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 27783,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D海南省海口市龙华区金盘工业开发区金盘路2号海南海马汽车有限公司装配联合厂房西侧',
    city: '海口',
    province: '海南',
    country_code: 'CN',
    map_address: '海南省海口市龙华区金盘工业开发区金盘路2号海南海马汽车有限公司装配联合厂房西侧',
    address_line_1: '海南省海口市龙华区金盘工业开发区金盘路2号海南海马汽车有限公司装配联合厂房西侧',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '海口龙华特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'sanyadelivery',
    title: '三亚特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 20662,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D海南省三亚市吉阳区海润路火车头东三街诚越新能源汽车产业园4号馆',
    city: '三亚',
    province: '海南',
    country_code: 'CN',
    map_address: '海南省三亚市吉阳区海润路火车头东三街诚越新能源汽车产业园4号馆',
    address_line_1: '海南省三亚市吉阳区海润路火车头东三街诚越新能源汽车产业园4号馆',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '三亚特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'kunmingjingkaidelivery',
    title: '昆明经开特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 25759,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D云南自由贸易试验区昆明片区经开区出口加工区A6-1-2号（宝泽路41号）2栋',
    city: '昆明',
    province: '云南',
    country_code: 'CN',
    map_address: '云南自由贸易试验区昆明片区经开区出口加工区A6-1-2号（宝泽路41号）2栋',
    address_line_1: '云南自由贸易试验区昆明片区经开区出口加工区A6-1-2号（宝泽路41号）2栋',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '昆明经开特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'baodingdelivery',
    title: '保定特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 13858,
    gmaps_url: 'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D河北省保定市莲池区长城路1485号',
    city: '保定',
    province: '河北',
    country_code: 'CN',
    map_address: '河北省保定市莲池区长城路1485号',
    address_line_1: '河北省保定市莲池区长城路1485号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '保定特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'langfangdelivery',
    title: '廊坊特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 14627,
    gmaps_url: 'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D河北省廊坊市广阳区东环路19号',
    city: '廊坊',
    province: '河北',
    country_code: 'CN',
    map_address: '河北省廊坊市广阳区东环路19号',
    address_line_1: '河北省廊坊市广阳区东环路19号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '廊坊特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'shijiazhuangchangandelivery',
    title: '石家庄长安特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 416505,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D河北省石家庄市长安区太行北大街55号长安国际汽车产业园E-1栋',
    city: '石家庄',
    province: '河北',
    country_code: 'CN',
    map_address: '河北省石家庄市长安区太行北大街55号长安国际汽车产业园E-1栋',
    address_line_1: '河北省石家庄市长安区太行北大街55号长安国际汽车产业园E-1栋',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '石家庄长安特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'tangshankaiyueroaddelivery',
    title: '唐山开越路特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 30920,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D河北省唐山市开平区开越路东侧586号',
    city: '唐山',
    province: '河北',
    country_code: 'CN',
    map_address: '河北省唐山市开平区开越路东侧586号',
    address_line_1: '河北省唐山市开平区开越路东侧586号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '唐山开越路特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'xingtailonggangdelivery',
    title: '邢台龙岗特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 409459,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D河北省邢台市邢东新区龙岗大街与永安路交叉口西北角',
    city: '邢台',
    province: '河北',
    country_code: 'CN',
    map_address: '河北省邢台市邢东新区龙岗大街与永安路交叉口西北角',
    address_line_1: '河北省邢台市邢东新区龙岗大街与永安路交叉口西北角',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '邢台龙岗特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'taiyuandelivery',
    title: '太原特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 14750,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D山西省太原市马练营路59号新汇众汽车园',
    city: '太原',
    province: '山西',
    country_code: 'CN',
    map_address: '山西省太原市马练营路59号新汇众汽车园',
    address_line_1: '山西省太原市马练营路59号新汇众汽车园',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '太原特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'hefeifeidongdelivery',
    title: '合肥肥东交付中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 8861,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D安徽省合肥市肥东县经济开发区包公大道与护城路交口南侧1号',
    city: '合肥',
    province: '安徽',
    country_code: 'CN',
    map_address: '安徽省合肥市肥东县经济开发区包公大道与护城路交口南侧1号',
    address_line_1: '安徽省合肥市肥东县经济开发区包公大道与护城路交口南侧1号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '合肥肥东交付中心',
    metro_area: '',
  },
  {
    service_id: 'hohhothaixiroaddelivery',
    title: '呼和浩特海西路特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 26475,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D内蒙古自治区呼和浩特市回民区海拉尔西街恒茂汽车中心',
    city: '呼和浩特',
    province: '内蒙古',
    country_code: 'CN',
    map_address: '内蒙古自治区呼和浩特市回民区海拉尔西街恒茂汽车中心',
    address_line_1: '内蒙古自治区呼和浩特市回民区海拉尔西街恒茂汽车中心',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '呼和浩特海西路特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'changchunjingyuedelivery',
    title: '长春净月特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 14628,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D吉林省长春市净月高新技术产业发展区天普路488号',
    city: '长春',
    province: '吉林',
    country_code: 'CN',
    map_address: '吉林省长春市净月高新技术产业发展区天普路488号',
    address_line_1: '吉林省长春市净月高新技术产业发展区天普路488号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '长春净月特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'harbindelivery',
    title: '哈尔滨特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 14629,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D黑龙江省哈尔滨市道外区先锋路与黄家崴子交口哈得力园区',
    city: '哈尔滨',
    province: '黑龙江',
    country_code: 'CN',
    map_address: '黑龙江省哈尔滨市道外区先锋路与黄家崴子交口哈得力园区',
    address_line_1: '黑龙江省哈尔滨市道外区先锋路与黄家崴子交口哈得力园区',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '哈尔滨特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'guiyangmengguandelivery',
    title: '贵阳孟关特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 28771,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D贵州省贵阳市花溪区孟关汽车城红星路2号',
    city: '贵阳',
    province: '贵州',
    country_code: 'CN',
    map_address: '贵州省贵阳市花溪区孟关汽车城红星路2号',
    address_line_1: '贵州省贵阳市花溪区孟关汽车城红星路2号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '贵阳孟关特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'zunyihuichuandelivery',
    title: '遵义汇川特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 402109,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D贵州省遵义市汇川区遵义国际汽车城二期',
    city: '遵义',
    province: '贵州',
    country_code: 'CN',
    map_address: '贵州省遵义市汇川区遵义国际汽车城二期',
    address_line_1: '贵州省遵义市汇川区遵义国际汽车城二期',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '遵义汇川特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'lanzhouanningdelivery',
    title: '兰州安宁特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 16895,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D甘肃省兰州市安宁区北滨河西路1746之9号安宁汽车城',
    city: '兰州',
    province: '甘肃',
    country_code: 'CN',
    map_address: '甘肃省兰州市安宁区北滨河西路1746之9号安宁汽车城',
    address_line_1: '甘肃省兰州市安宁区北滨河西路1746之9号安宁汽车城',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '兰州安宁特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'yinchuanjinfengdelivery',
    title: '银川金凤特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 16896,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D宁夏省银川市经开区金凤工业园创业街88号',
    city: '银川',
    province: '宁夏',
    country_code: 'CN',
    map_address: '宁夏省银川市经开区金凤工业园创业街88号',
    address_line_1: '宁夏省银川市经开区金凤工业园创业街88号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '银川金凤特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'urumqidelivery',
    title: '乌鲁木齐特斯拉中心',
    appointment_location_id: null,
    distance: null,
    non_customer_location: '0',
    trt_id: 20162,
    gmaps_url:
      'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D新疆维吾尔自治区乌鲁木齐市米东区振兴中路1725号',
    city: '乌鲁木齐',
    province: '新疆',
    country_code: 'CN',
    map_address: '新疆维吾尔自治区乌鲁木齐市米东区振兴中路1725号',
    address_line_1: '新疆维吾尔自治区乌鲁木齐市米东区振兴中路1725号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '',
    store_hours: '',
    timezone: null,
    location_type: ['Delivery', 'Logistics'],
    chinese_title: '乌鲁木齐特斯拉中心',
    metro_area: '',
  },
  {
    service_id: 'xiningkunluneastroaddelivery',
    title: '西宁昆仑东路特斯拉中心',
    distance: 0,
    non_customer_location: '0',
    trt_id: 409314,
    gmaps_url: 'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D城东区金丰路1号',
    city: '西宁',
    province: '青海',
    country_code: 'CN',
    map_address: '城东区金丰路1号',
    address_line_1: '城东区金丰路1号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '810015',
    store_hours: '',
    common_name: '西宁昆仑东路特斯拉中心',
    timezone: null,
    location_type: ['Delivery', 'Service', 'Store'],
    metro_area: null,
    latitude: '36.56232',
    longitude: '101.87009',
    chinese_title: '西宁昆仑东路特斯拉中心',
  },
  {
    service_id: 'yixingrongda',
    title: '宜兴融达特斯拉中心',
    distance: 0,
    non_customer_location: '0',
    trt_id: 31121,
    gmaps_url: 'http://map.baidu.com/?newmap=1&ie=utf-8&s=s%26wd%3D江苏省宜兴市西氿大道97号',
    city: '宜兴',
    province: '江苏',
    country_code: 'CN',
    map_address: '江苏省宜兴市西氿大道97号',
    address_line_1: '江苏省宜兴市西氿大道97号',
    address_line_2: '',
    address_description: '',
    country: '中国大陆',
    postal_code: '214200',
    store_hours: '',
    common_name: '宜兴融达特斯拉中心',
    timezone: null,
    location_type: ['Delivery', 'Service', 'Store'],
    metro_area: null,
    latitude: '31.388682',
    longitude: '119.79093',
    chinese_title: '宜兴融达特斯拉中心',
  },
];

const defaultSelectedPickupLocationList = [
  14441, //	上海	Greater CN-CN-Shanghai Senlan Delivery Center
  25759, //	昆明	Greater CN-CN-Kunming Jingkai Tesla Center
  26475, //	呼和浩特	Greater CN-CN-Hohhot Haixi Road Tesla Center
  16509, //	北京	Greater CN-CN-Beijing Xiedao Tesla Center
  14628, //	长春	Greater CN-CN-Changchun Jingyue Tesla Center
  14962, //	成都	Greater CN-CN-Chengdu Longquan Delivery Center
  3516, //	天津	APAC-CN-Tianjin-Wanggang Service
  16896, //	银川	Greater CN-CN-Yinchuan Jinfeng Tesla Center
  8861, //	合肥	Greater CN-CN-Hefei Feidong Delivery Center
  28787, //	济南	Greater CN-CN-Jinan Fengqi Road Tesla Center
  14750, //	太原	Greater CN-CN-Taiyuan Tesla Center
  8914, //	广州	APAC-CN-Guangzhou-Kexuecheng Tesla Center
  16510, //	南宁	Greater CN-CN-Nanning Wuxiang Delivery Center
  20162, //	乌鲁木齐	Greater CN-CN-Urumqi Tesla Center
  16512, //	南京	Greater CN-CN-Nanjing Lukou Delivery Center
  8898, //	南昌	Greater CN-CN-Nanchang Hengwang Tesla Center
  416505, //	石家庄	Greater CN-CN-Shijiazhuang ChangAn Tesla Center
  18523, //	郑州	Greater CN-CN-Zhengzhou Qiancheng Delivery Center
  12167, //	杭州	Greater CN-CN-Hangzhou North Delivery Center
  27783, //	海口	Greater CN-CN-Haikou Longhua Tesla Center
  16996, //	武汉	Greater CN-CN-Wuhan Zhongfa Xincheng Delivery Center
  16995, //	长沙	Greater CN-CN-Changsha Qingzhuhu Delivery Center
  16895, //	兰州	Greater CN-CN-Lanzhou Anning Tesla Center
  12923, //	福州	Greater CN-CN-Fuzhou Cangshan Tesla Center
  28771, //	贵阳	Greater CN-CN-Guiyang Mengguan Tesla Center
  418302, //	沈阳	APAC-CN-Shenyang-Shenbei Rd Tesla Center
  16555, //	重庆	Greater CN-CN-Chongqing Lijia Delivery Center
  16552, //	西安	Greater CN-CN-Xi'an Delivery Center
  409314, //	西宁	Greater CN-CN-Xining Kunlun East Road Tesla Center
  14629, //	哈尔滨	Greater CN-CN-Harbin Tesla Center
];

export { locationsData, defaultSelectedPickupLocationList };

import React from 'react';
import cx from 'classnames';
import { string, number } from 'prop-types';
import { OrderSummary, formatCurrency, formatPercent } from '@tesla/coin-common-components';

import { connect } from 'react-redux';
import { processEnterpriseOrder } from 'actions';
import { i18n, addRegionalFeesToList } from 'utils';
import {
  getDestinationAndDocFee,
  getFeeAmountById,
  getVATAmount,
  getConfigurationOptions,
  getTrimTotal,
  getDepositAmount,
  getGovernmentIncentives,
  getSelectedAccessoriesList,
  getAccessoriesList,
  getSavedConfiguration,
  getSavedAccessories,
  getBasePlusTrimPrice,
  getRN,
  getModelName,
  getLuxuryTax,
  getInspectionFee,
  getRegionalIncentiveInPurchasePrice,
  getInnovationBonus,
} from 'selectors';
import {
  ORDER_FEE_TYPE,
  FEE_AQUISITION,
  FEE_DELIVERY,
  FEE_FIRST_REGISTRATION,
  FEE_REGISTRATION,
  TAX_REGISTRATION,
  REGIONAL_TIRE_FEE,
  DELIVERY_METHOD_PO,
} from 'dictionary';
import { PaymentTermsLegalDisclaimers } from '../PaymentOverview/Sections/LegalDisclaimers';
import AssetLoader from '../../components/AssetLoader';
import TextLoader from '../../components/TextLoader';
import EmirateDropdown from '../../components/EmirateDropdown';
import ExtraIncentives from '../PaymentOverview/ExtraIncentives';
import EditDeliveryLocation from '../PaymentOverview/Sections/EditDeliveryLocation';
import RegistrationTypeSection from '../PaymentOverview/Sections/RegistrationTypeSection';
import Analytics from 'analytics';

const VatCaption = ({ percentage, amount }) => {
  if (!percentage && !amount) return null;

  return (
    <span>
      {i18n('Enterprise.orderSummary.vatPercentage', {
        VAT_PERCENTAGE: formatPercent(percentage, 0),
        VAT_AMOUNT: formatCurrency(amount),
      })}
    </span>
  );
};

VatCaption.propTypes = {
  percentage: number,
  amount: number,
};

VatCaption.defaultProps = {
  percentage: 0,
  amount: 0,
};

const getEditDesignProps = state => {
  const savedConfiguration = getSavedConfiguration(state);
  const savedAccessories = getSavedAccessories(state);
  const reservationNumber = getRN(state);
  const purchaseOrderNumber = savedConfiguration?.config?.purchaseOrderNumber;
  const baseConfiguration = getTrimTotal(state);
  const asset = state?.CustomGroups?.BATTERY_AND_DRIVE?.asset;
  const adaptedAsset = { ...asset, size: 400, zoom: 1.5 };
  const isPoEditDesign = state?.App?.isEnterprisePurchaseOrder || false;
  const figure = {
    title: getModelName(state),
    asset: <AssetLoader asset={adaptedAsset} />,
    captions: [
      isPoEditDesign
        ? i18n('Enterprise.purchase_order', { NUMBER: purchaseOrderNumber })
        : reservationNumber,
    ],
  };
  let subtotal = getBasePlusTrimPrice(state);
  const itemizedOptions = getConfigurationOptions(state).map(option => {
    subtotal += option?.price || 0;
    return {
      label: (
        <TextLoader
          data={option}
          field="long_name"
          tag={{
            component: 'span',
            props: {},
          }}
        />
      ),
      value: option?.formattedPrice,
    };
  });

  return {
    figure,
    itemizedOptions: [
      ...baseConfiguration,
      ...itemizedOptions,
      {
        label: (
          <span className="tds-text--medium tds-text--contrast-high">
            {i18n(
              `Enterprise.${savedAccessories.length ? 'subtotal_vehicle' : 'orderSummary.subtotal'}`
            )}
          </span>
        ),
        value: (
          <span className="tds-text--medium tds-text--contrast-high">{formatCurrency(subtotal)}</span>
        ),
      },
    ],
  };
};

const mapStateToProps = state => {
  const {
    Pricing,
    ReviewDetails,
    SummaryPanel,
    App: { query_params: { deliveryMethod = '' } = {}, enableCyberpunk = false } = {},
    FinancingOptions,
    Financial,
  } = state;
  const { cash, total: subtotalExcVat } = Pricing;
  let { grossPrice } = Pricing;
  const {
    extraIncentives = [],
    formatWithPrecision = false,
    DeliveryLocations: { selected: trt = {}, compoundLocations = [] } = {},
    DeliveryContact,
    hideExtraIncentivesInOrderSummary = false,
  } = ReviewDetails;
  const {
    showRegistrationAboveSummary = false,
    showOrderFee,
    showRegionalTaxTypes,
  } = FinancingOptions;
  const { fees = {} } = Financial || {};
  const { current: currentFees = {} } = fees;
  const { regional: regionalFees = [] } = currentFees || {};
  const regionalFeesValues = showRegionalTaxTypes ? regionalFees[0] || null : null;
  const { totalsRounding = {}, region_code: region, showEmirateSelection = false } = SummaryPanel;
  const { cash: cashRounding = 0 } = totalsRounding;
  const isLoading = !grossPrice;
  const vatPercentage = cash?.excludingVat?.vatPercentage;
  const destinationAndDocFee = getDestinationAndDocFee(state);
  const deliveryFee = getFeeAmountById(state, FEE_DELIVERY);
  const firstRegistrationTax = getFeeAmountById(state, FEE_FIRST_REGISTRATION);
  const firstRegistrationFee = getFeeAmountById(state, FEE_REGISTRATION);
  const governmentIncentives = getGovernmentIncentives(state);
  const aquisitionFee = getFeeAmountById(state, FEE_AQUISITION);
  const inspectionFee = getInspectionFee(state);
  const tireFee = getFeeAmountById(state, REGIONAL_TIRE_FEE);
  const vatAmount = getVATAmount(state);
  const savedConfiguration = getSavedConfiguration(state);
  const isEditDesign = Boolean(savedConfiguration);
  const savedAccessories = getSavedAccessories(state);
  const { itemizedOptions, figure } = getEditDesignProps(state);
  const selectedAccessories = getSelectedAccessoriesList(state);
  const accessoriesList = getAccessoriesList(state);
  const luxuryTax = getLuxuryTax(state);
  const regionalIncentiveInPurchasePrice = getRegionalIncentiveInPurchasePrice(state);
  const innovationBonus = getInnovationBonus(state);
  let accessoriesSubtotal = 0;
  // use selected accessories source if edit accessories is allowed (the list is injected)
  // otherwise use savedAccessories injection to only display accessories
  const savedAccessoriesSource = accessoriesList.length ? selectedAccessories : savedAccessories;
  const accessoryItems = isEditDesign
    ? savedAccessoriesSource.map(accessory => {
        const optPrice = accessory?.price || accessory?.options[0]?.purchasePrice?.amount || 0;
        grossPrice += optPrice;
        accessoriesSubtotal += optPrice;
        return {
          label: accessory?.name,
          value: formatCurrency(optPrice),
        };
      })
    : [];
  if (accessoriesSubtotal > 0) {
    accessoryItems.push({
      label: (
        <span className="tds-text--medium tds-text--contrast-high">
          {i18n('Enterprise.subtotal_accessories')}
        </span>
      ),
      value: (
        <span className="tds-text--medium tds-text--contrast-high">
          {formatCurrency(accessoriesSubtotal)}
        </span>
      ),
    });
  }
  const { firstName, lastName, email, phoneNumber: { code, number } = {} } = DeliveryContact || {};
  const deliveryLocation = (
    <>
      <div className="tds-flex">
        <div className="tds-flex-item">
          <div className="tds--vertical_padding--small">
            <div className="tds-o-padding_bottom-4">{i18n('DeliveryLocation.title')}</div>
            <p className="tds-text--medium tds-text--contrast-high">
              {trt?.label || `${trt?.title} ${trt?.map_address}`}
            </p>
          </div>
        </div>
        <If condition={compoundLocations?.length > 1}>
          <div className="tds-flex-item tds-flex--col_1of4 tds-o-text-align_end tds--vertical_padding--small">
            <EditDeliveryLocation id="delivery-selection" />
          </div>
        </If>
      </div>
    </>
  );
  const deliveryType = (
    <>
      <div className="tds--vertical_padding--small">
        <div className="tds-o-padding_bottom-4">{i18n('DeliveryLocation.delivery_method')}</div>
        <p className="tds-text--medium tds-text--contrast-high">
          {i18n('DeliveryLocation.delivery_method_pickup')}
        </p>
      </div>
      <If condition={firstName && lastName && email}>
        <div className="tds--vertical_padding--small">
          <div className="tds-flex">
            <div className="tds-flex-item">
              <div className="tds-o-padding_bottom-4">
                {i18n('DeliveryLocation.delivery_contact')}
              </div>
            </div>
            <div className="tds-flex-item tds-flex--col_1of4 tds-o-text-align_end">
              <EditDeliveryLocation id="delivery-contact" />
            </div>
          </div>
          <p className="tds-text--medium tds-text--contrast-high">
            {firstName} {lastName}
            <br />
            {email}
            <br />
            {number ? `+${code} ${number}` : ''}
          </p>
        </div>
      </If>
    </>
  );
  let amountList = isEditDesign
    ? []
    : [
        {
          label: (
            <span className="tds-text--medium tds-text--contrast-high">
              {i18n(
                `Enterprise.${
                  selectedAccessories.length ? 'subtotal_vehicle' : 'orderSummary.subtotal'
                }`
              )}
            </span>
          ),
          value: (
            <span className="tds-text--medium tds-text--contrast-high">
              {formatCurrency(subtotalExcVat)}
            </span>
          ),
        },
      ];
  if (destinationAndDocFee > 0) {
    amountList.push({
      label: `${i18n('SummaryPanel.destination_fee')}`,
      value: formatCurrency(destinationAndDocFee),
    });
  }
  if (firstRegistrationFee > 0) {
    amountList.push({
      label: `${i18n('Enterprise.orderSummary.firstRegistrationFee')}`,
      value: formatCurrency(firstRegistrationFee),
    });
  }
  if (firstRegistrationTax > 0) {
    amountList.push({
      label: `${i18n('SummaryPanel.disclaimers.estFirstRegistrationTax')}`,
      value: formatCurrency(firstRegistrationTax),
    });
  }
  if (deliveryFee > 0) {
    amountList.push({
      label: `${i18n('SummaryPanel.delivery_fee')}`,
      value: formatCurrency(deliveryFee),
    });
  }
  if (governmentIncentives !== 0) {
    amountList.push({
      label: i18n('FinancingOptions.governmentIncentive', null, null,  {
        specificOverride: 'business'
      }),
      value: formatCurrency(governmentIncentives),
    });
  }
  if (regionalIncentiveInPurchasePrice?.amount) {
    amountList.push({
      label: i18n('Incentives.purchasePriceRegionalIncentive', {
        REGION: regionalIncentiveInPurchasePrice?.longName,
      }),
      value: formatCurrency(regionalIncentiveInPurchasePrice?.amount),
    });
  }
  if (aquisitionFee !== 0) {
    amountList.push({
      label: i18n('SummaryPanel.disclaimers.aquisitionFee'),
      value: formatCurrency(aquisitionFee),
    });
  }
  if (inspectionFee !== 0) {
    amountList.push({
      label: i18n('SummaryPanel.disclaimers.inspectionFee', null, null, {
        specificOverride: [region],
        returnNullWhenEmpty: true,
        specificOverrideOperator: 'OR',
      }),
      value: formatCurrency(inspectionFee, { useDynamicRounding: true }),
    });
  }
  if (tireFee !== 0) {
    amountList.push({
      label: i18n('SummaryPanel.disclaimers.tireFee'),
      value: formatCurrency(tireFee),
    });
  }
  if (innovationBonus) {
    amountList.push({
      label: i18n('SummaryPanel.disclaimers.innovationBonus'),
      value: formatCurrency(innovationBonus),
    });
    grossPrice += innovationBonus || 0;
  }
  if (luxuryTax !== 0) {
    amountList.push({
      label: i18n('SummaryPanel.disclaimers.luxuryTax'),
      value: formatCurrency(luxuryTax, { useDynamicRounding: true }),
    });
  }
  // Add regional fees
  amountList = addRegionalFeesToList(amountList, regionalFeesValues);
  if (!accessoriesSubtotal) {
    selectedAccessories.map(accessory => {
      amountList.push({
        label: accessory?.name,
        value: formatCurrency(accessory?.options[0]?.purchasePrice?.amount || 0),
      });
      grossPrice += accessory?.options[0]?.purchasePrice?.amount || 0;
    });
  }
  const orderFee = getDepositAmount(state, true);
  const { paymentSourceSubType, orderPayment } = orderFee || {};
  if (paymentSourceSubType === ORDER_FEE_TYPE && orderPayment) {
    amountList.push({
      label: `${i18n('SummaryPanel.order_fee')}`,
      value: formatCurrency(orderPayment),
    });
    if (!showOrderFee) {
      grossPrice += orderPayment;
    }
  }
  const hasIncentives = !!extraIncentives?.length && !extraIncentives.includes(TAX_REGISTRATION) && !hideExtraIncentivesInOrderSummary;
  const disclaimer = (
    <>
      <VatCaption percentage={vatPercentage} amount={vatAmount} />
      <If condition={hasIncentives}>
        <>
          <ExtraIncentives keyPrefix="IncentiveDisclaimerItem" />
        </>
      </If>
    </>
  );
  const preContent = [];
  if (showRegistrationAboveSummary) {
    preContent.push(<RegistrationTypeSection readOnly={isEditDesign} />);
  }
  if (trt?.id) {
    preContent.push(deliveryLocation);
  }
  if (deliveryMethod === DELIVERY_METHOD_PO) {
    preContent.push(deliveryType);
  }
  if (showEmirateSelection && !isEditDesign) {
    preContent.push(<EmirateDropdown />);
  }
  const totalLine = {
    label: `${i18n('Enterprise.purchase_price')}`,
    value: formatCurrency(grossPrice, {
      precision: cashRounding,
      useDynamicRounding: formatWithPrecision,
    }),
    caption: disclaimer,
  };

  let lineItemsList = [itemizedOptions, accessoryItems, amountList];
  const lineItems = isEditDesign ? lineItemsList : [amountList];
  return {
    title: isEditDesign ? '' : i18n('Enterprise.order_summary'),
    totals: isLoading ? null : [totalLine],
    lineItems: isLoading ? null : lineItems,
    // eslint-disable-next-line jsx-control-statements/jsx-use-if-tag
    legal: isEditDesign ? null : <PaymentTermsLegalDisclaimers />,
    buttonLabel: isEditDesign ? null : `${i18n('Enterprise.place_order')}`,
    figure: isEditDesign ? figure : null,
    preContent,
    isDarkTheme: enableCyberpunk,
  };
};

const CoinV3StyleWrapper = props => {
  const { classes } = props;
  return (
    <div className={cx('coin--v3', { [classes]: classes })}>
      <OrderSummary {...props} />
    </div>
  );
};

CoinV3StyleWrapper.propTypes = {
  classes: string,
};

CoinV3StyleWrapper.defaultProps = {
  classes: '',
};

const mapDispatchToProps = dispatch => ({
  onSubmit: () => {
    Analytics.fireTagEvent({
      event: Analytics.event,
      'cfg-type': Analytics.cfgType,
      interaction: 'place-order',
    });
    dispatch(processEnterpriseOrder());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CoinV3StyleWrapper);

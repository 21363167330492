import React from 'react';
import { connect } from 'react-redux';
import { bool, number, string, shape, arrayOf, oneOfType } from 'prop-types';
import cx from 'classnames';
import _get from 'lodash/get';
import { formatCurrency } from '@tesla/coin-common-components';
import { i18n, isPriceAcceptance } from 'utils';
import {
  getDepositAmount,
  useModelYContent,
  getTransportationFeeAmount,
  getModelCode,
  getExtraCurrencyAmount,
  getAmountInOtherCurrencies,
  getAccessoriesTotal,
  isAccessoriesSelected,
  isInventory,
  getPricingContext,
} from 'selectors';
import { CONTEXT_DEFAULT } from 'dictionary';
import AccessoriesTotal from './AccessoriesTotal';
import OrderRefundDisclaimer from './OrderRefundDisclaimer';
import TextLoader from '../../../components/TextLoader';

const DepositAmountTable = ({
  isEnabled,
  depositAmount,
  depositType,
  bolded,
  enableCyberpunk,
  isMobile,
  amountInOtherCurrencies,
  baseCurrency,
  extraPricingContextAmount,
  hasAccessories,
  precision,
  isInventory,
  displayDoubleCurrency,
  pricingContext,
  showAccessoriesBlock,
  reviewOrderDisclaimer,
  market,
  userHasApplePay,
  prepayment,
}) => {
  if (!isEnabled || isPriceAcceptance()) {
    return null;
  }

  return (
    <>
      <AccessoriesTotal
        extraPricingContextAmount={extraPricingContextAmount}
        showAccessoriesBlock={showAccessoriesBlock}
      />
      <table className={cx(
        'tds-table',
        { 'tds-o-margin_bottom-16': userHasApplePay && showAccessoriesBlock && !isInventory },
        { 'review-page--due-today-container': market !== 'CN' }
      )}>
        <tbody className="tds-table-body">
          <tr className="tds-table-row tds-table--align_top">
            <th scope="row" className={cx({'tds-o-padding_bottom-4': market !== 'CN', 'tds-o-padding_bottom-16': market === 'CN'})}>
              <span
                className={cx(
                  'tds--no_padding',
                  { 'tds-text--bold': bolded },
                  { 'tds-text--h4': !(enableCyberpunk && isMobile) },
                  { 'tds-text--h3': enableCyberpunk && isMobile }
                )}
              >
                {i18n('SummaryPanel.disclaimers.orderPaymentShort', {
                  DEPOSIT_TYPE: depositType,
                })}
              </span>
            </th>
            <td
              className={cx('tds-text--end', {'tds-o-padding_bottom-4': market !== 'CN' && !enableCyberpunk })}
            >
              <span
                className={cx(
                  'tds--no_padding tds-o-nowrap',
                  { 'tds-text--bold': bolded },
                  { 'tds-text--h4': !(enableCyberpunk && isMobile) },
                  { 'tds-text--h3': enableCyberpunk && isMobile }
                )}
              >
                {formatCurrency(depositAmount, {
                  currency: amountInOtherCurrencies.length ? pricingContext : baseCurrency,
                  precision,
                })}
              </span>
              <If condition={displayDoubleCurrency && amountInOtherCurrencies.length > 0}>
                <span className="double-currency">
                  {formatCurrency(amountInOtherCurrencies.map(amn => amn.amount)[0], {
                    currency: amountInOtherCurrencies.map(amn => amn.currency)[0],
                    useDynamicRounding: true,
                  })}
                </span>
              </If>
            </td>
          </tr>
          <If condition={hasAccessories && reviewOrderDisclaimer}>
            <TextLoader data={reviewOrderDisclaimer} className="tds-text--caption" />
          </If>
          <If condition={!showAccessoriesBlock || isInventory}>
            <tr>
              <td
                colSpan="2"
                className={cx('coin-deposit-disclaimer',
                  {
                  'tds-o-background-transparent': enableCyberpunk,
                  },
                  {
                    'tds-o-padding_bottom-24': userHasApplePay && prepayment,
                  },
                  {
                    'tds-o-padding_bottom-4': !userHasApplePay || !prepayment,
                  }
                )}
              >
                <OrderRefundDisclaimer extraPricingContextAmount={extraPricingContextAmount} />
              </td>
            </tr>
          </If>
        </tbody>
      </table>
    </>
  );
};

DepositAmountTable.propTypes = {
  isEnabled: bool,
  depositAmount: number,
  depositType: string,
  bolded: bool,
  enableCyberpunk: bool,
  isMobile: bool,
  amountInOtherCurrencies: arrayOf(
    shape({
      amount: number,
      currency: string,
    })
  ),
  baseCurrency: string.isRequired,
  extraPricingContextAmount: oneOfType([string, number]),
  hasAccessories: bool,
  precision: number,
  isInventory: bool.isRequired,
  displayDoubleCurrency: bool,
  market: string,
  isReservedPreOrder: bool,
  isBookedPreOrder: bool,
  pricingContext: string,
  showAccessoriesBlock: bool,
  reviewOrderDisclaimer: string,
  prepayment: bool,
};

DepositAmountTable.defaultProps = {
  isEnabled: false,
  depositAmount: null,
  depositType: null,
  bolded: false,
  enableCyberpunk: false,
  isMobile: false,
  amountInOtherCurrencies: [],
  extraPricingContextAmount: '',
  hasAccessories: false,
  precision: 0,
  market: '',
  isReservedPreOrder: false,
  isBookedPreOrder: false,
  pricingContext: '',
  showAccessoriesBlock: false,
  reviewOrderDisclaimer: '',
  prepayment: false,
};

function mapStateToProps(state) {
  const { ReviewDetails, App, ApplicationFlow, CustomGroups, ApplePay } = state;
  const { isSaveDesignEnabled, isPreSale = {}, isTransportFeeEnabled = false } = ReviewDetails;
  const { isTransportFeeCollectionEnabled = false, enableCyberpunk = false, isPostOrderSwap } = App;
  const { isReservationToOrderFlow = false, canModifyOrder = false } = ApplicationFlow || {};
  const { source: isPreSaleSource = false } = isPreSale;
  const { userHasApplePay = false } = ApplePay || {};
  const modelCode = getModelCode(state);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const useModelYContentState = useModelYContent(state);
  const market = _get(state, 'OMS.oms_params.market', '');
  const isReservedPreOrder = _get(state, 'ApplicationFlow.isReservedPreOrder', false);
  const isBookedPreOrder = _get(state, 'ApplicationFlow.isBookedPreOrder', false);
  let depositType =
    !useModelYContentState || isPreSaleSource
      ? i18n('SummaryPanel.disclaimers.firstDeposit')
      : i18n('SummaryPanel.disclaimers.reserveDeposit');
  if (market === 'CN' && (isReservedPreOrder || isBookedPreOrder)) {
    depositType = i18n('SummaryPanel.disclaimers.paymentDue');
  }

  const orderFee = getDepositAmount(state);
  const transportFeeAmount = getTransportationFeeAmount(state);
  const baseCurrency = state?.Payment?.CurrencyCode;
  const transportFee =
    isTransportFeeEnabled && isTransportFeeCollectionEnabled ? transportFeeAmount : 0;
  const depositAmount = orderFee + transportFee + getAccessoriesTotal(state);
  const hasAccessories = isAccessoriesSelected(state);
  const { due_today_disclaimer: reviewOrderDisclaimer } = CustomGroups?.REVIEW_ORDER || {};

  return {
    isEnabled: !isSaveDesignEnabled && !!orderFee && !isPostOrderSwap && !canModifyOrder,
    depositAmount,
    depositType,
    enableCyberpunk,
    isMobile: _get(state, 'App.isLayoutMobileAdjusted'),
    amountInOtherCurrencies: getAmountInOtherCurrencies(state, modelCode),
    baseCurrency,
    modelCode,
    extraPricingContextAmount: getExtraCurrencyAmount(state, modelCode),
    hasAccessories,
    isInventory: isInventory(state),
    precision: Number.isInteger(depositAmount) ? 0 : 2,
    displayDoubleCurrency: _get(state, 'App.displayDoubleCurrency', false),
    market,
    isReservedPreOrder,
    isBookedPreOrder,
    pricingContext: getPricingContext(state, CONTEXT_DEFAULT),
    showAccessoriesBlock: hasAccessories || !!(enableCyberpunk && isReservationToOrderFlow && market !== 'US'),
    reviewOrderDisclaimer,
    userHasApplePay,
  };
}

export default connect(mapStateToProps)(DepositAmountTable);

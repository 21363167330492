/* eslint-disable no-param-reassign */
import { formatCurrency } from '@tesla/coin-common-components';
import React from 'react';
import { connect } from 'react-redux';
import {
  i18n,
} from 'utils';
import { 
  getPurchasePriceTotal 
} from 'selectors';


const PurchasePrice = ({
  estPurchasePrice,
}) => {
  
  return (
    <div className="review-page--total-section review-page--line-items">
      <ul className="review-page--totals-list tds-list">
        <li className="review-page--line-item tds-list-item">
          <span className="tds-text--medium tds-o-text_color--20 label left">
            {i18n('Review.est_purchase_price')}
          </span>
          <span className="tds-text--medium tds-o-text_color--20 value">
            {formatCurrency(estPurchasePrice)}
          </span>
        </li>
      </ul>
    </div>
  )
};

function mapStateToProps(state) {
  
  const estPurchasePrice = getPurchasePriceTotal(state)

  return {
    estPurchasePrice,
  };
}

PurchasePrice.propTypes = {
  
};

PurchasePrice.defaultProps = {

};

export default connect(mapStateToProps)(PurchasePrice);
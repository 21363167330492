import React from 'react';
import { connect } from 'react-redux';
import { Icon } from '@tesla/design-system-react';
import { iconStatusSuccess } from '@tesla/design-system-icons';
import { bool, shape, number, string } from 'prop-types';

import { i18n } from 'utils';
import { isUsedInventory, getReferralCredit } from 'selectors';

const PricingDisclaimers = ({
  veteranVerificationStatus,
  referralCredit,
  purchasePriceDisclaimer,
  discountsDisclaimer,
}) => {
  return (
    <>
      <If
        condition={(!!referralCredit?.credit || veteranVerificationStatus) && discountsDisclaimer}
      >
        <div className="tds-o-flex_no-wrap tds-o-margin_bottom">
          <Icon data={iconStatusSuccess} className="tds-icon--small" />
          <span className="tds--horizontal_padding--small tds-text--caption">
            {discountsDisclaimer}
          </span>
        </div>
      </If>
      <If condition={purchasePriceDisclaimer}>
        <p className="items price review-page--disclaimer tds-text--caption">
          {purchasePriceDisclaimer}
        </p>
      </If>
    </>
  );
};

function mapStateToProps(state) {
  const { verificationStatus = {} } = state?.ApplicationFlow || {};
  const { status: veteranVerificationStatus } = verificationStatus;
  const referralCredit = getReferralCredit(state);
  const referralKey = referralCredit?.credit ? 'referral' : '';
  const veteranKey = veteranVerificationStatus ? 'veteran' : '';
  const discountsDisclaimer = !state?.Pricing?.lease?.isALD && i18n('Review.discount_included', {}, null, {
    specificOverride: [veteranKey, referralKey],
    specificOverrideOperator: 'OR',
    returnNullWhenEmpty: true,
  });
  const { region_code: region } = state?.SummaryPanel || {};
  const { model_code: modelCode = '' } = state?.Configuration || {};

  return {
    veteranVerificationStatus,
    referralCredit,
    discountsDisclaimer,
    purchasePriceDisclaimer: !isUsedInventory(state)
      ? i18n('SummaryPanel.disclaimers.purchasePriceDisclaimer', null, null, {
          specificOverride: [region, modelCode],
          returnNullWhenEmpty: true,
          specificOverrideOperator: 'OR',
        })
      : '',
  };
}

PricingDisclaimers.propTypes = {
  veteranVerificationStatus: bool,
  referralCredit: shape({ credit: number }),
  discountsDisclaimer: string,
  purchasePriceDisclaimer: string,
};

PricingDisclaimers.defaultProps = {
  veteranVerificationStatus: false,
  referralCredit: {},
  discountsDisclaimer: '',
  purchasePriceDisclaimer: '',
};

export default connect(mapStateToProps)(PricingDisclaimers);
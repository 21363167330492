import React from 'react';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import { shape, bool } from 'prop-types';
import { referralConfigValidation } from 'selectors';
import ReferralBanner from '../ReferralBanner';
import VeteranBanner from '../VeteranBanner';

/* Group notification banner component */
const NotificationBanner = props => {
  return (
    <>
      <If condition={!_isEmpty(props?.verificationStatus)}>
        <VeteranBanner {...props} />
      </If>
      <If condition={!props?.verificationStatus?.status}>
        <ReferralBanner {...props} />
      </If>
    </>
  );
};

function mapStateToProps(state) {
  const { verificationStatus = {}, referral: referralData = {} } = state?.ApplicationFlow;
  const validConfigForReferral = referralConfigValidation(state);
  const creditCash = referralData?.cash?.value || 0;
  const creditTrial = referralData?.trials || [];
  const { showReferralZeroCreditDisclaimer = false } = state?.ReviewDetails || {};

  return {
    verificationStatus,
    validConfigForReferral,
    showReferralZeroCreditDisclaimer,
    showCredits: !!(creditCash || creditTrial?.length),
    referralData,
  };
}

NotificationBanner.propTypes = {
  verificationStatus: shape({}),
  validConfigForReferral: bool,
  showReferralZeroCreditDisclaimer: bool,
  showCredits: bool,
  referralData: shape({}),
};

NotificationBanner.defaultProps = {
  verificationStatus: {},
  validConfigForReferral: false,
  showReferralZeroCreditDisclaimer: false,
  showCredits: false,
  referralData: {},
};

export default connect(mapStateToProps, null)(NotificationBanner);
import React from 'react';
import { string } from 'prop-types';
import { connect } from 'react-redux';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _has from 'lodash/has';
import classnames from 'classnames';
import { getTrimCode } from 'selectors';

import { i18n, htmlToReact, parseSelectedBy } from 'utils';
import { EMISSIONS_DATA, GROUP } from '../../common/dictionary';

const InventoryEmissions = ({ link }) => {
  const emissionsData = _get(window.tesla, 'product.data.EmissionsData', {});

  // check if object is flat and has EmissionsData as a group in the payload
  if (
    !_has(emissionsData, GROUP) ||
    emissionsData?.group !== EMISSIONS_DATA ||
    _isEmpty(emissionsData) ||
    !link
  ) {
    return null;
  }

  return (
    <div
      className={classnames(
        'group-block specs-block tds--vertical_padding option-widget--container'
      )}
    >
      <div
        className={classnames(
          `emissions-item tds-text--caption emissions-item-container inventory-emissions`
        )}
      >
        <span className="inventory-emissions-items">
          {`${i18n('Emissions.official_energy_consumption')}: ${
            emissionsData.energy_consumption
          } kWh/100km.`}
        </span>
        <span className="inventory-emissions-items">{`CO${`\u2082`} ${i18n(
          'Emissions.test_cycle'
        )}: 0.`}</span>
        <span className="inventory-emissions-items">
          {`CO${`\u2082`} ${i18n('Emissions.efficiency_class')}: ${
            emissionsData.efficiency_class
          }.`}
        </span>
        <span className="inventory-emissions-items inventory-emissions-link">
          {htmlToReact(link)}
        </span>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const specs = state?.OMS?.lexicon?.metadata?.specs?.data[0]?.options?.[getTrimCode(state)] ?? {};
  const contentOverrides = Array.isArray(specs.overrides)
    ? specs.overrides.find(item =>
        parseSelectedBy(item.selected_by, state?.Configuration?.option_codes ?? [], null, state)
      )?.content
    : {};
  const { emissions_url_fragment = '' } = { ...specs, ...contentOverrides };
  const link = i18n(
    'Emissions.link',
    {
      LOCALE: state?.App?.locale,
      EMISSIONS_URL_FRAGMENT: emissions_url_fragment ? `#${emissions_url_fragment}` : '',
    },
    null,
    {
      returnNullWhenEmpty: true,
    }
  );
  return {
    link,
  };
};

InventoryEmissions.propTypes = {
  link: string,
};

InventoryEmissions.defaultProps = {
  link: null,
};

export default connect(mapStateToProps)(InventoryEmissions);

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { shape, bool, func } from 'prop-types';
import { i18n, htmlToReact } from 'utils';
import _isEmpty from 'lodash/isEmpty';
import cx from 'classnames';
import { REFERRAL_TYPE_B, REFERRAL_MODAL } from 'dictionary';
import { iconStatusSuccess, iconStatusError } from '@tesla/design-system-icons';
import { Icon } from '@tesla/design-system-react';
import Analytics from 'analytics';
import { openModal } from 'actions';

const VeteranBanner = ({
  verificationStatus,
  isCyberpunk,
  referralData,
  showCredits,
  validConfigForReferral,
  showReferralZeroCreditDisclaimer,
  openReferralModal,
}) => {
  if (_isEmpty(verificationStatus)) {
    return null;
  }
  const { status = false } = verificationStatus || {};
  const showReferralMsg = status && referralData?.type === REFERRAL_TYPE_B && validConfigForReferral;
  const referralKey = showReferralMsg ? 'referral' : '';
  const discountsDisclaimer = status ? i18n('Review.discount_applied', {}, null,
    {
      specificOverride: ['veteran', referralKey],
      specificOverrideOperator: 'OR',
      returnNullWhenEmpty: true,
    }
  ) : i18n('Review.veteran_credit_ineligible');

  useEffect(() => {
    Analytics.fireInteractionEvent(`Dme-discount-${status ? 'eligible' : 'ineligible'}`);
  }, []);

  return (
    <div
      className={cx('tds-o-flex_no-wrap', {
        'tds-flex--justify-center': status,
      }, {
        'tds--vertical_padding--small': !isCyberpunk,
      }, {
        'tds-o-vertical_padding-top--3x': isCyberpunk,
      })}
    >
      <Icon data={status ? iconStatusSuccess : iconStatusError} />
      <span className="tds--horizontal_padding--small">
        {htmlToReact(discountsDisclaimer)}
        <If condition={showReferralMsg && (showCredits || showReferralZeroCreditDisclaimer)}>
          <a className="tds-link tds--horizontal_margin-5" onClick={openReferralModal}>
            {i18n('common.learn_more')}
          </a>
        </If>
      </span>
    </div>
  );
};

VeteranBanner.propTypes = {
  verificationStatus: shape({
    status: bool,
  }),
  showCredits: bool,
  showReferralZeroCreditDisclaimer: bool,
  openReferralModal: func,
  isCyberpunk: bool,
};

VeteranBanner.defaultProps = {
  verificationStatus: {
    status: false,
  },
  isCyberpunk: false,
  showCredits: false,
  showReferralZeroCreditDisclaimer: false,
  openReferralModal: () => {},
};

function mapStateToProps(state) {
  return {
    isCyberpunk: state?.App?.enableCyberpunk || false,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openReferralModal: () => {
      dispatch(
        openModal(REFERRAL_MODAL, {
          genericWrapper: false,
        })
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VeteranBanner);

import React, { useState, useEffect } from 'react';
import { string, bool, oneOfType, func, arrayOf, shape } from 'prop-types';
import _isArray from 'lodash/isArray';
import _isObject from 'lodash/isObject';
import _isString from 'lodash/isString';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import classnames from 'classnames';
import { htmlToReact, cleanNonBreakingSpace } from 'utils';
import {
  SHOW_GAS_SAVINGS_MODAL,
  SHOW_SAVINGS_MODAL,
  FEATURES_LIST_COLLAPSE,
  FEATURES_LIST_EXPAND,
  SHOW_INTERIOR_MODAL,
  SHOW_BANNER_MODAL,
  SHOW_INCENTIVES_MODAL,
  SHOW_FINANCE_MODAL,
  SHOW_EMISSIONS_MODAL,
  SHOW_GENERIC_MODAL_TRIGGER_MODAL,
  SHOW_INCENTIVES_SAVINGS_MODAL,
  FINANCE_MODAL_TAB_OPTIONS,
  MODAL_XSMALL,
  RECOMMENDED_INFO_MODAL,
  BANNER_MODAL,
  MODAL_LARGE,
  FINANCE_MODAL_TAB_INCENTIVES,
  FINANCE_MODAL_TAB_INCENTIVES_SAVINGS,
  FINANCE_MODAL_TAB_EMISSIONS,
  FINANCE_MODAL_TAB_GAS_SAVINGS,
  BATTERY_SAVINGS_MODAL,
  FEATURE_LIST_MODAL_OPEN,
  FINANCE_CAROUSEL,
  FEATURE_LIST_MODAL_OPEN_LINK,
  GET_UPDATES,
  MODAL_OPEN,
  NAVIGATION_SELECT_KEY,
} from 'dictionary';
import ModalTrigger from '../ModalTrigger';
import TextLoader from './index';
import useGoogleOptimize from '../../hooks/useGoogleOptimize';
import { FormInputChoice, FormItem } from '@tesla/design-system-react';
import GioStatistics from 'gioStatistics';
import { WEB_CONFIGURATOR_PAYMENT_PC_DETAILS } from '../../common/gioStatistics';

const FeatureListMainTitle = ({ text_schema }) => {
  if (!text_schema.main_title) {
    return null;
  }
  const classNames = text_schema.main_title.behavior?.map(item => item?.content?.classNames).join();

  return (
    <TextLoader
      data={text_schema}
      field="main_title"
      className={classnames({
        [`${classNames}`]: classNames,
        'tds-text--h6': !classNames,
      })}
    />
  );
};

FeatureListMainTitle.propTypes = {
  text_schema: shape({}).isRequired,
};

export default function TextLoaderRepresentation(props) {
  const {
    text_schema,
    tag,
    actions,
    onAction,
    key,
    actionClass,
    className,
    plainText,
    optimize,
    code,
    availableGroups,
    isLayoutMobile,
    isLayoutTablet,
    inheritClasses,
  } = props;

  const [currentAction] = actions || [];
  const { defaultValue = false } = currentAction || {};
  const [classNameWithOptimize, setClassNameWithOptimize] = useState(className);
  const [checked, setChecked] = useState(defaultValue);
  const experimentId = _get(optimize, 'experimentId');

  useEffect(() => {
    if (classNameWithOptimize !== className) {
      setClassNameWithOptimize(className);
    }
  }, [className]);

  useGoogleOptimize(experimentId, variant => {
    let variants = _get(optimize, 'variants', []);

    // add a default variant at the start of the array
    variants = [{}, ...variants];

    const variantClassName = Array.isArray(variants[variant].classNames)
      ? variants[variant].classNames.join(' ')
      : className;

    setClassNameWithOptimize(variantClassName);
  });

  // TWS-42524 provisional fix to remove `&nbsp;` until we have TDS-fonts fix.
  if (!text_schema) {
    return null;
  }
  const extraProps = { inheritClasses };
  const tabIndex = actions && actions.length ? null : -1;

  if (React.isValidElement(text_schema)) {
    return text_schema;
  }

  if (_isArray(text_schema)) {
    return (
      <div
        className={classnames(
          'text-loader',
          { [`${className}--container`]: className },
          `${className}`
        )}
        onClick={() => onAction(actions)}
        onKeyPress={() => onAction(actions)}
        role="presentation"
      >
        {text_schema.map((text, index) => {
          if (!_isEmpty(text)) {
            return (
              <TextLoader
                data={text}
                key={`ArrayTextLoader:${text + index + key}`}
                className={className}
                {...extraProps}
              />
            );
          }
        })}
      </div>
    );
  }
  if (_isObject(text_schema)) {
    return (
      <div
        className={classnames('text-loader', {
          [`${className}--container`]: className,
          'text-loader--asset-container': text_schema?.asset,
        })}
        onClick={() => onAction(actions)}
        onKeyPress={() => onAction(actions)}
        role="presentation"
      >
        {text_schema.asset && (
          <div className="text-loader--asset">
            <TextLoader
              data={text_schema}
              field="asset"
              className={classnames({
                [`${className}`]: className,
              })}
              {...extraProps}
            />
          </div>
        )}
        {text_schema.title && (
          <TextLoader
            data={text_schema}
            field="title"
            className={classnames('text-loader--subtitle tds-text--h6', {
              [`${className}`]: className,
            })}
            {...extraProps}
          />
        )}
        {<FeatureListMainTitle text_schema={text_schema} />}
        {text_schema.description && (
          <TextLoader
            data={text_schema}
            field="description"
            className="text-loader--description"
            {...extraProps}
          />
        )}
        {text_schema.sub_items && (
          <ol className="tds-list tds-list--unordered text-loader--list">
            {text_schema.sub_items.map((feature, subindex) =>
              (() => {
                const nonBreakingString = cleanNonBreakingSpace(feature);
                if (!_isEmpty(feature) && _isString(feature)) {
                  return (
                    <li
                      className="tds-list-item text-loader--list_element text-loader--sub_item_element"
                      key={`Feature__${nonBreakingString + subindex}feature_list:container`}
                    >
                      <TextLoaderRepresentation
                        {...props}
                        className="text-loader--list_element--text"
                        text_schema={nonBreakingString}
                        tag={{ component: 'span' }}
                      />
                    </li>
                  );
                }
                if (!_isEmpty(feature)) {
                  return (
                    <li
                      className="tds-list-item text-loader--list_element text-loader--sub_item_element"
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${subindex}feature_list:container`}
                    >
                      <TextLoader
                        className="text-loader--list_element--text"
                        data={feature}
                        {...extraProps}
                      />
                    </li>
                  );
                }
              })()
            )}
          </ol>
        )}
        {text_schema.items && (
          <ol className="tds-list tds-list--unordered text-loader--list text-loader--list_items">
            {text_schema.items.map((feature, subindex) =>
              (() => {
                if (!_isEmpty(feature) && _isString(feature)) {
                  return (
                    <li
                      className="tds-list-item text-loader--list_element"
                      key={`ListString_${cleanNonBreakingSpace(feature) +
                        subindex}feature_list:container`}
                    >
                      <TextLoaderRepresentation
                        {...props}
                        className="text-loader--list_element--text"
                        text_schema={cleanNonBreakingSpace(feature)}
                        tag={{ component: 'span' }}
                      />
                    </li>
                  );
                }

                if (!_isEmpty(feature)) {
                  return (
                    <li
                      className="tds-list-item text-loader--list_element"
                      // eslint-disable-next-line react/no-array-index-key
                      key={`List__${subindex}feature_list:container`}
                    >
                      <TextLoader
                        className="text-loader--list_element--text"
                        data={feature}
                        {...extraProps}
                      />
                    </li>
                  );
                }
              })()
            )}
          </ol>
        )}
        {text_schema.content && <TextLoader data={text_schema} />}
        {text_schema.disclaimer && (
          <TextLoader
            data={_isString(text_schema) ? cleanNonBreakingSpace(text_schema) : text_schema}
            field="disclaimer"
            className="tds-text--caption text-loader--disclaimer"
            {...extraProps}
          />
        )}
        {text_schema.list && (
          <>
            {text_schema.list.map(feature =>
              (() => {
                if (!_isEmpty(feature) && _isString(feature)) {
                  return (
                    <TextLoaderRepresentation
                      {...props}
                      text_schema={cleanNonBreakingSpace(feature)}
                      tag={{ component: 'span' }}
                    />
                  );
                }

                if (!_isEmpty(feature)) {
                  return <TextLoader data={feature} {...extraProps} />;
                }
              })()
            )}
          </>
        )}
      </div>
    );
  }

  if (plainText) {
    return htmlToReact(text_schema);
  }

  // Text that triggers an action should be links or IconTriggers.
  if (actions && actions.length === 1) {
    const [currentAction] = actions;
    const { classNames: currentActionClasses = [], containerClassNames = [], selectedTab = '', id = '' } =
      currentAction || {};
    const modalBaseClasses = ['tds-o-text_color--20', 'tds-link'];
    const modalClasses = [...modalBaseClasses, ...currentActionClasses];
    const modalClassesJoined = modalClasses.join(' ');
    const containerCss = containerClassNames.length ? ` ${containerClassNames.join(' ')}` : '';
    // eslint-disable-next-line react/prop-types
    switch (currentAction.type) {
      case SHOW_INTERIOR_MODAL:
        return (
          <ModalTrigger
            options={{
              props: {
                componentName: RECOMMENDED_INFO_MODAL,
                props: {
                  genericWrapper: true,
                  size: MODAL_XSMALL,
                  props: currentAction.props,
                  classes: modalClassesJoined,
                  containerCss: `tds-display--inline${containerCss}`,
                },
              },
            }}
          >
            {htmlToReact(text_schema)}
          </ModalTrigger>
        );
      case SHOW_BANNER_MODAL:
        return (
          <ModalTrigger
            options={{
              props: {
                componentName: BANNER_MODAL,
                props: {
                  genericWrapper: true,
                  size: MODAL_XSMALL,
                  ...currentAction.props,
                  classes: modalClassesJoined,
                  containerCss: `coin--banner-modal${containerCss}`,
                },
              },
            }}
          >
            {htmlToReact(text_schema)}
          </ModalTrigger>
        );
      case SHOW_FINANCE_MODAL:
        return (
          <>
            <ModalTrigger
              type={FINANCE_CAROUSEL}
              selectedView={FINANCE_MODAL_TAB_OPTIONS}
              selectedTab={selectedTab}
              options={{
                props: {
                  props: {
                    analyticsPrefix: currentAction.analyticsPrefix,
                    classes: modalClassesJoined,
                    containerCss: `tds-display--inline${containerCss}`,
                  },
                },
              }}
            >
              {htmlToReact(text_schema)}
            </ModalTrigger>
          </>
        );
      case SHOW_SAVINGS_MODAL:
        return (
          <>
            <ModalTrigger
              options={{
                props: {
                  componentName: BATTERY_SAVINGS_MODAL,
                  props: {
                    genericWrapper: true,
                    size: MODAL_LARGE,
                    analyticsPrefix: currentAction.analyticsPrefix,
                    classes: modalClassesJoined,
                    containerCss: `tds-display--inline tds-text--caption${containerCss}`,
                  },
                },
              }}
            >
              {htmlToReact(text_schema)}
            </ModalTrigger>
          </>
        );
      case SHOW_INCENTIVES_MODAL:
        return (
          <>
            <ModalTrigger
              type={FINANCE_CAROUSEL}
              selectedView={FINANCE_MODAL_TAB_INCENTIVES}
              options={{
                props: {
                  props: {
                    analyticsPrefix: currentAction.analyticsPrefix,
                    classes: modalClassesJoined,
                    containerCss: `tds-display--inline${containerCss}`,
                  },
                },
              }}
            >
              {htmlToReact(text_schema)}
            </ModalTrigger>
          </>
        );
      case SHOW_GAS_SAVINGS_MODAL:
        return (
          <>
            <ModalTrigger
              type={FINANCE_CAROUSEL}
              selectedView={FINANCE_MODAL_TAB_GAS_SAVINGS}
              options={{
                props: {
                  props: {
                    analyticsPrefix: currentAction.analyticsPrefix,
                    classes: modalClassesJoined,
                    containerCss: `tds-display--inline${containerCss}`,
                  },
                },
              }}
            >
              {htmlToReact(text_schema)}
            </ModalTrigger>
          </>
        );
      case SHOW_INCENTIVES_SAVINGS_MODAL:
        return (
          <>
            <ModalTrigger
              type={FINANCE_CAROUSEL}
              selectedView={FINANCE_MODAL_TAB_INCENTIVES_SAVINGS}
              options={{
                props: {
                  props: {
                    classes: modalClassesJoined,
                    containerCss: `tds-display--inline tds-text--caption${containerCss}`,
                  },
                },
              }}
            >
              {htmlToReact(text_schema)}
            </ModalTrigger>
          </>
        );
      case FEATURES_LIST_COLLAPSE:
      case FEATURES_LIST_EXPAND:
      case FEATURE_LIST_MODAL_OPEN:
        return (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <button
            tabIndex={tabIndex}
            className={classnames('tds-link tds-link--secondary action-trigger--btn-link', {
              [className]: className,
            })}
            onClick={() => onAction(actions)}
          >
            {htmlToReact(text_schema)}
          </button>
        );
      case FEATURE_LIST_MODAL_OPEN_LINK: {
        const selectedView = _get(actions, '[0].payload.selectedView');
        if (selectedView && !availableGroups.includes(selectedView)) {
          return <span>{htmlToReact(text_schema)}</span>;
        }
        return (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <button
            tabIndex={tabIndex}
            className={classnames('feature-modal-link', {
              'tds-flex-item': isLayoutMobile || isLayoutTablet,
              [`${className}`]: className,
            })}
            onClick={() => {
              onAction(actions);
              GioStatistics.manuallyTrack(WEB_CONFIGURATOR_PAYMENT_PC_DETAILS);
            }}
          >
            <span className="tds-link tds-link--secondary action-trigger--btn-link">
              {htmlToReact(text_schema)}
            </span>
          </button>
        );
      }
      case SHOW_EMISSIONS_MODAL:
        return (
          <>
            <ModalTrigger
              type={FINANCE_CAROUSEL}
              selectedView={FINANCE_MODAL_TAB_EMISSIONS}
              options={{
                props: {
                  props: {
                    analyticsPrefix: currentAction.analyticsPrefix,
                    classes: modalClassesJoined,
                    containerCss: `tds-display--inline${containerCss}`,
                  },
                },
              }}
            >
              {htmlToReact(text_schema)}
            </ModalTrigger>
          </>
        );
      case SHOW_GENERIC_MODAL_TRIGGER_MODAL: {
        return (
          <>
            <ModalTrigger
              analyticsInteraction={currentAction?.analyticsInteraction}
              options={{
                props: {
                  componentName: currentAction?.componentName,
                  props: {
                    ...currentAction?.props,
                  },
                },
              }}
            >
              {htmlToReact(text_schema)}
            </ModalTrigger>
          </>
        );
      }
      case GET_UPDATES:
        return (
          <>
            <FormItem variant="choice">
              <FormInputChoice
                onChange={e => {
                  setChecked(e.target.checked);
                  onAction(actions, code);
                }}
                id={id}
                type="checkbox"
                name={`getUpdates-${id}`}
                tabIndex={0}
                checked={checked}
                label={htmlToReact(text_schema)}
              />
            </FormItem>
          </>
        );
      case MODAL_OPEN:
      case NAVIGATION_SELECT_KEY:
        return (
          <tag.component
            className={classnames({
              [actionClass]: actions && actions.length && actionClass,
              'tds-link action-trigger--link': actions && actions.length && !actionClass,
              'text-loader--content': !actionClass,
              [classNameWithOptimize]: classNameWithOptimize,
            })}
            {...tag.props}
            onClick={() => onAction(actions, code)}
            onKeyDown={e => {
              if (e.keyCode === 13 || e.keyCode === 32) {
                onAction(actions, code);
              }
            }}
            tabIndex={0}
          >
            {htmlToReact(cleanNonBreakingSpace(text_schema) || '')}
          </tag.component>
        );
      default:
    }
  }
  return (
    <tag.component
      className={classnames({
        [actionClass]: actions && actions.length && actionClass,
        'tds-link action-trigger--link': actions && actions.length && !actionClass,
        'text-loader--content': !actionClass,
        [classNameWithOptimize]: classNameWithOptimize,
      })}
      {...tag.props}
      onClick={() => onAction(actions, code)}
      tabIndex={tabIndex}
    >
      {htmlToReact(text_schema || '')}
    </tag.component>
  );
}

TextLoaderRepresentation.propTypes = {
  analyticsInteraction: string,
  tag: shape({
    props: shape({}),
  }),
  actions: arrayOf(
    shape({
      analyticsPrefix: string,
      props: shape({}),
    })
  ),
  onAction: func,
  key: string,
  className: string,
  actionClass: string,
  plainText: bool,
  text_schema: oneOfType([string, shape({}), arrayOf(oneOfType([string, shape({})]))]),
  optimize: shape({
    experimentId: string.isRequired,
    variants: arrayOf(
      shape({
        classNames: arrayOf(string),
      })
    ).isRequired,
  }),
  code: string,
  availableGroups: arrayOf(string),
  isLayoutMobile: bool,
  isLayoutTablet: bool,
  inheritClasses: bool,
};

TextLoaderRepresentation.defaultProps = {
  analyticsInteraction: null,
  tag: '',
  actions: [],
  onAction: null,
  key: '',
  className: '',
  actionClass: '',
  plainText: false,
  text_schema: null,
  optimize: null,
  code: '',
  availableGroups: [],
  isLayoutMobile: false,
  isLayoutTablet: false,
  inheritClasses: true,
};

import React, { useState, useEffect, useRef, Suspense } from 'react';
import { func, string, bool, array } from 'prop-types';
import { connect } from 'react-redux';
import { lazyWithPreload } from '../Lazy';
import classnames from 'classnames';
import { closeFinanceModal, selectFinanceView, resetTaxesAndFees } from 'actions';
import _get from 'lodash/get';
import { FINANCE_MODAL_TAB_EMISSIONS, FINANCE_MODAL_TAB_OPTIONS } from 'dictionary';
import { i18n, htmlToReact, isWeChatMini } from 'utils';
import { getEnabledTabs } from 'selectors';

import '../../style/finance-modal.css';
import RegionalSelector from '../../components/Forms/Incentives/RegionalSelector';
import Spinner from '../Loaders/Spinner';
import FinanceToggle from '../GroupComponents/FinanceToggle';

const CarouselModal = lazyWithPreload(() =>
  import(/* webpackChunkName: "carousel-modal" */ './index')
);

const forms = {
  finance_options: lazyWithPreload(() =>
    import(/* webpackChunkName: "financing-options" */ '../Forms/FinancingOptions')
  ),
  gas_savings: lazyWithPreload(() =>
    import(/* webpackChunkName: "gas-savings" */ '../Forms/GasSavings')
  ),
  incentives_savings: lazyWithPreload(() =>
    import(/* webpackChunkName: "incentives-savings" */ '../Forms/IncentivesSavings')
  ),
  incentives: lazyWithPreload(() =>
    import(/* webpackChunkName: "incentives" */ '../Forms/Incentives')
  ),
  tax: lazyWithPreload(() => import(/* webpackChunkName: "tax-and-fees" */ '../Forms/TaxAndFees')),
  tesla_insurance: lazyWithPreload(() =>
    import(/* webpackChunkName: "tesla-insurance" */ '../Forms/TeslaInsurance')
  ),
  emissions: lazyWithPreload(() =>
    import(/* webpackChunkName: "emissions" */ '../Forms/Emissions')
  ),
  prequalify: lazyWithPreload(() =>
    import(/* webpackChunkName: "prequalify" */ '../Forms/PreQualify')
  ),
  tradeIn: lazyWithPreload(() => import(/* webpackChunkName: "tradein" */ '../Forms/TradeIn')),
};

// eslint-disable-next-line react/prop-types
const FinancialForm = ({ selectedForm }) => {
  if (forms[selectedForm]) {
    const SelectedForm = forms[selectedForm];
    return <SelectedForm />;
  }

  return `Form ${selectedForm} is not implemented yet`;
};

const FinanceCarouselModalContainer = ({
  closeCarouselModal,
  isCarouselOpen,
  selectedView,
  menuItems,
  selectFinanceTab,
  includeTaxesAndFees,
  unsetTaxesAndFees,
  showRegionDropDown,
  hideLabel,
  showFinancialTabsEnabled,
  isLayoutMobile,
  isCoinReloaded,
}) => {
  const carouselRef = useRef();
  const [isFadeOut, setIsFadeOut] = useState(false);
  const onCloseCarousel = () => {
    setIsFadeOut(true);
    setTimeout(closeCarouselModal, 500);
    if (includeTaxesAndFees) {
      unsetTaxesAndFees();
    }
  };

  useEffect(() => {
    carouselRef.current?.selectView(selectedView);
  }, [selectedView]);

  useEffect(() => {
    if (isCarouselOpen) {
      document.body.classList.add('no-scroll-cfg');
      setIsFadeOut(false);
    } else {
      document.body.classList.remove('no-scroll-cfg');
    }
  }, [isCarouselOpen]);

  const panels = menuItems.map(item => ({
    key: item.key,
    id: item.key,
    label: item?.i18nLabel && i18n(item?.i18nLabel),
    component: (
      <>
        <div className={classnames({ 'modal-container-title title-fixed': item?.i18nLabel })}>
          <If condition={!hideLabel && item?.i18nLabel}>
            <h3 className="tds--no_padding">{htmlToReact(i18n(item.i18nLabel))}</h3>
          </If>
          <If condition={item?.showDeliveryZipCode}>
            <RegionalSelector
              showRegionDropDown={showRegionDropDown}
              showRegionAndZipCode
              showSubmitButton
              className="tds-o-margin-top"
            />
          </If>
        </div>
        <If condition={(item?.key === FINANCE_MODAL_TAB_OPTIONS && (!isWeChatMini() || showFinancialTabsEnabled))}>
          <div className="finance-selector-container--modal">
            <FinanceToggle
                tabsSource={FINANCE_MODAL_TAB_OPTIONS}
                label={i18n('common.Select_Finance_Solutions')}
                onChangeId={'financeContainer'}
                isCompact={isLayoutMobile}
                variant={isCoinReloaded ? 'underline' : 'toggle'}
                animated={!isCoinReloaded}
                showWithSingleItem
            />
          </div>
        </If>
        <Suspense key="Forms.Suspense.FinanceContainerForms" fallback={<Spinner active />}>
          <div className={classnames("modal-container--finance_container", `modal-container--finance_container--${item.key}`)}>
            <div className={classnames('financial--form-container', `selected-tab--${item.key}`)}>
              <FinancialForm selectedForm={item.key} />
            </div>
          </div>
        </Suspense>
        <div className="groupComponent-gradient"></div>
      </>
    ),
  }));

  return (
    <div
      className={classnames('carousel-container', {
        'is-open': isCarouselOpen,
        'is-close': isFadeOut,
      })}
    >
      <If condition={isCarouselOpen && panels.length}>
        <Suspense key="Forms.Suspense.FinanceContainer" fallback={<Spinner active />}>
          <CarouselModal
            ref={carouselRef}
            onCloseCarousel={onCloseCarousel}
            id="financeContainer"
            panels={panels}
            label="test"
            initialView={selectedView}
            onPanelChange={id => {
              if (selectedView !== id) {
                selectFinanceTab(id);
              }
            }}
          />
        </Suspense>
      </If>
    </div>
  );
};

function mapStateToProps(state) {
  const { App, FinanceModal = {}, FinancingOptions, ReviewDetails: { DeliveryDetails = {} } = {} } =
    state || {};
  const { isCarouselOpen, selectedView, carouselTriggerText } = FinanceModal;
  const emissions = _get(state, 'CustomGroups.EMISSIONS.title', null);
  const { showTaxesAndFees, includeTaxesAndFees, preQualify } = FinancingOptions || {};
  const { showFinancialTabsEnabled = true, isLayoutMobile, isCoinReloaded } = App || {}

  let menuItems = getEnabledTabs(FinancingOptions, state);

  menuItems =
    emissions !== null
      ? menuItems
      : menuItems.filter(item => item.key !== FINANCE_MODAL_TAB_EMISSIONS);

  return {
    isCarouselOpen,
    selectedView,
    carouselTriggerText,
    menuItems,
    showTaxesAndFees,
    includeTaxesAndFees,
    showRegionDropDown: !!DeliveryDetails?.PostalCode,
    hideLabel: preQualify?.isDecisionPending,
    showFinancialTabsEnabled,
    isLayoutMobile,
    isCoinReloaded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeCarouselModal: () => dispatch(closeFinanceModal()),
    selectFinanceTab: tabName => dispatch(selectFinanceView(tabName)),
    unsetTaxesAndFees: () => dispatch(resetTaxesAndFees()),
  };
}

FinanceCarouselModalContainer.propTypes = {
  closeCarouselModal: func.isRequired,
  isCarouselOpen: bool.isRequired,
  carouselTriggerText: string,
  selectedView: string, // Initial view to display on open
  menuItems: array,
  selectFinanceTab: func.isRequired,
  showTaxesAndFees: bool,
  hideLabel: bool,
  showFinancialTabsEnabled: bool,
  isLayoutMobile: bool,
  isCoinReloaded: bool,
};

FinanceCarouselModalContainer.defaultProps = {
  selectedView: null,
  showTaxesAndFees: false,
  hideLabel: false,
  showFinancialTabsEnabled: true,
  isLayoutMobile: false,
  isCoinReloaded: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(FinanceCarouselModalContainer);

import React from 'react';
import { createPortal } from 'react-dom';
import { string, func, bool, arrayOf } from 'prop-types';
import { ConfirmModal } from '@tesla/coin-common-components';
import { i18n } from 'utils';
import { Icon } from '@tesla/design-system-react';
import { iconStatusWarning } from '@tesla/design-system-icons';

const EditDesignConfirmModal = ({ open, onClose, onContinue, options, total, accessories }) => {
  const container = document.querySelector('#enterprise-modal-root');
  return container
    ? createPortal(
        <ConfirmModal
          open={open}
          title={i18n('Enterprise.confirmDesignChange')}
          bodyTag="div"
          body={
            <>
              <div className="tds-status_msg">
                <div>
                  <Icon data={iconStatusWarning} style={{ marginInlineEnd: 'var(--tds-size-2x)' }} />
                </div>
                <p>{i18n('Enterprise.confirmChangeMessage')}</p>
              </div>
              <div className="tds-flex tds-flex-gutters">
                <div className="tds--vertical_padding tds-flex-item">
                  <h3 className="tds-text_color--35 tds-text--h6 tds--vertical_padding">
                    {i18n('Enterprise.selectedConfiguration')}
                  </h3>
                  <ul className="tds-list tds-text_color--10">
                    {options.map(item => (
                      <li key={item}>{item}</li>
                    ))}
                  </ul>
                </div>
                <If condition={accessories?.length}>
                  <div className="tds--vertical_padding tds-flex-item">
                    <h3 className="tds-text_color--35 tds-text--h6 tds--vertical_padding">
                      {i18n('Enterprise.orderSummary.accessories')}
                    </h3>
                    <ul className="tds-list tds-text_color--10">
                      {accessories?.map(item => (
                        <li key={item?.partNumber}>{item?.name}</li>
                      ))}
                    </ul>
                  </div>
                </If>
              </div>
              <h3 className="tds-text_color--35 tds-text--h6 tds--vertical_padding">
                {i18n('Enterprise.purchase_price')}
              </h3>
              <ul className="tds-list tds-text_color--10">
                <li>{total}</li>
              </ul>
            </>
          }
          buttonLabel={i18n('Enterprise.confirm')}
          onClose={onClose}
          onContinue={onContinue}
        />,
        container
      )
    : null;
};

EditDesignConfirmModal.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  onContinue: func,
  options: arrayOf(string),
  total: string,
};

export default EditDesignConfirmModal;

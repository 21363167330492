/* eslint-disable no-param-reassign */
import React from 'react';
import _get from 'lodash/get';
import cx from 'classnames';
import { bool, string } from 'prop-types';
import { connect } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import {
  getModelCode,
  isAccessoriesSelected,
  isInventory as getIsInventory,
} from 'selectors';
import PaymentSummaryRepresentation from './representation.js';

const PaymentSummary = (props) => {
  const { ref, inView, entry } = useInView({
    triggerOnce: true,
    rootMargin: '0px 0px 250px 0px',
  });

  return (
    <div className={cx('cf-summary_container', { 'cf-summary_container--loading': !inView })} ref={ref}>
      <If condition={inView}>
        <PaymentSummaryRepresentation {...props} />
      </If>
    </div>
  );
};

function mapStateToProps(state) {
  const {
    isDm,
    enableCyberpunk,
    isLayoutMobileAdjusted: isMobile,
    isDeliverySelectionEnabled,
    ggConf,
    isSaveDesignLiteEnabled
  } = state?.App || {};

  const {
    showPaymentOverview,
  } = state?.Payment || {};

  const isInventory = getIsInventory(state);

  const modelCode = getModelCode(state);
  const model = _get(state, `OMS.lexicon.options.${modelCode}`, {});
  const modelName = _get(model, 'common_name', '') || _get(model, 'long_name', '');

  const showFinanceToggle = isInventory;

  return {
    isDm,
    enableCyberpunk,
    isMobile,
    isDeliverySelectionEnabled,
    showPaymentOverview,
    modelName,
    ggConf,
    loadGoogleScript: !!(isAccessoriesSelected(state) && Object.values(ggConf)?.length),
    showFinanceToggle,
    isSaveDesignLiteEnabled,
    isDownloadPDFEnabled: window?.tesla?.download_pdf_feature_enabled,
    canModifyOrder: state?.ApplicationFlow?.canModifyOrder,
    showPurchasePrice: state?.ReviewDetails?.showPurchasePrice,
  };
}

PaymentSummaryRepresentation.propTypes = {
  isDm: bool,
  enableCyberpunk: bool,
  isMobile: bool,
  isDeliverySelectionEnabled: bool,
  modelName: string,
  showPaymentOverview: bool,
  showFinanceToggle: bool,
  showPurchasePrice: bool,
};

PaymentSummaryRepresentation.defaultProps = {
  isDm: false,
  enableCyberpunk: false,
  isMobile: false,
  isDeliverySelectionEnabled: false,
  modelName: '',
  showPaymentOverview: true,
  showFinanceToggle: false,
  showPurchasePrice: true,
};

export default connect(mapStateToProps, null)(PaymentSummary);

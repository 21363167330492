import {
  USER_DEVICE_UPDATE_QUERY_PARAMS,
  SCREEN_LAYOUT_CHANGE,
  SCREEN_LAYOUT_MOBILE,
  SCREEN_LAYOUT_DESKTOP,
  SCREEN_LAYOUT_TABLET,
  APP_PROCESS_CHANGE,
  SAVE_DESIGN_EXPERIENCE_OFF,
  FINPLAT_INPUTS_CHANGED,
  FINPLAT_PRIVATE_LOAN,
  UPDATE_EXPERIMENT_STATE,
  SET_TRIMS_COLLAPSIBLE_HEIGHT,
  TOGGLE_FEATURE_STATE,
} from 'dictionary';

import { getQueryParameters, generateActivitySessionId } from 'utils';
import { selectTab } from 'actions';
import Analytics from 'analytics';

export function updateAppProcess(process, set){
  return {
    type: APP_PROCESS_CHANGE,
    process,
    set,
  }
}

export function updateQueryParameters() {
  return {
    type: USER_DEVICE_UPDATE_QUERY_PARAMS,
    query_params: getQueryParameters(),
  }
}

export function userDevice() {
  return (dispatch) => {
    dispatch(updateQueryParameters());
  }
}

export function setLoanTerm(termLength) {
  return (dispatch) => {
    dispatch(selectTab(FINPLAT_PRIVATE_LOAN));
    dispatch({
      type: FINPLAT_INPUTS_CHANGED,
      userInput: { termLength },
    });
  }
}


export function setSaveDesignExpOff() {
  return {
    type: SAVE_DESIGN_EXPERIENCE_OFF,
  }
}

export function updateScreenLayout(layoutSize,secondaryLayout) {
  let isLayoutMobile = false;
  let isLayoutMobileAdjusted = false;
  let isLayoutTablet = false;

  switch (layoutSize) {
      case SCREEN_LAYOUT_MOBILE:
          isLayoutMobileAdjusted = secondaryLayout;
          isLayoutMobile = true;
          break

      case SCREEN_LAYOUT_TABLET:
          isLayoutMobileAdjusted = secondaryLayout;
          isLayoutTablet = true;
          break

      case SCREEN_LAYOUT_DESKTOP:
      default:
  }

  return {
    type: SCREEN_LAYOUT_CHANGE,
    isLayoutMobile,
    isLayoutTablet,
    isLayoutMobileAdjusted,
  };
};

export function updateExperimentState(props) {
  const { isTradeInEnabled = false } = window?.tesla || {};
  const { isTradeInExperimentEnabled = false } = props || {};

  return {
    type: UPDATE_EXPERIMENT_STATE,
    payload: {
      ...props,
      isTradeInEnabled: !!(isTradeInEnabled && isTradeInExperimentEnabled),
    },
  }
};

export function setTrimsCollapsibleHeight(payload) {
  return {
    type: SET_TRIMS_COLLAPSIBLE_HEIGHT,
    payload,
  }
};

export function toggleFeatureState(feature) {
  return {
    type: TOGGLE_FEATURE_STATE,
    feature,
  }
};

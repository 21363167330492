import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty';

import {
    PRICE_CHANGED,
    PRICE_UPDATE_REFERRAL,
    BASE_CONFIGURATION_CHANGED,
    PRICE_ADD_DISCOUNT,
} from 'dictionary';

function Pricing(state = {}, action, { app_state }) {
    const baseTotal = _get(state, 'base.total', 0);
    switch (action.type) {
        case BASE_CONFIGURATION_CHANGED:
            return Object.assign({}, state, {
                base: {
                    total: action.price,
                    diff: 0
                }
            });

        case PRICE_CHANGED:
            return Object.assign({}, state, {
                financeType:            _get(action.data, 'financeType'),
                subtotal:               _get(action.data, 'apiResults.price.subtotalPrice'),

                grossPrice:             _get(action.data, 'grossPrice'),
                netPrice:               _get(action.data, 'result.cash.netPrice'),

                financeAmountDueAtSigning: _get(action.data, 'result.loan.LoanAPI.loanAmount.amountDueAtSigning'),
                financeTerm:            _get(action.data, 'result.loan.LoanAPI.loanAmount.term'),
                financeInterestRate:    _get(action.data, 'result.loan.LoanAPI.loanAmount.interestRate'),
                financePaymentMonthly:  _get(action.data, 'result.loan.LoanAPI.loanAmount.monthlyPayment'),
                financeResidualAmount:  _get(action.data, 'result.loan.LoanAPI.loanAmount.residualAmount'),

                leaseAmountDueAtSigning: _get(action.data, 'result.lease.LeaseAPI.leaseAmount.amountDueAtSigning'),
                leasePaymentMonthly:    _get(action.data, 'result.lease.LeaseAPI.leaseAmount.monthlyPayment'),
                leaseInterestRate:      _get(action.data, 'result.lease.LeaseAPI.leaseAmount.interestRate'),
                leaseResidualAmount:    _get(action.data, 'result.lease.LeaseAPI.leaseAmount.residualAmount'),

                prices_per_code:        _get(action.data, 'apiResults.price.matchedCodes'),
                price_string:           _get(action.data, 'apiResults.price.priceString'),
                manufacturing_string:   _get(action.data, 'apiResults.price.manufacturingString'),
                cash:                   _get(action.data, 'result.cash'),
                lease:                  _get(action.data, 'result.lease'),
                finance:                _get(action.data, 'result.loan'),
                finplat:                _get(action.data, 'result.finplat', null),
                balloonPayment:         _get(action.data, 'result.balloonPayment'),
                total:                  _get(action.data, 'variables.vehiclePrice'),
            }, {
                calculatorResult: {data: action.data},
                calculatorParameters: action.calculatorParameters
            }, {
                base: {
                    total: baseTotal,
                    diff:  action.data.variables.vehiclePrice - baseTotal,
                }
            })

        case PRICE_UPDATE_REFERRAL:

            const model_name = app_state.Configuration.configurations_detail.filter(configuration=>configuration.selected)[0].name
            let message      = _get(action, 'data.message', '').slice(0,-1)
            if (_get(action, 'data.discount', 0)) {
                message += ' ' + model_name
            }

            return Object.assign({}, state, {
                discount: {
                    referral: {
                        enabled: true,
                        title: action.data.title,
                        code: action.data.referral_code,
                        message: message,
                        value: action.data.discount,
                        data: action.data
                    }
                }
            })

        case PRICE_ADD_DISCOUNT:

            return Object.assign({}, state, {
                discounts: [].concat(state.discounts.filter(discount=>discount.key !== action.key), {key: action.key, value: action.value})
            });


        default:
            return state
    }
}

export default Pricing;

import _get from 'lodash/get';
import superagent from 'superagent';
import { v4 } from 'uuid';

let activitySessionId = null;
/**
 * Get / generate an activity session ID
 * @return string
 */
export function getActivitySessionId() {
    if (!activitySessionId) {
        activitySessionId = v4();
    }
    return activitySessionId;
}

const teslaObj = _get(window, 'tesla', {});
const { App = {}, setLocaleHeader } = teslaObj;
const { locale } = App || {};
let request = superagent.agent();
if (setLocaleHeader && locale) {
  request = request.set('x-locale', locale.replace('_', '-'));
}
request.set('x-correlation-id', getActivitySessionId());

request.use((req) => {
  const teslaConfigObj = _get(window, 'teslaConfig', {});
  const { csrf_key: csrfKey, csrf_token: csrfToken } = teslaConfigObj;
  if (csrfKey && csrfToken) {
    req.header['csrf-Name'] = csrfKey;
    req.header['csrf-Value'] = csrfToken;
  }
  return req;
});

export { 
  request,
};
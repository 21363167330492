import React from 'react';
import { connect } from 'react-redux';
import { bool, string } from 'prop-types';
import { i18n } from 'utils';
import { getPricePostfix } from 'selectors';

const ExVatDisclaimer = ({ showVATBreakdown, pricePostfix, context }) => {
  if (!showVATBreakdown) {
    return null;
  }
  const estimatedPriceLabel = i18n('Review.purchase_price_estimated', null, null, {
    returnNullWhenEmpty: true,
  });
  return (
    <p className="tds-text--caption tds-text--center tds--vertical_padding tds-flex tds-flex--row-nowrap tds-flex--center tds-flex--justify-center">
      {pricePostfix}
      {estimatedPriceLabel
        ? estimatedPriceLabel
        : i18n('Review.purchase_price_ex_vat', null, null, {
            specificOverride: context,
          })}
    </p>
  );
};

function mapStateToProps(state) {
  return {
    showVATBreakdown: state?.ReviewDetails?.showVATBreakdown ?? false,
    pricePostfix: getPricePostfix(state),
  };
}

ExVatDisclaimer.propTypes = {
  showVATBreakdown: bool,
  pricePostfix: string,
  context: string,
};

ExVatDisclaimer.defaultProps = {
  showVATBreakdown: false,
  pricePostfix: '',
  context: '',
};

export default connect(mapStateToProps)(ExVatDisclaimer);

import {
    USER_DEVICE_IS_MOBILE_UPDATE,
    USER_DEVICE_UPDATE_QUERY_PARAMS,
    APPLICATION_FLAGS,
    APP_PROCESS_CHANGE,
    SCREEN_LAYOUT_CHANGE,
    SAVE_DESIGN_EXPERIENCE_OFF,
    GET_UPDATES,
    UPDATE_EXPERIMENT_STATE,
    SET_TRIMS_COLLAPSIBLE_HEIGHT,
    TOGGLE_FEATURE_STATE,
} from 'dictionary'

import _get from 'lodash/get'

function updateProcess(processes, process, action) {
    let data = processes.reduce((result, item) => {
        if ('add' === action || ('remove' === action && item !== process)){
            result.push(item)
        }
        return result
    }, [])
    if (!data.includes(process) && 'add' === action) {
        data.push(process)
    }
    return data
}

/**
*   Reducer for detecting and updating state with user inforamation
*   [navigator.userAgent]
*/
function App(state = {}, action){
    switch (action.type) {
        case USER_DEVICE_UPDATE_QUERY_PARAMS:
            return Object.assign({}, state, {
                query_params: action.query_params
            })
        case USER_DEVICE_IS_MOBILE_UPDATE:
            return Object.assign({}, state, {
                isMobile: action.isMobile,
                userOS: action.userOS
            })
        case APPLICATION_FLAGS:
            return Object.assign({}, state, {
                reduxLogger: action.reduxLogger,
                analytics: action.analytics,
                debug: action.debug || false,
                suppressConsole: action.debug === false
            })
        case APP_PROCESS_CHANGE:
            let processes = _get(state, 'processes', []).slice(0)
            return Object.assign({}, state, {
                processes: updateProcess(processes, action.process, action.set)
            })
        case SCREEN_LAYOUT_CHANGE:
            return Object.assign({}, state, {
                isLayoutMobile: action.isLayoutMobile,
                isLayoutTablet: action.isLayoutTablet,
                isLayoutMobileAdjusted: action.isLayoutMobileAdjusted,
                trimsCollapsibleHeight: {},
            })

        case SAVE_DESIGN_EXPERIENCE_OFF:
            return {
                ...state,
                isSaveDesignLiteEnabled: false,
            }
        case GET_UPDATES:
            const { id, defaultValue } = action || {};
            const { [id]: value } = state?.getUpdates || {};
            const flag = value !== undefined ? value : defaultValue;
            return {
                ...state,
                getUpdates: {
                ...state.getUpdates,
                [id]: !flag,
                },
            };
        case UPDATE_EXPERIMENT_STATE:
            return {
                ...state,
                ...(action?.payload || {}),
            }
        case SET_TRIMS_COLLAPSIBLE_HEIGHT:
            const { payload = {} } = action || {};
            return {
                ...state,
                trimsCollapsibleHeight: { ...state.trimsCollapsibleHeight ?? {}, ...payload },
            }
        case TOGGLE_FEATURE_STATE:
            const { feature } = action || {};
            return {
                ...state,
                currentFeatures: {
                    ...(state?.currentFeatures || {}),
                    [`${feature}`]: !state?.currentFeatures?.[feature],
                },
            };
        default:
            return state
    }
}

export default App
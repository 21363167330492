import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import {
  setAccountDetailsValidFlag,
  updateAccountDetails,
  updateDeliveryDetails,
  togglePaymentForm,
  updateAccountIDTypeLabel,
  updateBillingInfo,
  toggleFinancePanelDrivertype,
  selectTab,
  setVerifiedPhoneNumber,
} from 'actions';

import AccountFormRepresentation from './representation';
import { isInventory } from 'selectors';

const AccountForm = props => <AccountFormRepresentation {...props} />;

function mapStateToProps(state) {
  const { App, ReviewDetails, FinancingOptions } = state;
  const { countryCode, locale, sibling = '' } = App;
  const {
    disableCompanySearch = false,
    hidePlaceHolderInput,
    showPrivateFields = [],
    taxOffices = [],
    usesOfInvoice = [],
    defaultUseOfInvoice = '',
  } = ReviewDetails;
  const { driverType } = FinancingOptions;
  const registrationType = _get(state, 'ReviewDetails.AccountDetail.AccountType', '');
  return {
    emailExists: state.ReviewDetails.emailExists,
    emailCheckLoading: state.ReviewDetails.emailCheckLoading,
    emailConfirmEnabled: _get(state, 'ReviewDetails.emailConfirmEnabled', true),
    accountFormValid: state.ReviewDetails.accountDetailsValid,
    market: countryCode,
    userFilledInData: state.ReviewDetails.AccountDetail,
    ccFormOpen: state.Payment.formOpen,
    showAccountTypeSwitch: state.ReviewDetails.showAccountTypeSwitch,
    showVAT: state.ReviewDetails.showVAT,
    showCompanyAgentLabel: state.ReviewDetails.showCompanyAgentLabel,
    showLocalName: state.ReviewDetails.showLocalName,
    showLegalConsent: state.ReviewDetails.showLegalConsent,
    extraBusinessFields: state.ReviewDetails.extraBusinessFields,
    extraPersonalFields: state.ReviewDetails.extraPersonalFields,
    identificaitonTypes: state.ReviewDetails.identificaitonTypes,
    identificationLabel: state.ReviewDetails.identificationLabel,
    registration: _get(state, 'Payment.registration', {}),
    registrationType,
    identificationType: _get(state, 'ReviewDetails.AccountDetail.IdentificationType', ''),
    privateVatId: _get(state, 'ReviewDetails.AccountDetail.PrivateVatId', ''),
    companyName: _get(state, 'ReviewDetails.AccountDetail.CompanyName', ''),
    vatId: _get(state, 'ReviewDetails.AccountDetail.VatId', ''),
    model: _get(state, 'OMS.oms_params.model', ''),
    sibling,
    locale,
    country: countryCode,
    showAccountDetailDisclaimer: _get(state, 'ReviewDetails.showAccountDetailDisclaimer'),
    USLegalDisclaimer: _get(state, 'ReviewDetails.USLegalDisclaimer'),
    isIntlPhoneInputEnabled: state?.ReviewDetails?.isIntlPhoneInputEnabled || false,
    isBusinessVerificationEnabled: _get(
      state,
      'ReviewDetails.isBusinessVerificationEnabled',
      false
    ),
    allowCrossBorderRegistration: _get(state, 'ReviewDetails.allowCrossBorderRegistration', false),
    isCompanyAgentIdEnabled: _get(state, 'App.isCompanyAgentIdEnabled', false),
    isInline: state.App.enableCyberpunk,
    isPasswordlessFlow: state.App.isPasswordlessFlow,
    phone: String(_get(state, 'ReviewDetails.AccountDetail.PhoneNumber', '')),
    phoneCountryCode: _get(state, 'ReviewDetails.AccountDetail.PhoneCountry', countryCode),
    defaultAccountType: state.ReviewDetails.defaultAccountType,
    isInventory: isInventory(state),
    disableCompanySearch,
    driverTypeFromRedux: driverType,
    hidePlaceHolderInput,
    serverError: state.Payment?.serverError,
    showPrivateFields,
    isVerifyPhoneNumberEnabled: _get(state, 'App.isVerifyPhoneNumberEnabled', false),
    verifyPhoneNumberHost: _get(state, 'App.verifyPhoneNumberHost', ''),
    verifyPhoneNumberUid: _get(state, 'App.verifyPhoneNumberUid', ''),
    verifyPhoneNumberFlow: _get(state, 'App.orderVerifyPhoneNumberFlow', ''),
    isNonBrandNewConsentEnabled: _get(state, 'App.isNonBrandNewConsentEnabled', false),
    titleStatus: _get(state, 'ReviewDetails.product.data.TitleStatus', ''),
    titleSubStatus: _get(state, 'ReviewDetails.product.data.TitleSubStatus', ''),
    taxOfficesList: taxOffices[registrationType] ?? [],
    usesOfInvoiceList: usesOfInvoice ?? [],
    defaultUseOfInvoice,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setValidFlag: flag => {
      dispatch(setAccountDetailsValidFlag(flag));
    },
    updateAccountDetails: payload => {
      dispatch(updateAccountDetails(payload));
    },
    updateDeliveryDetails: payload => {
      dispatch(updateDeliveryDetails(payload));
    },
    updateAccountIDTypeLabel: payload => {
      dispatch(updateAccountIDTypeLabel(payload));
    },
    updateBillingInfo: payload => {
      dispatch(updateBillingInfo(payload));
    },
    togglePaymentForm: flag => {
      dispatch(togglePaymentForm(flag));
    },
    setDrivertype: driverType => {
      dispatch(toggleFinancePanelDrivertype(driverType));
    },
    selectTab: tab => {
      dispatch(selectTab(tab));
    },
    setVerifiedPhoneNumber: payload => {
      dispatch(setVerifiedPhoneNumber(payload));
    },
  };
}

AccountForm.propTypes = {
  emailExists: PropTypes.shape({
    flag: PropTypes.bool,
    email: PropTypes.string,
  }),
  emailCheckLoading: PropTypes.bool,
  market: PropTypes.string.isRequired,
  accountFormValid: PropTypes.bool.isRequired,
  ccFormOpen: PropTypes.bool.isRequired,
  showAccountTypeSwitch: PropTypes.bool,
  showCompanyAgentLabel: PropTypes.bool,
  showLocalName: PropTypes.bool,
  showVAT: PropTypes.bool,
  extraBusinessFields: PropTypes.arrayOf(PropTypes.string),
  extraPersonalFields: PropTypes.arrayOf(PropTypes.any),
  identificaitonTypes: PropTypes.arrayOf(PropTypes.any),
  isCompanyAgentIdEnabled: PropTypes.bool,
  showLegalConsent: PropTypes.arrayOf(PropTypes.string),
  isPasswordlessFlow: PropTypes.bool,
  isVerifyPhoneNumberEnabled: PropTypes.bool,
  verifyPhoneNumberHost: PropTypes.bool,
  verifyPhoneNumberUid: PropTypes.bool,
  verifyPhoneNumberFlow: PropTypes.bool,
  isNonBrandNewConsentEnabled: PropTypes.bool,
  titleStatus: PropTypes.string,
  titleSubStatus: PropTypes.string,
};

AccountForm.defaultProps = {
  emailExists: {
    flag: true,
    email: '',
  },
  emailCheckLoading: false,
  showCompanyAgentLabel: false,
  showLocalName: false,
  showVAT: false,
  extraBusinessFields: [],
  extraPersonalFields: [],
  identificaitonTypes: [],
  isCompanyAgentIdEnabled: false,
  showLegalConsent: [],
  showAccountTypeSwitch: false,
  isPasswordlessFlow: false,
  ccFormOpen: false,
  accountFormValid: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountForm);

const { isAccessoriesEnabled = false } = window?.tesla || {};

export const InitialState = {
  // reservation flow
  reservation: {
    allowedSections: ['payment'],
    section: 'payment',
    main: [
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main.BATTERY_AND_DRIVE',
        group: 'BATTERY_AND_DRIVE',
        sections: ['payment'],
      },
      {
        component: 'PaymentOverview',
        key: 'PAYMENT',
        sections: ['payment'],
      },
    ],
    gallery: [
      {
        component: 'MainGallery',
        key: 'MainGallery:gallery.EXTERIOR',
        group: 'EXTERIOR_GALLERY',
        sections: ['payment'],
      },
    ],
  },
  // reservation to order flow
  order: {
    allowedSections: ['overview', 'payment'],
    section: 'overview',
    activeGroupId: 'BATTERY_AND_DRIVE_MOTOR_GROUP',
    main: [
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main.BATTERY_AND_DRIVE_MOTOR_GROUP',
        group: 'BATTERY_AND_DRIVE_MOTOR_GROUP',
        sections: ['overview'],
      },
      (isAccessoriesEnabled ? {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main.ELECTRONIC_ACCESSORIES',
        group: 'ELECTRONIC_ACCESSORIES',
        sections: ['overview'],
        props: {
          classes: "tds-theme--replicant-dotted-bg",
        }
      } : {}),
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main.VEHICLE_FEATURES',
        sections: ['overview'],
        group: 'VEHICLE_FEATURES',
        props: {
          classes: "tds-theme--replicant-dotted-bg",
        }
      },
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main.POWER_YOUR_HOME',
        sections: ['overview'],
        group: 'POWER_YOUR_HOME',
        props: {
          classes: "tds-theme--replicant-dotted-bg",
        }
      },
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main.EXTERIOR',
        sections: ['overview'],
        group: 'EXTERIOR',
        props: {
          classes: "tds-theme--replicant-dotted-bg",
        }
      },
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main.WHEELS',
        sections: ['overview'],
        group: 'WHEELS',
        props: {
          classes: "tds-theme--replicant-dotted-bg",
        }
      },
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main.INTERIOR',
        sections: ['overview'],
        group: 'INTERIOR',
        props: {
          classes: "tds-theme--replicant-dotted-bg",
        }
      },
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main.AUTOPILOT_PACKAGE',
        sections: ['overview'],
        group: 'AUTOPILOT_PACKAGE',
        props: {
          classes: "tds-theme--replicant-dotted-bg",
        }
      },
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main.SIGNATURE_PACKAGE',
        sections: ['overview'],
        group: 'SIGNATURE_PACKAGE',
        props: {
          classes: "tds-theme--replicant-dotted-bg",
        }
      },
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main.ACCESSORIES_PACKAGE',
        sections: ['overview'],
        group: 'ACCESSORIES_PACKAGE',
        props: {
          classes: "tds-theme--replicant-dotted-bg",
        }
      },
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main.LIGHTBAR',
        sections: ['overview'],
        group: 'LIGHTBAR',
        props: {
          classes: "tds-theme--replicant-dotted-bg",
        }
      },
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main:CHARGING',
        sections: ['overview'],
        group: 'CHARGING',
        props: {
          classes: "tds-theme--replicant-dotted-bg",
        }
      },
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main:PAYMENT_OVERVIEW',
        sections: ['overview'],
        group: 'PAYMENT_OVERVIEW',
      },
      {
        component: 'EditDesignLink',
        key: 'EditDesignLink:main.PAYMENT',
        sections: ['payment'],
        props: {
          classes: 'tds-content_container tds--padding tds--no_vertical_padding',
        },
      },
      {
        component: 'AssetLoader',
        key: 'PAYMENT.asset',
        sections: ['payment'],
        props: {
          asset: {
            bkba: 4,
            type: 'image-compositor',
            view: 'REAR34',
            crop: [1400, 800, 230, 130],
          },
          className: 'group-main-content--full-asset ',
          isAsset: true,
        },
        selected_by: {
          or: [
            {
              on: ['tablet', 'mobile'],
            },
          ],
        },
      },
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main.REVIEW_ORDER',
        group: 'REVIEW_ORDER',
        sections: ['payment'],
        props: {
          classes: 'cf-content_container',
        },
      },
      {
        component: 'PaymentOverview',
        key: 'PAYMENT',
        sections: ['payment'],
        props: {
          classes: 'tds-content_container'
        }
      },
    ],
    gallery: [
      {
        component: 'MainGallery',
        key: 'MainGallery:gallery.FSD',
        group: 'FSD_GALLERY',
        excludeGroup: 'BATTERY_AND_DRIVE_MOTOR_GROUP',
        sections: ['overview'],
      },
      {
        component: 'MainGallery',
        key: 'MainGallery:gallery.EXTERIOR',
        group: 'EXTERIOR_ASSET',
        sections: ['overview'],
      },
      {
        component: 'MainGallery',
        key: 'MainGallery:gallery.EXTERIOR',
        group: 'EXTERIOR_ASSET',
        gallery: {
          group: 'EXTERIOR_ASSET',
          section: 2,
        },
        sections: ['payment'],
      },
    ],
  },
  
};
export default InitialState;

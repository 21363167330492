import React, { useEffect, useRef } from 'react';
import { shape, arrayOf, func, bool, string } from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import { Card } from '@tesla/design-system-react';
import { UI_DATA_IDS, SHOW_BANNER_MODAL, EXPERIMENT_TOP_BANNER_OFF } from 'dictionary';
import { htmlToReact } from 'utils';

import TextLoader from '../TextLoader';
import ActionTrigger from '../ActionTrigger';
import _get from 'lodash/get';
import { Icon } from '@tesla/design-system-react';
import { iconChevron90 } from '@tesla/design-system-icons';

const DynamicBanner = ({ dynamicBannerContent, openBannerModal, referralCode, countryCode, isCoinReloaded, isLayoutMobile, isLayoutTablet, triggerAction, hasDynamicContent, bannerDisabled }) => {
  const topBannerRef = useRef(null);
  const contentLength = dynamicBannerContent?.length;
  const isTopBanner = !bannerDisabled && isCoinReloaded && contentLength === 1;
  const headerContainer = document.querySelector('.site-header-container');
  const t4bNotificationStickyBanner = document.querySelector('.site-header-container .tds-banner');
  const isDesktop = !(isLayoutMobile || isLayoutTablet);

  useEffect(() => {
    if (isTopBanner) {
      document.body.classList.add('has-top-banner');
    } else {
      document.body.classList.remove('has-top-banner');
    }
  }, [isTopBanner])

  useEffect(() => {
    let bannerHeightTimer = setTimeout(() => {
      if (topBannerRef?.current?.clientHeight || hasDynamicContent) {
        const bannerHeight = parseInt(topBannerRef?.current?.clientHeight) || 0;
        const headerHeight = headerContainer ? parseInt(getComputedStyle(headerContainer)?.height) || 0 : 0;
        const t4bBannerHeight = t4bNotificationStickyBanner ? parseInt(getComputedStyle(t4bNotificationStickyBanner)?.height) || 0 : 0;
        const totalHeight = bannerHeight + headerHeight + t4bBannerHeight;
        const root = document.getElementById('root');
        if (root) {
          root.style.setProperty('--coin-top-banner-height', `${bannerHeight}px`);
          root.style.setProperty('--tds-shell-header-height', `${totalHeight}px`);
          document.documentElement.style.setProperty('--tds-shell-header-height--override', `${totalHeight}px`);
        }
      }
    }, 200);
    return () => {
      clearTimeout(bannerHeightTimer);
    };
  }, [topBannerRef?.current?.clientHeight, isTopBanner, headerContainer])


  if (!contentLength || (isCoinReloaded && !isTopBanner)) {
    return null;
  }

  const isMultiBanner = contentLength > 1;
  const isCentered =
    contentLength < 2 && dynamicBannerContent?.[0]?.isAlignLeft !== true;
  const onClick = data => {
    const { modalContent, modalTitle, modalSubtitle, modalBtnCopy, modalBtnBehavior } = data || {};
    const content =
      modalTitle && modalContent
        ? [
            {
              modalTitle,
              modalSubtitle,
              modalContent,
              modalBtnCopy,
              modalBtnBehavior,
            },
          ]
        : [];
    openBannerModal({ content });
  };

  const addReferralToLink = content => {
    if (countryCode !== 'CN') {
      return content;
    }
    const regEx = /["']https?:\/\/.*?["']/gi;
    let urls = content.match(regEx);
    if (Array.isArray(urls)) {
      urls = _.uniq(urls);
      urls.forEach(function(url) {
        if (!url || url.indexOf('referral') >= 0 || !referralCode) {
          return;
        }
        url = url.replaceAll('\'', '').replaceAll('\"', '');
        let targetUrl = new URL(url);
        targetUrl.searchParams.append('referral', referralCode);
        content = _.replace(content, url, targetUrl.toString());
      });
    }
    return content;
  };
  const getContent = bannerContent => {
    if (isTopBanner) {
      const { description, btnCopy, btnBehavior } = bannerContent[0];
      const replacedDescription = addReferralToLink(description);
      return (
          <>
            <If condition={description}>
              <TextLoader
                  data={replacedDescription}
                  transitionWrapper={false}
              />
            </If>
            &nbsp;
            <If condition={btnCopy && (btnBehavior?.length || bannerContent[0]?.modalContent)}>
              <div className="modal-trigger-container">
                <Choose>
                  <When condition={btnBehavior?.length && isDesktop}>
                    <TextLoader
                        data={{
                          content: btnCopy,
                          behavior: btnBehavior,
                        }}
                    />
                  </When>
                  <When condition={btnCopy && isDesktop}>
                    <button className="modal-trigger tds-link" onClick={() => onClick(bannerContent[0])}>
                      {htmlToReact(btnCopy)}
                    </button>
                  </When>
                  <When condition={!isDesktop}>
                    <Icon data={iconChevron90} />
                  </When>
                </Choose>
              </div>
            </If>
          </>
      )
    };
    return (
      <>
        {bannerContent?.map((content, index) => {
          const { title, subtitle, description, btnCopy, btnBehavior, link } = content || {};
          const replacedDescription = addReferralToLink(description);
          return (
            <div key={`banner-id-${index}`} className={cx({ 'tds--padding_top-16': index })}>
              <If condition={title}>
                <TextLoader data={title} tag={{ component: 'strong' }} className="tds-text--h3" />
              </If>
              <If condition={subtitle}>
                <TextLoader
                  data={subtitle}
                  tag={{ component: 'strong' }}
                  className="tds-text--body"
                />
              </If>
              <If condition={description}>
                <TextLoader
                  data={replacedDescription}
                  transitionWrapper={false}
                  className="tds--padding_top-8 tds-text--caption"
                />
              </If>
              <If condition={btnCopy && (btnBehavior?.length || content?.modalContent)}>
                <div className="tds--padding_top-8 modal-trigger-container">
                  <Choose>
                    <When condition={btnBehavior?.length}>
                      <TextLoader
                        data={{
                          content: btnCopy,
                          behavior: btnBehavior,
                        }}
                      />
                    </When>
                    <When condition={btnCopy}>
                      <button className="modal-trigger tds-link" onClick={() => onClick(content)}>
                        {htmlToReact(btnCopy)}
                      </button>
                    </When>
                  </Choose>
                </div>
              </If>
              <If condition={link}>
                <div className="text-loader--content tds-text--center federal-tax--link tds-text--caption">
                  {htmlToReact(link)}
                </div>
              </If>
            </div>
          );
        })}
      </>
    );
  };
  const bannerContentBlock = getContent(dynamicBannerContent);
  if (!bannerContentBlock) {
    return null;
  }

  if (isTopBanner) {
    const onTouch = () => {
      if (!isDesktop) {
        if (dynamicBannerContent[0]?.modalContent) {
          onClick(dynamicBannerContent[0])
        }
        else if(dynamicBannerContent[0]?.btnBehavior) {
          triggerAction({ actionType: dynamicBannerContent[0]?.btnBehavior[0]?.content?.type });
        }
      }
    }
    return (
        <div className="top-banner" ref={topBannerRef} onClick={onTouch}>
          <ActionTrigger>
            {bannerContentBlock}
          </ActionTrigger>
        </div>
    );
  }

  return (
    <div className={'tds-flex tds-flex--justify-center'}>
      <Card
        className={cx('coin-prominent--currency tds-scrim--black tds-scrim--gradient', {
          'coin-card--multibanner': isMultiBanner,
          'tds-text--center': isCentered,
        })}
        data-id={UI_DATA_IDS?.overviewSection?.banner}
      >
        <ActionTrigger>
          {bannerContentBlock}
        </ActionTrigger>
      </Card>
    </div>
  );
};

DynamicBanner.propTypes = {
  dynamicBannerContent: arrayOf(shape({})).isRequired,
  openBannerModal: func.isRequired,
  triggerAction: func.isRequired,
  hasDynamicContent: bool.isRequired,
  referralCode: string,
  countryCode: string.isRequired,
  isCoinReloaded: bool.isRequired,
  isLayoutMobile: bool.isRequired,
  isLayoutTablet: bool.isRequired,
  bannerDisabled: bool.isRequired,
};

DynamicBanner.defaultProps = {
  referralCode: '',
};

function mapDispatchToProps(dispatch) {
  return {
    openBannerModal: props => {
      dispatch({
        type: SHOW_BANNER_MODAL,
        props,
      });
    },
    triggerAction: props => {
      dispatch({
        type: props?.actionType
      });
    },
  };
}

function mapStateToProps(state) {
  const { ApplicationFlow } = state;
  const { optimizelyExpId = null, referral = {} } = ApplicationFlow;
  const { referralCode = '' } = referral || {};
  const countryCode = _get(state, 'OMS.oms_params.market');
  return {
    referralCode,
    countryCode,
    isCoinReloaded: countryCode !== 'CN' && state?.App?.isCoinReloaded,
    isLayoutMobile: state?.App?.isLayoutMobile,
    isLayoutTablet: state?.App?.isLayoutTablet,
    bannerDisabled: (optimizelyExpId === EXPERIMENT_TOP_BANNER_OFF),
  };
  }

export default connect(mapStateToProps, mapDispatchToProps)(DynamicBanner);

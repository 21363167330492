import {
  SHOW_MODAL_FORM,
  PRICE_CHANGED,
  SUMMARY_PANEL_TAB_CHANGE,
  BASE_CONFIGURATION_CHANGED,
  TOGGLE_LOAN_TYPE,
  FINPLAT_INPUTS_CHANGED,
  FINANCE_PLAN_CHANGE,
  COMPLETE_CONFIGURATION_CHANGED,
} from 'dictionary';
import {
  getFinancialTerms,
  getFinanceProductId,
  getDownPaymentRangeMatrix,
  getDefaultDownPaymentPercentFromMatrix,
  getDefaultDownPaymentAmountFromMatrix,
  getTermOptions,
  getAprMatrix,
} from 'selectors';
import _get from 'lodash/get';

export default function Reducer(state = {}, action, { app_state }) {
  const financeType = getFinanceProductId(app_state);
  const financePlanId = action.financePlanId || action.calculatorParameters?.financePlanId;
  const financialTerms = getFinancialTerms(app_state, financePlanId);
  const financeTypeData = financialTerms?.[`${financeType}Data`];
  switch (action.type) {
    case BASE_CONFIGURATION_CHANGED:
    case SHOW_MODAL_FORM:
    case PRICE_CHANGED:
    case TOGGLE_LOAN_TYPE:
      return {
        ...state,
        ...getFinancialTerms(app_state, financePlanId),
      };
    case FINPLAT_INPUTS_CHANGED: {
      const productId = getFinanceProductId(app_state);
      const { finplatUserInputs = {} } = state;
      const formState = finplatUserInputs?.[productId] || {};

      return {
        ...state,
        finplatUserInputs: {
          ...finplatUserInputs,
          [productId]: {
            ...formState,
            ...action.userInput,
          },
        },
      };
    }
    case SUMMARY_PANEL_TAB_CHANGE:
    case COMPLETE_CONFIGURATION_CHANGED:
      const countryCode = _get(app_state, 'App.countryCode', '');
      if (countryCode === 'CN') {
        return {
          ...state,
          ...financialTerms,
            [`${financeType}Data`]: {
              ...financeTypeData,
                params: {
                  ...financeTypeData?.params,
                    financePlanId: financePlanId,
                    downPaymentPercent: getDefaultDownPaymentPercentFromMatrix(app_state, financePlanId),
                    downPaymentAmount: getDefaultDownPaymentAmountFromMatrix(app_state, financePlanId),
                },
            },
        };
      } else {
        return {
          ...state,
          ...financialTerms,
        };
      }
    case FINANCE_PLAN_CHANGE:
      return {
        ...state,
        ...financialTerms,
          [`${financeType}Data`]: {
            ...financeTypeData,
              params: {
                ...financeTypeData?.params,
                financePlanId: action.financePlanId,
                downPaymentPercent: getDefaultDownPaymentPercentFromMatrix(app_state, action.financePlanId),
                downPaymentAmount: getDefaultDownPaymentAmountFromMatrix(app_state, action.financePlanId),
              },
              isPromotionPlan: action?.isPromotionPlan?? false,
              isStandardPlan: action?.isStandardPlan?? true
          },
      };

    default:
      return state;
  }
}

import { useState, useEffect } from 'react';
const libs = [];

export default function useScript(url, name, type = 'script', cb = null) {
  const [lib, setLib] = useState({});

  useEffect(() => {
    if (libs[`${name}:${type}`]) {
      return;
    }
    let script = '';
    switch (type) {
      case 'style':
        script = document.createElement('link');
        script.rel = 'stylesheet';
        script.type = 'text/css';
        script.href = url;
        script.media = 'all';
        break;
      case 'script':
      default:
        script = document.createElement('script');
        script.src = url;
        script.async = true;
        break;
    }
    script.onload = () => {
      if (typeof cb === 'function') {
        cb();
      }
      libs[`${name}:${type}`] = url;
      setLib({ [name]: window[name] });
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);

  return lib;
}

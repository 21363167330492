import { filter, map } from 'rxjs/operators';
import {
  SHOW_MODAL_FORM,
  SUMMARY_PANEL_LEASE_TERM_CHANGE,
  SUMMARY_PANEL_FINANCE_TERM_CHANGE,
  SUMMARY_PANEL_LEASE_APR_CHANGE,
  SUMMARY_PANEL_FINANCE_APR_CHANGE,
  SUMMARY_PANEL_FINANCE_BALLOON_PAYMENT_CHANGE,
  SUMMARY_PANEL_FINANCE_DISTANCE_CHANGE,
  SUMMARY_PANEL_DISTANCE_CHANGE,
  SUMMARY_PANEL_FUEL_INCENTIVE_CHANGE,
  SUMMARY_PANEL_RESIDUAL_AMOUNT_CHANGE,
  FINANCIAL_FORM_STATE_CHANGE,
  MODAL_OPEN,
  TOGGLE_LOAN_TYPE,
  SUMMARY_PANEL_INTEREST_RATE_TYPE_CHANGE,
  FINANCE_PLAN_CHANGE
} from 'dictionary';

export const showForm = selectedForm => dispatch => {
  dispatch({
    type: SHOW_MODAL_FORM,
    selectedForm,
  });
};

export const storeFinancialFormState = action$ =>
  action$.pipe(
    filter(action =>
      [
        SUMMARY_PANEL_LEASE_TERM_CHANGE,
        SUMMARY_PANEL_FINANCE_TERM_CHANGE,
        SUMMARY_PANEL_LEASE_APR_CHANGE,
        SUMMARY_PANEL_FINANCE_APR_CHANGE,
        SUMMARY_PANEL_FINANCE_BALLOON_PAYMENT_CHANGE,
        SUMMARY_PANEL_DISTANCE_CHANGE,
        SUMMARY_PANEL_FINANCE_DISTANCE_CHANGE,
        SUMMARY_PANEL_FUEL_INCENTIVE_CHANGE,
        SUMMARY_PANEL_RESIDUAL_AMOUNT_CHANGE,
        SUMMARY_PANEL_INTEREST_RATE_TYPE_CHANGE,
        MODAL_OPEN,
        TOGGLE_LOAN_TYPE,
      ].includes(action.type)
    ),
    map(action => {
      switch (action.type) {
        case MODAL_OPEN: {
          const { props } = action;
          const { form: selectedForm, ...rest } = props;
          return {
            type: SHOW_MODAL_FORM,
            selectedForm,
            props: rest,
          };
        }
        default:
          return {
            type: FINANCIAL_FORM_STATE_CHANGE,
            leaseTerm: action.leaseTerm,
            loanTerm: action.leaseTerm,
            loanDistance: action.loanDistance,
            leaseApr: action.leaseApr,
            leaseDistance: action.leaseDistance,
            financeeApr: action.financeApr,
            balloonPayment: action.balloonPayment,
            fuelParams: action.params,
            residualAmount: action.residualAmount,
            interestRateType: action.interestRateType,
            userLeaseAmount: action.userLeaseAmount
          };
      }
    }));

  export const setFinancePlan = ({value: financePlanId, grossPrice, isPromotionPlan, ...rest}) => ({
      type: FINANCE_PLAN_CHANGE,
      financePlanId,
      grossPrice,
      isPromotionPlan,
      userInput: {
        financePlanId,
        isPromotionPlan,
      },
      ...rest
  });

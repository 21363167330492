import React, { Component } from 'react';
import _isFunction from 'lodash/isFunction';
import { string, number, bool, shape, func } from 'prop-types';
import cx from 'classnames';
import { Collapse } from 'react-collapse';
import Analytics from 'analytics';
import { GIO_TAG_ENABLE } from 'gioStatistics';
import { i18n, getDefaultAnimationSpringConfig, isPriceAcceptance } from 'utils';

import AccountForm from '../../components/AccountForm';
import Alert from '../../components/Alert';
import PaymentTypes from '../../components/PaymentTypes';
import PickupLocation from '../../components/PickupLocation';
import InvoiceOptions from '../../components/InvoiceOptions';
import DeliveryDetailsForm from '../../components/AccountForm/DeliveryDetails';
import DepositAmountTable from './Sections/DepositAmountTable';
import PaymentQRcodePanel from '../../components/PaymentQRcodePanel';
import { FormFieldset, FormLegend } from '@tesla/design-system-react';
import {
  WEB_CONFIRM_CHANGE_CLICK,
  WEB_KEEP_CURRENT_DESIGN_CLICK,
  WEB_DESIGN_PAYMENT_SUBMIT,
  WEB_DESIGN_INVENTORY_PAYMENT_SUBMIT,
} from '../../common/gioStatistics';
import LegalDisclaimers from './Sections/LegalDisclaimers';
import { EDIT_DESIGN_DISCLAIMER_SECTION } from '../../common/dictionary';
import EsignFlowProcess from './Sections/EsignFlowProcess';
import TextLoader from '../../components/TextLoader';
import { updateESignPending } from '../../reducers';

export default class PaymentForms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  openForms = () => {
    const { isSessionSet, dispatchCheckSession } = this.props;
    // Update state, then wait a half-second for the re-render.
    if (!isSessionSet && _isFunction(dispatchCheckSession)) {
      dispatchCheckSession();
    }
    this.setState(
      {
        isOpen: true,
      },
      () => {
        setTimeout(() => {
          // scroll the user down to the new form
          const { isLayoutMobile, isLayoutTablet } = this.props;
          const reviewPanel = document.querySelector('.review-page--creditcard');
          const scrollElement =
            isLayoutMobile || isLayoutTablet
              ? document.querySelector('.config-main')
              : document.querySelector('.config-aside');
          const scrollTarget = reviewPanel.getBoundingClientRect().top + window.scrollY;
          if (scrollElement && scrollTarget) {
            scrollElement.scroll({ top: scrollTarget, behavior: 'smooth' });
          }
        }, 250);
      }
    );
    Analytics.fireTagEvent({
      event: Analytics.event,
      interaction: 'buy-now',
      'cfg-type': Analytics.cfgType,
    });
    const { market, setTimeOfClickPlaceOrderButton } = this.props;
    if (market === 'CN') {
      setTimeOfClickPlaceOrderButton(new Date().getTime());
    }
  };

  render() {
    const {
      userHasApplePay,
      isCollapsedFlow,
      rnLoaded,
      depositAmount,
      ccDetailsValid,
      submitTried,
      orderButtonDisabled,
      invitedOrderButtonDisabled,
      saveMarketingConfig,
      validateAndPlacePublicOrder,
      validateAndPlaceOrder,
      cancelUpdateOrder,
      modifyOrder,
      isSaveDesignEnabled,
      validateAndSaveDesign,
      saveDesignButtonDisabled,
      showPickupLocation,
      pickupLocationError,
      showInvoiceOption,
      isReservation,
      isReservedPreOrder,
      isBookedPreOrder,
      isFixedPickupLocation,
      isPaymentFormOpen,
      isDeliveryDetailsRequired,
      isDeliveryDetailsValid,
      modelName,
      paymentTabsType,
      enableCyberpunk,
      payXUIRef,
      children,
      isPaymentInteractionRequired,
      hasAlerts,
      isUnbuildable,
      isDeliverySelectionEnabled,
      market,
      isNativePaymentEnabled,
      legalConsentValid,
      isInventory,
      isPostOrderSwap,
      isPayxPaymentOptionSelected,
      showLegalConsentEditDesign,
      shouldShowEsignProcess,
    } = this.props;

    if (isPriceAcceptance()) {
      return null;
    }

    const { isOpen } = this.state;
    const isCN = market === 'CN';
    const isReservationToBookedWithoutPay =
      isCN && (isReservedPreOrder || isBookedPreOrder) && depositAmount === 0;
    const showConfirmConfiguration = isCN && (isReservedPreOrder || isBookedPreOrder);

    const orderPlacementLabel = isReservation
      ? 'Review.btn_label__place_reservation'
      : 'Review.btn_label__place_order';
    const confirmConfigurationLabel = showConfirmConfiguration
      ? i18n('Review.btn_label__confirm_configuration')
      : i18n('Review.btn_label__place_order');

    const submitOrderButtonGioEventName = isInventory
      ? WEB_DESIGN_INVENTORY_PAYMENT_SUBMIT
      : WEB_DESIGN_PAYMENT_SUBMIT;
    return (
      <div className="error-scroll-container">
        <If
          condition={
            !isPostOrderSwap &&
            !(isNativePaymentEnabled && isCN) &&
            ( modifyOrder || isReservationToBookedWithoutPay)
          }
        >
          <div className="alerts-container">
            <If condition={hasAlerts}>
              <Alert />
            </If>
          </div>
          <If condition={!modifyOrder}>
            <div
              className={cx('tds--vertical_padding action-block__buttons', {
                'payment-form--hidden': !isOpen,
                'tesla--payment-react': enableCyberpunk,
              })}
            >
              <If
                condition={
                  !isCollapsedFlow ||
                  (isCollapsedFlow && !showConfirmConfiguration && !isPaymentFormOpen && !isOpen)
                }
              >
                <button
                  type="button"
                  className={cx('tds-btn tds-btn--secondary tds-btn--width-full', {
                    'tds-btn--outline': enableCyberpunk,
                  })}
                  data-gio-track={GIO_TAG_ENABLE}
                  data-gio-eventname="web_design_payment_place"
                  onClick={isOpen || !isDeliveryDetailsValid ? null : this.openForms}
                >
                  {i18n('common.orderWithCard')}
                </button>
              </If>
            </div>
          </If>

          <Collapse
            isOpened={
              (rnLoaded || isOpen || (isPaymentFormOpen && !userHasApplePay)) &&
              !isPaymentInteractionRequired
            }
            springConfig={getDefaultAnimationSpringConfig()}
          >
            <If condition={!modifyOrder || !rnLoaded}>
              <div
                className={cx(
                  'review-page--container review-page--creditcard payment-type--container',
                  {
                    'tds-scrim--black': enableCyberpunk,
                  }
                )}
              >
                <section className="review-page--content">
                  <div className="review-page--body">
                    <div
                      className={cx('collapse-container', { 'tds-scrim--black': enableCyberpunk })}
                    >
                      <If
                        condition={
                          !isDeliverySelectionEnabled &&
                          !userHasApplePay &&
                          isDeliveryDetailsRequired
                        }
                      >
                        <form>
                          <DeliveryDetailsForm className="delivery-location--container__padded" />
                        </form>
                      </If>

                      <If condition={!rnLoaded}>
                        <h5 className="review-page--header">{i18n('Review.account_label')}</h5>
                        <legend className="tds-form-legend tds-form-item-label tds--is_invisible">
                          {i18n('Review.account_label')}
                        </legend>
                        <AccountForm title={i18n('Review.account_label')} />
                      </If>

                      <If condition={showPickupLocation}>
                        <div className="pickup-location--container">
                          <div className="field-container pickup_location">
                            <h5 className="review-page--header">
                              {i18n('Review.pickup_location_title')}
                            </h5>
                            <>
                              <PickupLocation
                                label={
                                  isFixedPickupLocation
                                    ? i18n('Review.BillingInfo.Form.pickup_fixed_location_label')
                                    : i18n('Review.BillingInfo.Form.pickup_select_province')
                                }
                                locationLabel={i18n(
                                  'Review.BillingInfo.Form.pickup_select_location'
                                )}
                                error={pickupLocationError}
                              />
                            </>
                          </div>
                        </div>
                      </If>
                      <If condition={showInvoiceOption}>
                        <div className="invoicing-options--container">
                          <div className="field-container invoice_options">
                            <div className="review-page--header">
                              {i18n('InvoiceType.InvoiceOptions')}
                            </div>
                            <InvoiceOptions />
                          </div>
                        </div>
                      </If>
                      {!isSaveDesignEnabled && !isReservationToBookedWithoutPay && (
                        <div className="payment-block tds--vertical_padding">
                          <h5 className="review-page--header">{i18n('Review.payment_label')}</h5>
                          <If condition={!ccDetailsValid && submitTried}>
                            <div className="review-page--credit-card-errors">
                              {i18n('Review.errors__ccFieldsInvalid')}
                            </div>
                          </If>
                          <form>
                            <FormFieldset>
                              <FormLegend
                                text={i18n('Review.payment_label')}
                                className="tds--is_visually_hidden"
                              />
                              <PaymentTypes mode={paymentTabsType} />
                            </FormFieldset>
                          </form>
                        </div>
                      )}
                    </div>
                  </div>
                </section>
              </div>
            </If>

            <section
              className={cx('review-page--pricing', { 'tds-o-text_color--20': !enableCyberpunk })}
            >
              <div
                className={cx('pricing-container', {
                  'tds-btn_group tds-btn_group--vertical': modifyOrder,
                })}
              >
                <If condition={!modifyOrder && depositAmount}>
                  <div className="items payment-due">
                    <DepositAmountTable />
                  </div>
                </If>
                <If condition={modifyOrder && (showLegalConsentEditDesign || market === 'CN')}>
                  <div className="items payment-due">
                    <div className="legal-disclaimer">
                      <LegalDisclaimers
                        type={EDIT_DESIGN_DISCLAIMER_SECTION}
                        withRefundPolicy={false}
                      />
                    </div>
                  </div>
                </If>
                <If condition={!modifyOrder}>{children}</If>
                <Choose>
                  <When condition={isSaveDesignEnabled}>
                    <button
                      disabled={saveDesignButtonDisabled}
                      className={cx('tds-btn tds-btn--primary tds-btn--width-full tds-btn--large', {
                        disabled: saveDesignButtonDisabled,
                      })}
                      onClick={validateAndSaveDesign}
                      data-id="btn-order"
                      data-subtype="btn-order--save-design"
                      type="button"
                    >
                      {i18n('saveDesign', 'common.ui')}
                    </button>
                  </When>
                  <When condition={!rnLoaded}>
                    <button
                      disabled={orderButtonDisabled}
                      className={cx('tds-btn tds-btn--primary tds-btn--width-full tds-btn--large', {
                        disabled: orderButtonDisabled,
                        'tds-scrim--black': enableCyberpunk,
                      })}
                      onClick={validateAndPlacePublicOrder}
                      data-id="btn-order"
                      data-subtype="btn-order--primary"
                      type="button"
                      data-gio-track={GIO_TAG_ENABLE}
                      data-gio-eventname={submitOrderButtonGioEventName}
                    >
                      {i18n(orderPlacementLabel)}
                    </button>
                  </When>
                  <When condition={!modifyOrder}>
                    <button
                      disabled={invitedOrderButtonDisabled}
                      className={cx('tds-btn tds-btn--primary tds-btn--width-full tds-btn--large', {
                        disabled: invitedOrderButtonDisabled,
                      })}
                      onClick={validateAndPlaceOrder}
                      data-id="btn-order"
                      data-subtype="btn-order--primary"
                      type="button"
                    >
                      {confirmConfigurationLabel}
                    </button>
                  </When>
                  <Otherwise>
                    <button
                      disabled={
                        (showLegalConsentEditDesign || isCN) && !legalConsentValid
                      }
                      className="tds-btn tds-btn--blue tds-btn--large tds-btn--width-full"
                      onClick={saveMarketingConfig}
                      data-id="btn-order"
                      data-subtype="btn-order--update-order"
                      type="button"
                      data-gio-track={GIO_TAG_ENABLE}
                      data-gio-eventname={WEB_CONFIRM_CHANGE_CLICK}
                    >
                      {(isCN && i18n('Review.apply_update_order')) ||
                        i18n('Review.update_order')}
                    </button>
                  </Otherwise>
                </Choose>

                <If condition={modifyOrder}>
                  <button
                    className="cancel-order-button tds-btn tds-btn--secondary tds-btn--width-full"
                    data-id="btn-order"
                    data-subtype="btn-order--cancel-update-order"
                    onClick={cancelUpdateOrder}
                    onKeyPress={cancelUpdateOrder}
                    tabIndex={0}
                    type="button"
                    data-gio-track={GIO_TAG_ENABLE && !isUnbuildable}
                    data-gio-eventname={WEB_KEEP_CURRENT_DESIGN_CLICK}
                  >
                    {i18n(isUnbuildable ? 'common.back' : 'Review.cancel_update_order', {
                      MODEL: modelName.replace('&nbsp;', ' '),
                    })}
                  </button>
                </If>
              </div>
            </section>
          </Collapse>
          <PaymentQRcodePanel />
        </If>
        {/* PayX provides the open / close flow */}
        <If condition={!modifyOrder && !isReservationToBookedWithoutPay}>
          <Choose>
            <When condition={isCN && !isNativePaymentEnabled && !isPostOrderSwap}>
              {/* Button to expand Account form */}
              <If condition={!modifyOrder}>
                <div
                  className={cx('tds--vertical_padding action-block__buttons', {
                    'payment-form--hidden': !isOpen,
                    'tesla--payment-react': enableCyberpunk,
                  })}
                >
                  <If
                    condition={
                      !isCollapsedFlow ||
                      (isCollapsedFlow &&
                        !showConfirmConfiguration &&
                        !isPaymentFormOpen &&
                        !isOpen)
                    }
                  >
                    <button
                      type="button"
                      className={cx('tds-btn tds-btn--secondary tds-btn--width-full', {
                        'tds-btn--outline': enableCyberpunk,
                      })}
                      data-gio-track={GIO_TAG_ENABLE}
                      data-gio-eventname="web_design_payment_place"
                      onClick={isOpen || !isDeliveryDetailsValid ? null : this.openForms}
                    >
                      {i18n('common.orderWithCard')}
                    </button>
                  </If>
                </div>
              </If>

              <Collapse
                isOpened={
                  (rnLoaded || isOpen || (isPaymentFormOpen && !userHasApplePay)) &&
                  !isPaymentInteractionRequired
                }
                springConfig={getDefaultAnimationSpringConfig()}
              >
                <If condition={!modifyOrder || !rnLoaded}>
                  <div
                    className={cx(
                      'review-page--container review-page--creditcard payment-type--container',
                      {
                        'tds-scrim--black': enableCyberpunk,
                      }
                    )}
                  >
                    <section className="review-page--content">
                      <div className="review-page--body">
                        <div
                          className={cx('collapse-container', {
                            'tds-scrim--black': enableCyberpunk,
                          })}
                        >
                          <If condition={!rnLoaded}>
                            <h5 className="review-page--header">{i18n('Review.account_label')}</h5>
                            <legend className="tds-form-legend tds-form-item-label tds--is_invisible">
                              {i18n('Review.account_label')}
                            </legend>
                            <AccountForm title={i18n('Review.account_label')} />
                          </If>

                          <If condition={showPickupLocation}>
                            <div className="pickup-location--container">
                              <div className="field-container pickup_location">
                                <h5 className="review-page--header">
                                  {i18n('Review.pickup_location_title')}
                                </h5>
                                <>
                                  <PickupLocation
                                    label={
                                      isFixedPickupLocation
                                        ? i18n(
                                            'Review.BillingInfo.Form.pickup_fixed_location_label'
                                          )
                                        : i18n('Review.BillingInfo.Form.pickup_select_province')
                                    }
                                    locationLabel={i18n(
                                      'Review.BillingInfo.Form.pickup_select_location'
                                    )}
                                    error={pickupLocationError}
                                  />
                                </>
                              </div>
                            </div>
                          </If>
                          {!isReservationToBookedWithoutPay && (
                            <div className="payment-block tds--vertical_padding">
                              <h5 className="review-page--header">
                                {i18n('Review.payment_label')}
                              </h5>
                            </div>
                          )}
                        </div>
                      </div>
                    </section>
                  </div>
                </If>
              </Collapse>

              <Collapse
                isOpened={rnLoaded || isOpen || (isPaymentFormOpen && !userHasApplePay)}
                springConfig={getDefaultAnimationSpringConfig()}
              >
                <If condition={!modifyOrder || !rnLoaded}>
                  <Collapse isOpened={!shouldShowEsignProcess}>
                    <PaymentTypes
                      mode={paymentTabsType}
                      orderButtonDisabled={orderButtonDisabled}
                      invitedOrderButtonDisabled={invitedOrderButtonDisabled}
                      validateAndPlacePublicOrder={validateAndPlacePublicOrder}
                      validateAndPlaceOrder={validateAndPlaceOrder}
                      payXUIRef={payXUIRef}
                      legalConsentValid={legalConsentValid}
                      market={market}
                      isPayxPaymentOptionSelected={isPayxPaymentOptionSelected}
                    />
                  </Collapse>
                  <If condition={shouldShowEsignProcess}>
                    <TextLoader data={i18n('esign.signContract')} className="tds-text--h5" />
                    <EsignFlowProcess />
                  </If>
                </If>
              </Collapse>
            </When>
            <Otherwise>
              <PaymentTypes
                mode={paymentTabsType}
                orderButtonDisabled={orderButtonDisabled}
                invitedOrderButtonDisabled={invitedOrderButtonDisabled}
                validateAndPlacePublicOrder={validateAndPlacePublicOrder}
                validateAndPlaceOrder={validateAndPlaceOrder}
                payXUIRef={payXUIRef}
                legalConsentValid={legalConsentValid}
              />
            </Otherwise>
          </Choose>
        </If>
      </div>
    );
  }
}

PaymentForms.propTypes = {
  userHasApplePay: bool.isRequired,
  isCollapsedFlow: bool.isRequired,
  isLayoutMobile: bool.isRequired,
  isLayoutTablet: bool.isRequired,
  rnLoaded: bool.isRequired,
  depositAmount: number.isRequired,
  ccDetailsValid: bool.isRequired,
  submitTried: bool,
  orderButtonDisabled: bool.isRequired,
  invitedOrderButtonDisabled: bool.isRequired,
  saveMarketingConfig: func.isRequired,
  validateAndPlacePublicOrder: func.isRequired,
  cancelUpdateOrder: func.isRequired,
  validateAndPlaceOrder: func.isRequired,
  modifyOrder: bool.isRequired,
  isFixedPickupLocation: bool,
  isPaymentFormOpen: bool,
  isSessionSet: bool,
  dispatchCheckSession: func,
  isDeliveryDetailsRequired: bool.isRequired,
  isDeliveryDetailsValid: bool.isRequired,
  isSaveDesignEnabled: bool,
  validateAndSaveDesign: func,
  saveDesignButtonDisabled: bool,
  showPickupLocation: bool,
  pickupLocationError: bool,
  showInvoiceOption: bool,
  isReservation: bool,
  rn: string,
  isReservedPreOrder: bool,
  isBookedPreOrder: bool,
  children: shape({}),
  modelName: string,
  paymentTabsType: string,
  isNativePaymentEnabled: bool,
  enableCyberpunk: bool,
  isPaymentInteractionRequired: bool,
  hasAlerts: bool,
  isUnbuildable: bool,
  payXUIRef: shape({}),
  market: string,
  legalConsentValid: bool,
  isInventory: bool,
  isPostOrderSwap: bool,
  isPayxPaymentOptionSelected: func,
  showLegalConsentEditDesign: bool,
  setTimeOfClickPlaceOrderButton: func,
  shouldShowEsignProcess: bool,
};

PaymentForms.defaultProps = {
  isPaymentFormOpen: true,
  isSessionSet: false,
  dispatchCheckSession: null,
  submitTried: false,
  isSaveDesignEnabled: false,
  validateAndSaveDesign() {},
  saveDesignButtonDisabled: false,
  showPickupLocation: false,
  pickupLocationError: false,
  showInvoiceOption: false,
  isReservation: false,
  children: {},
  isFixedPickupLocation: false,
  modelName: '',
  paymentTabsType: 'tabs',
  enableCyberpunk: false,
  isPaymentInteractionRequired: false,
  hasAlerts: false,
  isUnbuildable: false,
  market: '',
  legalConsentValid: true,
  isInventory: false,
  isPostOrderSwap: false,
  isPayxPaymentOptionSelected: () => {},
  showLegalConsentEditDesign: false,
  setTimeOfClickPlaceOrderButton: () => {},
  shouldShowEsignProcess: false,
};

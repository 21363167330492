/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React, { useEffect } from 'react';
import {
  FormInputOptionGroup,
  FormInputOption,
  FormLabel,
  FormItem,
} from '@tesla/design-system-react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import cx from 'classnames';

const MediaControls = ({
  option: optionProp,
  onAssetCaptionChange,
  group,
  hideSelection = false,
}) => {
  const option = optionProp || group || {};
  const { media_controls, code, name } = option || {};
  const label = `${code}:${name}`;
  if (!media_controls) {
    return null;
  }
  let isUserScroll = false;
  let autoScroll = false;

  const { options, field } = media_controls || {};
  const container = document.getElementsByClassName('feature-description');

  const scrollIntoView = (activeElement, index) => {
    if (!isUserScroll && container?.length && activeElement) {
      const containerTop = container?.[0]?.offsetTop;
      const activeTop = activeElement?.offsetTop;
      const value = (activeTop - containerTop) || 0;
      const parentScrollTop = container[0]?.scrollTop;
      const childOffsetHeight = container[0]?.offsetHeight;
      const view = (childOffsetHeight/2 + parentScrollTop) || 0;
      if (value > view) {
        autoScroll = true;
        container[0]?.scroll(0, view);
      } else if (autoScroll && index < 1 && parentScrollTop) {
        container[0]?.scroll(0, 0);
      }
    }
  };

  const setMediaCaption = caption => {
    if (caption && typeof onAssetCaptionChange === 'function') {
      onAssetCaptionChange(caption);
    } else {
      const elem = document.getElementsByClassName('video-caption');
      if (elem?.length) {
        elem[0].innerHTML = caption;
      }
    }
  };

  const onTimeUpdate = event => {
    const { currentTime } = event.target || {};
    const index = options?.findLastIndex(x => x?.currentTime <= currentTime);
    if (index !== -1) {
      const radio = document.getElementById(`${code}:Asset:${index}`);
      scrollIntoView(radio, index);
      if (radio && !radio?.checked) {
        radio.checked = true;
        const caption = radio?.dataset?.caption;
        setMediaCaption(caption);
      }
    }
  };

  const onWheel = () => {
    isUserScroll = true;
  };

  useEffect(() => {
    const media = document.getElementById(`video-id-${label}`);
    media?.addEventListener('timeupdate', onTimeUpdate, false);

    if (container?.length) {
      container[0]?.addEventListener('mousewheel', onWheel, true);
      container[0]?.addEventListener('click', onWheel, true);
    }

    return () => {
      media?.removeEventListener('timeupdate', onTimeUpdate, false);
      if (container?.length) {
        container[0]?.removeEventListener('mousewheel', onWheel, true);
        container[0]?.removeEventListener('click', onWheel, true);
      }
    };
  }, []);

  return (
    <FormItem className={cx({ 'tds--is_hidden': hideSelection })}>
      <FormLabel is="legend">{option?.[field] || ''}</FormLabel>
      <FormInputOptionGroup>
        {options.map((item, index) => {
          const { field, currentTime, duration } = item;
          return (
            <FormInputOption
              type="radio"
              key={`MediaControls:${code}:${index}`}
              id={`${code}:Asset:${index}`}
              data-caption={option?.[field]}
              name={`MediaControls:${code}`}
              label={
                <>
                  <span className="tds-flex-item tds-o-label-title">{option?.[field] || ''}</span>
                  <If condition={duration}>
                    <span className="tds-flex-item tds-flex--col_1of3 tds-o-text-align_end tds-o-label-descriptor">
                      {`${duration} sec`}
                    </span>
                  </If>
                </>
              }
              className="tds-o-option-label"
              onClick={() => {
                const media = document.getElementById(`video-id-${label}`);
                const radio = document.getElementById(`${code}:Asset:${index}`);
                if (media && radio) {
                  media.currentTime = currentTime;
                  radio.checked = true;
                  const caption = radio?.dataset?.caption;
                  setMediaCaption(caption);
                }
              }}
            />
          );
        })}
      </FormInputOptionGroup>
    </FormItem>
  );
};

MediaControls.propTypes = {
  option: shape({
    name: string,
    code: string,
    media_controls: shape({
      options: arrayOf(shape({})),
      field: string,
    }),
  }),
  group: shape({
    name: string,
    code: string,
    media_controls: shape({
      options: arrayOf(shape({})),
      field: string,
    }),
  }),
  hideSelection: bool,
  onAssetCaptionChange: func,
};

MediaControls.defaultProps = {
  option: null,
  group: null,
  hideSelection: false,
};


export default MediaControls;

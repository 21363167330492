import React from 'react';
import { connect } from 'react-redux';
import { number, string, shape, bool, arrayOf } from 'prop-types';
import { formatCurrency } from '@tesla/coin-common-components';
import { StatusMessage } from '@tesla/design-system-react';
import { i18n, htmlToReact } from 'utils';
import { ORDER_FEE_TYPE } from 'dictionary';
import {
  getAccessoriesTaxAmount,
  getSelectedAccessoriesList,
  getDepositAmount,
  isAccessoriesEligibleForTax,
  getSelectedPartNumbers,
} from 'selectors';
import { isAccessoriesSelected, isTaxInclusive } from '../../../common/selectors';
import cx from 'classnames';

const AccessoriesTotal = ({
  accessories,
  orderPayment,
  hasAccessories,
  taxAmount,
  orderPaymentType,
  isTaxInclusiveMarket,
  showNonRefund,
  showNonRefundableDisclaimer,
  isTaxApplicableForAccessories,
  enableCyberpunk,
  currency,
  showAccessoriesBlock,
  lexiconOrderPayment,
  isCoinReloaded,
}) => {

  if (!showAccessoriesBlock) {
    return null;
  }

  if (isCoinReloaded) {
    return (
      <div className="accessories-list-item no-border">
        <If condition={hasAccessories}>
          <table className="tds-table">
            <tbody className="tds-table-body">
              {accessories.map(item => {
                const { name, purchasePrice } = item;
                return (
                  <tr className="tds-table-row" key={`accessory-${name}`}>
                    <td
                      scope="row"
                      className={cx({ 'tds-o-padding_bottom-4': !enableCyberpunk && !isCoinReloaded }, 'tds-o-no_padding_top tds-o-padding_bottom-8')}
                    >
                      {name}
                    </td>
                    <td
                      className={cx({ 'tds-o-padding_bottom-4': !enableCyberpunk && !isCoinReloaded }, 'tds-o-no_padding_top tds-o-padding_bottom-8 tds-text--end')}
                    >
                      {formatCurrency(purchasePrice, { currency })}
                    </td>
                  </tr>
                );
              })}
              <If condition={isTaxApplicableForAccessories}>
                <tr className="tds-table-row">
                  <td scope="row" className="tds-o-no_padding_top tds-o-padding_bottom-8 tds-text--regular">
                    <span
                      className={cx('tds-text--body', {
                        'tds-o-padding_bottom-12': enableCyberpunk,
                      })}
                    >
                      {i18n(`common.${isTaxInclusiveMarket ? 'includesVat' : 'salesTax'}`)}
                    </span>
                  </td>
                  <td className="tds-o-no_padding_top tds-o-padding_bottom-8 tds-text--regular tds-text--end">
                    <span className="tds-text--body">
                      {taxAmount !== undefined
                        ? formatCurrency(taxAmount, { precision: 2, currency })
                        : isTaxInclusiveMarket
                        ? ''
                        : i18n('common.pending')}
                    </span>
                  </td>
                </tr>
              </If>
            </tbody>
          </table>
        </If>
        <table className="tds-table">
          <tbody className="tds-table-body">
            <Choose>
              <When condition={enableCyberpunk && lexiconOrderPayment?.amount}>
                <tr className="tds-table-row">
                  <th scope="row">{lexiconOrderPayment?.title}</th>
                  <td className="tds-text--end">{lexiconOrderPayment?.formattedAmount}</td>
                </tr>
                <span className="tds-text--caption">
                  {htmlToReact(lexiconOrderPayment?.disclaimer)}
                </span>
              </When>
              <Otherwise>
                <tr className="tds-table-row">
                  <td
                    scope="row"
                    className="tds-o-no_padding_top tds-o-padding_bottom-24"
                  >
                    {lexiconOrderPayment?.disclaimer || orderPaymentType}
                  </td>
                  <td className="tds-text--end tds-o-no_padding_top tds-o-padding_bottom-24">
                    {formatCurrency(orderPayment, { currency })}
                  </td>
                </tr>
              </Otherwise>
            </Choose>
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div className="accessories-list-items">
      <If condition={hasAccessories}>
        <table className="tds-table">
          <tbody className="tds-table-body">
            {accessories.map(item => {
              const { name, purchasePrice } = item;
              return (
                <tr className="tds-table-row" key={`accessory-${name}`}>
                  <th
                    scope="row"
                    className={cx({ 'tds-o-padding_bottom-4': !enableCyberpunk }, 'tds-text--medium')}
                  >
                    {name}
                  </th>
                  <td
                    className={cx({ 'tds-o-padding_bottom-4': !enableCyberpunk }, 'tds-text--medium tds-text--end')}
                  >
                    {formatCurrency(purchasePrice, { currency })}
                  </td>
                </tr>
              );
            })}
            <Choose>
              <When condition={isTaxApplicableForAccessories}>
                <tr className="tds-table-row">
                  <th scope="row" className="tds--no_padding tds-text--regular">
                    <span
                      className={cx('tds-text--caption', {
                        'tds-o-padding_bottom-12': enableCyberpunk,
                      })}
                    >
                      {i18n(`common.${isTaxInclusiveMarket ? 'includesVat' : 'salesTax'}`)}
                    </span>
                  </th>
                  <td className="tds--no_padding tds-text--regular tds-text--end">
                    <span className="tds-text--caption">
                      {taxAmount !== undefined
                        ? formatCurrency(taxAmount, { precision: 2, currency })
                        : isTaxInclusiveMarket
                        ? ''
                        : i18n('common.pending')}
                    </span>
                  </td>
                </tr>
              </When>
              <Otherwise>
                <span className="tds-text--caption">{i18n('common.nonRefundable')}</span>
              </Otherwise>
            </Choose>
          </tbody>
        </table>
      </If>
      <table className="tds-table">
        <tbody className="tds-table-body">
          <Choose>
            <When condition={enableCyberpunk && lexiconOrderPayment?.amount}>
              <tr className="tds-table-row">
                <th scope="row">{lexiconOrderPayment?.title}</th>
                <td className="tds-text--end">{lexiconOrderPayment?.formattedAmount}</td>
              </tr>
              <span className="tds-text--caption">
                {htmlToReact(lexiconOrderPayment?.disclaimer)}
              </span>
            </When>
            <Otherwise>
              <tr className="tds-table-row">
                <th
                  scope="row"
                  className="tds-text--medium tds-o-padding_bottom-4 tds--padding_top-16"
                >
                  {orderPaymentType}
                </th>
                <td className="tds-text--end tds-text--medium tds-o-padding_bottom-4 tds--padding_top-16">
                  {formatCurrency(orderPayment, { currency })}
                </td>
              </tr>
              <tr className="tds-table-row order-disclaimer">
                <td colSpan="2">
                  <p className="tds-text--caption">
                    {showNonRefund ? i18n('common.nonRefundable') : i18n('common.refundable')}
                    {showNonRefund && showNonRefundableDisclaimer ? '*' : ''}
                  </p>
                  <If condition={showNonRefund && showNonRefundableDisclaimer}>
                    <p className="tds-text--caption">
                      {i18n('SummaryPanel.disclaimers.orderNotRefundShortDisclaimer')}
                    </p>
                  </If>
                </td>
              </tr>
            </Otherwise>
          </Choose>
        </tbody>
      </table>
    </div>
  );
};

AccessoriesTotal.propTypes = {
  orderPayment: number,
  accessories: arrayOf(
    shape({
      purchasePrice: number,
      name: string,
    })
  ),
  taxAmount: number,
  hasAccessories: bool,
  showNonRefund: bool,
  showNonRefundableDisclaimer: bool,
  orderPaymentType: string,
  isTaxInclusiveMarket: bool,
  enableCyberpunk: bool,
  currency: string.isRequired,
  lexiconOrderPayment: shape({}),
  showAccessoriesBlock: bool,
  isCoinReloaded: bool,
};

AccessoriesTotal.defaultProps = {
  accessories: {},
  orderPayment: 0,
  taxAmount: undefined,
  hasAccessories: false,
  showNonRefund: false,
  showNonRefundableDisclaimer: false,
  orderPaymentType: '',
  isTaxInclusiveMarket: false,
  enableCyberpunk: false,
  lexiconOrderPayment: {},
  showAccessoriesBlock: false,
  isCoinReloaded: false,
};

function mapStateToProps(state, ownProps) {
  const { ReviewDetails, Accessories, App: { enableCyberpunk, isCoinReloaded } = {}, Payment } = state;
  const { showError } = ownProps;
  const selectedAccessories = getSelectedAccessoriesList(state);
  const partNumbers = getSelectedPartNumbers(state);
  const accessories = selectedAccessories.map(accessory => {
    const { purchasePrice: { amount = 0 } = {} } =
      accessory?.options?.find(x => partNumbers?.includes(x?.partNumber)) || {};
    return {
      name: accessory.name,
      purchasePrice: amount,
    };
  });
  const { orderPayment = 0, paymentSourceSubType = '' } = getDepositAmount(state, true) || {};

  return {
    hasAccessories: isAccessoriesSelected(state),
    accessories,
    taxAmount: getAccessoriesTaxAmount(state),
    showNonRefund: ReviewDetails?.nonRefundPolicy?.source || false,
    showNonRefundableDisclaimer: ReviewDetails?.nonRefundPolicyDisclaimer || false,
    orderPayment,
    orderPaymentType: paymentSourceSubType?.includes(ORDER_FEE_TYPE)
      ? (isCoinReloaded ? i18n('SummaryPanel.order_fee_non_refundable') : i18n('SummaryPanel.order_fee'))
      : i18n('SummaryPanel.order_deposit'),
    isTaxInclusiveMarket: isTaxInclusive(state),
    isTaxApplicableForAccessories: isAccessoriesEligibleForTax(state),
    enableCyberpunk,
    currency: state?.Payment?.CurrencyCode,
    lexiconOrderPayment: Payment?.orderPayment,
    isCoinReloaded,
  };
}

export default connect(mapStateToProps)(AccessoriesTotal);

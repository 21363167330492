import { get, has, find, difference, isArray, isNumber, isEmpty, reduce, isString, isObject, filter } from 'lodash';

import { formatCurrency, formatNumber, i18n } from '@tesla/coin-common-components';
import DOMPurify from 'dompurify';
import React from 'react';
import HtmlToReact from 'html-to-react';
import { getSuperRegion, getStates } from '@tesla/intl-display-names';
import {
  FILTER_KEY_ADL_OPTS,
  FILTER_KEY_PRICE,
  SUPERCHARGING_FREE,
  SUPERCHARGING_PAYG,
  FALLBACK_IMAGES_LHD,
  FALLBACK_IMAGES_RHD,
  CONDITION_NEW,
  CONTEXT_INT,
  MODEL_FULL_NAME,
  CONDITION_USED,
  DISPLAY,
  TESTDRIVE,
  TEST_DRIVE,
  MODEL_MS,
  MODEL_MX,
  MODEL_M3,
  MODEL_MY,
  ONSITE,
  COMPOSITOR_STUD_3QTR,
  BUTTON_END,
  BUTTON_START,
  REGION_EU,
  REGION_ME,
  REGION_AP,
  LOCATION_TAB,
} from '../dictionary';

const memoCache = {};

// Misc Util Functions
// ------------------------------------------------------------------------

export const getCountryAddressComponent = location =>
  find(get(location, 'address_components', []), item => item.types.includes('country'));

export const getPostalCodeComponent = location =>
  find(get(location, 'address_components', []), item => item.types.includes('postal_code'));

export const isValidCode = input => !/[\\/&;><]/.test(input);

export const sanitizeInputCode = input => {
  if (isValidCode(input)) {
    return input;
  }

  return '';
};

export const normalizeOption = (option, template) => {
  // Cast to String and set to Upper case
  if (isArray(template)) {
    return isArray(option) ? option : [String(option).toUpperCase()];
  }

  return option;
};

export const showApproximateResults = results => {
  const hasExact = results && results.exact && !!results.exact.length;
  const hasApproximate = results && results.approximate && !!results.approximate.length;
  const hasApproxOutside =
    results && results.approximateOutside && !!results.approximateOutside.length;
  return !hasExact && (hasApproximate || hasApproxOutside);
};

export const assessDifference = (option, template, category) => {
  let response = null;
  if (isArray(template)) {
    const diff = difference(template, option);
    if (diff.length === template.length && category !== FILTER_KEY_ADL_OPTS) {
      response = template;
    } else if (category === FILTER_KEY_ADL_OPTS && diff.length) {
      response = diff;
    }
  } else if (isNumber(template)) {
    if (option > template) {
      response = template;
    }
  }
  return response;
};

/**
 * Returns model name based on a model code passed
 * @param String  model       Model code (ms, mx, m3)
 * @param String  defaultName`  Default model name to return if model is empty
 * @return String   modelName     Mapped model name
 * */
export const getModelName = (model, defaultName = '') => {
  let modelName = defaultName;
  if (!model || !isString(model)) {
    return modelName;
  }
  switch (model.toLowerCase()) {
    case 'ms':
      modelName = 'Model S';
      break;
    case 'mx':
      modelName = 'Model X';
      break;
    case 'm3':
      modelName = 'Model 3';
      break;
    case 'my':
      modelName = 'Model Y';
      break;
    default:
      modelName = model;
  }
  return modelName;
};

export const formatOptionName = ({
  category,
  labels,
  optionName = null,
  optionCode = null,
  model = '',
}) => {
  let option_name = optionName;

  if (optionName === null) {
    option_name =
      get(category, 'accept', 'string') === 'number'
        ? formatNumber(category.value)
        : category.value;

    switch (category.code) {
      case FILTER_KEY_PRICE:
        option_name = formatCurrency(category.value);
        break;
    }
  }

  const optionPath = optionCode ? `optionLabels.label__${optionCode}` : null;
  const categoryPath = `labels.label__${category.code}`;

  // Check for option overwrite first
  if (optionPath && has(labels, `${optionPath}`)) {
    option_name = i18n(`Filters.${optionPath}`, {
      OPTION: option_name,
      MODEL: getModelName(model),
    });
  }
  // Check for category option overwrite (selected option values)
  if (has(labels, `${categoryPath}`)) {
    option_name = i18n(`Filters.${categoryPath}`, {
      OPTION: option_name,
      MODEL: getModelName(model),
    });
  }

  return option_name;
};

export const getViewport = () => {
  let viewportWidth;
  let viewportHeight;

  if (typeof window.innerWidth !== 'undefined') {
    // standards compliant browsers
    viewportWidth = window.innerWidth;
    viewportHeight = window.innerHeight;
  } else if (
    typeof document.documentElement !== 'undefined' &&
    typeof document.documentElement.clientWidth !== 'undefined' &&
    document.documentElement.clientWidth !== 0
  ) {
    // IE6 in standards compliant mode
    viewportWidth = document.documentElement.clientWidth;
    viewportHeight = document.documentElement.clientHeight;
  } else {
    // older versions of IE
    viewportWidth = document.getElementsByTagName('body')[0].clientWidth;
    viewportHeight = document.getElementsByTagName('body')[0].clientHeight;
  }

  return {
    vw: viewportWidth,
    vh: viewportHeight,
  };
};

/**
 * Check if a vehicle has either a Free Supercharging (SC01, SC05, $SC01, $SC05) option code
 * Or a Pay-As-You-Go Supercharging (SC04, $SC04)
 * @param  String    options_str     Options string
 * @param  String    type           Type of supercharging (free or pay as you go)
 * @return Boolean   true|false      Returns whether a vehicle has a free supercharging or not
 * */
const hasSuperchargingType = (options_str, type) => {
  const types = {
    [SUPERCHARGING_FREE]: 'SC0([1]|[5])',
    [SUPERCHARGING_PAYG]: 'SC0([4])',
  };

  const is_string = typeof options_str === 'string';
  const matches = new RegExp(`${types[type]}`, 'i').test(options_str);

  if (is_string && matches) {
    return true;
  }
  return false;
};

export const hasFreeSupercharging = options_str => {
  return hasSuperchargingType(options_str, SUPERCHARGING_FREE);
};

export const hasPaidSupercharging = options_str => {
  return hasSuperchargingType(options_str, SUPERCHARGING_PAYG);
};

/**
 * getFallbackImage
 * - Check for the Right-Hand Drive option & return a tiny fallback image for the expected compositor render
 */
export const getFallbackImage = (OptionCodeList, Model, view) => {
  const isRHD = OptionCodeList?.match(/DRRH/g);
  const fallbackImages = isRHD ? FALLBACK_IMAGES_RHD : FALLBACK_IMAGES_LHD;
  return get(fallbackImages, `${Model}.${view}`, null);
};

/**
 * Map Availability States
 * @param  Array     models           Models array
 * @param  Array     conditions       Conditions array
 * @return Array    availabilityStates  Return availability states
 * */
export const mapAvailabilityStates = (models, conditions) =>
  reduce(
    models,
    (result, model) => result.concat(conditions.map(condition => `${model}_${condition}`)),
    []
  );

/**
 * Returns bool of HOV enabled regions
 * @param String  market      US, GB, CN
 * @param String  region      State or Region
 * @param String  condition   new/used
 * @return Boolean  true|false
 * */
export const sendHOVParam = (market, region, condition) => {
  const HOVEnabledMarkets = ['US'];
  const HOVEnabledRegions = ['CA'];
  const enabledConditions = ['used'];
  return (
    HOVEnabledMarkets.includes(market) &&
    HOVEnabledRegions.includes(region) &&
    enabledConditions.includes(condition)
  );
};

/**
 * formatOdometer
 * Returns a formatted version of the odometer string
 */
export const formatOdometer = ({
  condition = 'new',
  odometer,
  odometerType = 'Km',
  useShortLabel = false,
  showOdometerNewVehicles = true,
  isDemo = false,
  odometerThreshold,
  Year,
  showOdometerModelYear,
  ChildMapping,
}) => {

  const currentYear = new Date().getFullYear();
  const currentModelYear = Year >= currentYear;
  const underThreshold = odometer === null || odometer < odometerThreshold;
  const showUnderThreshold = underThreshold && showOdometerNewVehicles;

  if (showOdometerModelYear) {
    if (isDemo) {
      return i18n(`Inventory.${currentModelYear ? 'odometer_current_year_demo' : 'odometer_previous_year_demo'}` , {
        YEAR: Year,
        VALUE: formatNumber(odometer), 
      })
    } else if (!currentModelYear) {
      return underThreshold ? Year : i18n('Inventory.odometer_previous_year', {
        YEAR: Year,
        VALUE: formatNumber(showUnderThreshold ? odometerThreshold : odometer),
      })
    }
  }

  if (condition === CONDITION_NEW && underThreshold && !isDemo && !showOdometerNewVehicles) {
    return null;
  }

  return i18n(
    `Inventory.odometer${
      showUnderThreshold ? '_under_threshold' : ''
    }_${odometerType.toLowerCase()}${useShortLabel ? '_short' : ''}`,
    {
      VALUE: formatNumber(showUnderThreshold ? odometerThreshold : odometer),
    }
  );
};

export const isVehicleYearEnabled = ({ condition, context, market }) => {
  const isNew = condition === CONDITION_NEW;
  const isInternal = context === CONTEXT_INT;
  const isEnabledPublicMarket = ['AU', 'HK', 'IL', 'JP', 'KR', 'MO', 'NZ', 'SG'].includes(
    market
  );
  return !(isNew && (isInternal || !isEnabledPublicMarket));
};

const pickupOnlyCheck = (
  {
    countryCode: incomingCountry = [],
    modelCode: incomingModel = [],
    trimCode: incomingTrim = [],
    titleStatus: incomingTitle = [],
  },
  car
) => {
  const result = {
    countryMatch: null,
    modelMatch: null,
    trimMatch: null,
    titleMatch: null,
  };

  let vehicleWithDisplayProp = {};

  // check if array element exists
  if (incomingCountry.length > 0) result.countryMatch = !!incomingCountry.includes(car.countryCode);
  if (incomingModel.length > 0) result.modelMatch = !!incomingModel.includes(car.modelCode);
  if (incomingTrim.length > 0) result.trimMatch = !!incomingTrim.includes(car.trimCode);
  if (incomingTitle.length > 0) result.titleMatch = !!incomingTitle.includes(car.titleStatus);

  // if there is false anywhere, don't display
  if (Object.values(result).indexOf(false) > -1) {
    vehicleWithDisplayProp = { ...car, display: false };
  } else {
    vehicleWithDisplayProp = { ...car, display: true };
  }

  return vehicleWithDisplayProp;
};

/**
 * Get memoized results of a func
 *
 * @param function  fn
 * @param mixed     params
 *
 * @return mixed Returns results from a func or cache
 */
export const memoize = (fn, params = null) => {
  if (typeof fn !== 'function') {
    return fn;
  }
  return (...args) => {
    const key = params || args;
    const n = `${fn.name}:${JSON.stringify(key)}`;
    if (has(memoCache, n)) {
      return memoCache[n];
    }
    const result = fn(...args);
    memoCache[n] = result;
    return result;
  };
};

export const isPublicPickupOnly = (
  pickupOnlyData = [],
  { vehicle: { TitleStatus, TrimCode, Model } }
) => {
  const normalizedModel = (Model || '').toLowerCase();
  const normalizedTitleStatus = (TitleStatus || '').toLowerCase();
  let isPickupOnly = false;
  for (let el of pickupOnlyData) {
    const { modelCode = [], titleStatus = [], trimCode = [] } = el;
    if (
      (!modelCode.length || modelCode.includes(normalizedModel)) &&
      (!titleStatus.length || titleStatus.includes(normalizedTitleStatus)) &&
      (!trimCode.length || trimCode.includes(TrimCode))
    ) {
      isPickupOnly = true;
      break;
    }
  }
  return isPickupOnly;
};

export const getAtLocationDisclaimer = ({ InTransit, IsFactoryGated }) => {
  let label = !InTransit && IsFactoryGated ? 'available_at_location' : 'coming_soon_to_location';
  return label ? `Inventory.${label}` : '';
};

export const getDeliveryStatus = ({ vehicle, useCountryHasVehicleAtLocation }) => {
  const {
    CountryHasVehicleAtLocation,
    CPORefurbishmentStatus,
    FactoryGatedDate,
    InTransit,
    IsAtLocation = false,
    IsFactoryGated,
    TitleStatus,
  } = vehicle;

  const isUsed = TitleStatus?.toLowerCase() === CONDITION_USED;
  const atLocationValue =
    useCountryHasVehicleAtLocation && CountryHasVehicleAtLocation !== undefined
      ? CountryHasVehicleAtLocation
      : IsAtLocation || !InTransit;

  let deliveryStatusCopy = 'Common.comingSoon';

  if (isUsed) {
    deliveryStatusCopy =
      atLocationValue && CPORefurbishmentStatus?.toLowerCase() === 'complete'
        ? 'Delivery.estimated__COMPLETE'
        : 'Common.comingSoon';
  } else {
    if (FactoryGatedDate || IsFactoryGated) {
      deliveryStatusCopy = atLocationValue
        ? 'Common.availablePickUp'
        : 'Common.intransitArriveSoon';
    }
  }
  return i18n(deliveryStatusCopy);
};

// filter
export const isPickupOnlyEnabledCheck = (
  incomingData = [],
  { country, vehicle: { TitleStatus, TrimCode, Model } }
) => {
  // incomingData should be an array of objects
  if (!Array.isArray(incomingData) || incomingData.length === 0) return false;
  let vehicleToShow = {};

  const newVehicle = {
    countryCode: country?.toLowerCase(),
    titleStatus: TitleStatus?.toLowerCase(),
    trimCode: TrimCode,
    modelCode: Model?.toLowerCase(),
  };

  incomingData.forEach(data => {
    let newData = { ...data };

    for (const [key, value] of Object.entries(data)) {
      let newObject = {};
      if (key !== 'trimCode' && value.length > 0) {
        const newValues = [];
        if (!Array.isArray(value)) return;
        value.forEach(text => {
          // check if country filter exists
          // if exists - match with vehicles and just check the matched ones
          if (key === 'countryCode') {
            if (value.length > 0) {
              newObject = {
                ...data,
                [key]: value.filter(cc => cc === country && cc),
              };
            }
          }

          // normalize all strings and create a new instance of incomingData
          newValues.push(text.toLowerCase());

          newObject = {
            ...newObject,
            [key]: newValues,
          };
        });
        newData = {
          ...newData,
          ...newObject,
        };
      }
    }

    if (pickupOnlyCheck(newData, newVehicle).display !== false) {
      vehicleToShow = { ...vehicleToShow, ...pickupOnlyCheck(newData, newVehicle) };
    }
  });

  return vehicleToShow;
};

export const getDistanceBetweenPoints = ({ lat1, lon1, lat2, lon2, unit }) => {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  }
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  if (unit === 'K') {
    dist *= 1.609344;
  }
  if (unit === 'N') {
    dist *= 0.8684;
  }
  return dist;
};

/**
 * getDamageDisclosure
 * Return whether a vehicle has damage disclosure.
 */
export const getDamageDisclosure = (DamageDisclosure, TitleStatus) => {
  return TitleStatus?.toLowerCase() === CONDITION_NEW && DamageDisclosure;
};

/**
 * Accepts html string as input and returns a React component
 * (allows us to avoid most needs for dangerouslySetInnerHTML)
 *
 * @param {String} strHtml - HTML string to convert into React components
 * @param {Array} processingInstructions - see README
 * @return {React.Component}
 */
export const htmlToReact = (strHtml, processingInstructions) => {
  function __isValidNode() {
    return true;
  }

  const htmlToReactParser = new HtmlToReact.Parser(React);

  if (processingInstructions) {
    //const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);
    return htmlToReactParser.parseWithInstructions(strHtml, __isValidNode, processingInstructions);
  }

  // if strHtml does not begin with a tag, then wrap in span tag
  if (typeof strHtml === 'number' || (typeof strHtml === 'string' && strHtml?.trim()?.substring(0, 1) !== '<')) {
    strHtml = `<span>${strHtml}</span>`;
  }

  let cleanString = '';
  if (strHtml) {
      cleanString = DOMPurify.sanitize(strHtml, { ADD_ATTR: ['target'] });
  } 

  return htmlToReactParser.parse(cleanString);
};

/**
 * Get a list of excluded financial products based on defined criteria.
 *
 * @param {Object} vehicle                    Vehicle object.
 * @param {Object} excludedFinancialProducts  Defined conditions and results to exclude products.
 *
 * @return {Array} Financial products to be excluded.
 */
export const getExcludedFinancialProducts = (vehicle, excludedFinancialProducts) => {
  const { TitleStatus = '' } = vehicle;
  const result = get(excludedFinancialProducts, 'result', []);
  const params = get(excludedFinancialProducts, 'params', {});
  if (isEmpty(params)) {
    return result;
  }
  if (get(params, 'TitleStatus', '') === TitleStatus?.toLowerCase()) {
    return result;
  }
  return [];
};

/**
 * Get damage disclosure file IDs
 *
 * @param {Array}  fileIds      Vehicle object.
 * @param {String} TitleStatus  Title status.
 *
 * @return {Array} File IDs.
 */
export const getDamageDisclosureFilesId = (fileIds, TitleStatus) => {
  return TitleStatus?.toLowerCase() === CONDITION_NEW && fileIds && fileIds.length ? fileIds : [];
};

export const getReferralFromCookie = () => {
  const oldCookie = document.cookie.split(';');
  let data = null;

  for (let i = 0; i < oldCookie.length; i++) {
    const cookie = oldCookie[i].trim();
    if (cookie.indexOf('tesla_referral_code') !== -1) {
      // eslint-disable-next-line prefer-destructuring
      data = cookie.split('=')[1];
      break;
    }
  }
  return data;
};

/**
 * Check if relevance ordering is enabled.
 *
 * @param {String} market     Country code.
 * @param {String} context    App context.
 *
 * @return {Boolean} Relevance ordering status.
 */
export const isRelevanceEnabled = context => CONTEXT_INT !== context;

/**
 * Get the default Arrange value based on market
 *
 * @param {String} market
 * @param {String} model
 * @param {String} condition
 * @return {null|Object}
 */
export const getDefaultArrange = (market, model, condition) => {
  if (getSuperRegion(market).code === REGION_EU && model === MODEL_M3 && condition === CONDITION_NEW) {
    return { order: 'asc', value: 'plh' };
  }

  return null;
};

/**
 * Get formatted date for search query.
 *
 * @param {String} Date
 * @return {String} Formatted date.
 */
export const getFormattedDate = date => {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) {
    month = `0${month}`;
  }
  if (day.length < 2) {
    day = `0${day}`;
  }
  return [year, month, day].join('-');
};

/**
 * Get vessel arrival date
 *
 * @param {String} actualDate   Actual vessel arrival date
 * @param {String} expectedDate Expected vessel arrival date
 * @param {String} titleStatus  Vehicle title status
 * @param {String} locale       Locale (en_US etc.)
 *
 * @return {String} Arrival date normalized.
 */
export const getVesselArrivalDate = (actualDate, expectedDate, titleStatus, locale = 'en-US') => {
  if (titleStatus?.toLowerCase() !== CONDITION_NEW) {
    return '';
  }
  const date = actualDate || expectedDate || '';
  if (!date) {
    return '';
  }
  const normalizedLocale = locale.replace('_', '-');
  const formattedDate = new Date(date).toLocaleString(normalizedLocale, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
  const label = i18n(`Filters.vessel_arrival_date_${actualDate ? 'actual' : 'expected'}`);
  return `${label}: ${formattedDate}`;
};

/**
 * Get model code by model full name.
 *
 * @param {String} modelName
 * @return {String} modelCode.
 */
export const mapFullModelToShort = modelName => {
  const model = Object.keys(MODEL_FULL_NAME).find(key => MODEL_FULL_NAME[key] === modelName);
  return typeof model === 'undefined' ? modelName : model;
};

/**
 *
 * @param {Array} vehicleList  Array of vehicle objects
 * @param {String} vin         Vehicle VIN Number
 * @returns {null|Object} vehicle
 */
export const findVehicle = (vehicleList, vin) => {
  if (!vin) return null;

  // no filters applied
  if (Array.isArray(vehicleList)) {
    return vehicleList.filter(
      vehicle => vehicle.VIN === vin || get(vehicle, 'PickupLocations[0].Public_Hash') === vin
    )[0];
  } else {
    // filter applied
    let result;
    for (let [, value] of Object.entries(vehicleList)) {
      if (value.length > 0) result = value;
    }
    return result.filter(
      vehicle => vehicle.VIN === vin || get(vehicle, 'PickupLocations[0].Public_Hash') === vin
    )[0];
  }
};

/**
 * return list of fleet sales region code
 */
export const getFleetSalesRegionCode = (market, region) => {
  const states = getStates(market);

  // some markets do not have states
  const { code } = states?.find(x => x.label === region) || {};
  const regionCode = code || region.split('-')[0];
  return regionCode && isString(regionCode) ? regionCode.trim() : regionCode;
};

/**
 * Check if it is a vin search or not
 *
 * @param {Object} state Redux state
 *
 * @return {Boolean} IsVinSearch
 */
export function isVINSearch(state) {
  const { InventoryMatch } = state;
  const { query: { options } = {} } = InventoryMatch;
  return !!options.VIN;
}

/**
 * Get all fleet sales region codes
 *
 * @param {Object} vehicle Vehicle
 *
 * @return {String} region codes
 */
export function getAllFleetSalesRegionCodes(vehicle) {
  const { FleetSalesRegions = [], CountryCode: market } = vehicle;
  const regions = FleetSalesRegions.reduce((r, region) => {
    const res = getFleetSalesRegionCode(market, region);
    if (res && !r.includes(res) && res !== market) r.push(res);
    return r;
  }, []);
  return regions.join(', ');
}

export const formatTrimName = trimName => {
  return typeof trimName === 'string' ? trimName.replace(/\xA0/g, ' ') : '';
};

/**
 * Get Registration & Delivery Disclaimer for CA
 *
 * @param {object } vehicle
 *
 * @returns string
 */
export const getDeliveryAndRegistrationDisclaimer = ({ state, vehicle }) => {
  const { showDeliveryAndRegistrationDisclaimer } = state.App;

  if (showDeliveryAndRegistrationDisclaimer && vehicle) {
    const { StateProvince } = vehicle;

    if (StateProvince) {
      return i18n('Delivery.delivery_registration_disclaimer', {
        StateProvince,
      });
    }
    return null;
  }
  return null;
};

/**
 * Check if it is a on site vehicle
 *
 * @param {object } vehicle
 *
 * @return {Boolean} isOnSiteVehicle
 */
export function isOnSiteVehicle(vehicle) {
  const { VehicleSubType } = vehicle || {};
  return VehicleSubType?.includes(ONSITE);
}

/**
 * Check if show inspection doc for used vehicle
 * @param {Boolean} showInspectionDocLink
 * @param {String} inspectionDocumentGuid
 * @param {String} titleStatus
 *
 * @returns {Boolean} isShowInspectionDoc
 */
export function isShowInspectionDoc(showInspectionDocLink, inspectionDocumentGuid, titleStatus) {
  return (
    showInspectionDocLink && inspectionDocumentGuid && titleStatus?.toLowerCase() === CONDITION_USED
  );
}

/**
 * Convert Underscore to camelCase
 *
 * @param {String} text
 *
 * @return {String} camelCase
 */

export function camelize(text) {
  text = text?.toLowerCase();
  return text?.replace(/_(\w)/g, (all, letter) => {
    return letter.toUpperCase();
  });
}

/**
 * Get used vehicle title substype
 *
 * @param {Array} data
 *
 * @return {String} title subtype
 */
export function getTitleSubtype(titleStatus, data, market) {
  if (market !== 'CN' || !Array.isArray(data) || titleStatus?.toLowerCase() === CONDITION_USED)
    return;
  let titleSubtype = data.find(item => item === DISPLAY || item === TESTDRIVE);
  if (titleSubtype === TESTDRIVE) titleSubtype = TEST_DRIVE;
  return camelize(titleSubtype);
}

/**
 * Check if financing availability needs to be shown
 *
 * @param {object } vehicle
 *
 * @return {Boolean} showFinancingAvailable
 */
export function showFinancingAvailable(vehicle) {
  const { RegistrationDetails, CountryCode, TitleStatus } = vehicle || {};
  switch (CountryCode) {
    case 'FR':
    case 'GB': {
      const firstRegistered = get(RegistrationDetails, 'firstRegistered', null);
      const maxAge = { FR: 72, GB: 108 };
      if (TitleStatus?.toLowerCase() === CONDITION_USED && firstRegistered) {
        const registrationDate = new Date(firstRegistered);
        const currentDate = new Date();
        const monthsAfterRegistration =
          currentDate.getMonth() -
          registrationDate.getMonth() +
          12 * (currentDate.getFullYear() - registrationDate.getFullYear());
        return monthsAfterRegistration <= maxAge[CountryCode];
      }
    }
  }
}

/**
 * Get corresponding pair model
 *
 * @param {string} model
 *
 * @return {string} Corresponding model
 */
export function getCorrespondingModel(model) {
  const map = {
    [MODEL_MY]: MODEL_M3,
    [MODEL_M3]: MODEL_MY,
    [MODEL_MS]: MODEL_MX,
    [MODEL_MX]: MODEL_MS,
  };
  return get(map, `${model}`);
}

/**
 * Get flag to hide inTransitStatus
 *
 * @param {object} vehicle
 *
 * @return {Boolean} hideInTransitStatus
 */
export const hideInTransitStatus = vehicle => {
  const countryMappings = {
    AE: false,
    AT: false,
    BE: false,
    CH: false,
    CZ: false,
    DE: false,
    DK: false,
    ES: false,
    FI: false,
    FR: false,
    GB: false,
    GR: false,
    HR: false,
    HU: false,
    IE: false,
    IL: false,
    IS: false,
    IT: false,
    LU: false,
    NL: false,
    NO: false,
    PL: false,
    PT: false,
    RO: false,
    SE: false,
    SI: false,
    TR: false,
    default: true,
  };
  const { CountryCode: country, Model: model, TitleStatus: condition } = vehicle || {};
  const mapping = countryMappings[country] ?? countryMappings['default'];
  return typeof mapping === 'boolean'
    ? mapping
    : mapping?.condition?.includes(condition?.toLowerCase()) && mapping?.model?.includes(model);
};

/**
 * Get metro name for in transit status
 *
 * @param {bool} showInTransitStatus
 * @param {object} vehicle
 *
 * @return {string} Corresponding model
 */
export function getInTransitStatusMetro({ hideInTransitStatus, vehicle }) {
  if (hideInTransitStatus) {
    return null;
  }

  const {
    InTransit,
    IsAtLocation,
    InTransitMetroName,
    InTransitSalesMetro,
    MetroName,
    SalesMetro,
    VrlLocksUnderThreshold = true,
  } = vehicle || {};

  let locationName = null;
  if (IsAtLocation || VrlLocksUnderThreshold) {
    if (InTransit) {
      locationName = InTransitMetroName || InTransitSalesMetro || null;
    } else {
      locationName = MetroName || SalesMetro || null;
    }
  }

  return locationName;
}

export function getCompositorView({
  compositorEndpoint,
  view = COMPOSITOR_STUD_3QTR,
  fallbackView = 'frontView',
  model,
  optionCodesList,
  crop = '1400,500,300,300',
  size = '1400',
}) {
  return {
    url: `${compositorEndpoint}?&bkba_opt=1&view=${view}&size=${size}&model=${model}&options=${optionCodesList}${
      crop ? `&crop=${crop}` : ''
    }`,
    view: fallbackView,
  };
}

/**
 * This is used for TTP strings which have modal trigger parts within a sentence
 * We want to stick to using actual buttons here than adding anchor tags in TTP strings
 * This helps with accessibility and use ModalTrigger component which ensures button rendering
 *
 * @param {string} startKey
 * @param {string} endKey
 * @param {string} inputString
 * @returns Object with 3 string parts
 */
export const buttonStringExtractor = (
  inputString,
  startKey = BUTTON_START,
  endKey = BUTTON_END
) => {
  // /(?:{BUTTON_START})(.*)(?:{BUTTON_END})/;
  const buttonStringRegex = new RegExp(`(?:${startKey})(.*)(?:${endKey})`);
  // /.+?(?={BUTTON_START})/
  const firstPartRegex = new RegExp(`.+?(?=${startKey})`);
  // /(?:{BUTTON_END})(.*)$/
  const lastPartRegex = new RegExp(`(?:${endKey})(.*)$`);

  const firstPartTest = firstPartRegex.exec(inputString);
  const buttonPartTest = buttonStringRegex.exec(inputString);
  const lastPart = lastPartRegex.exec(inputString);

  return {
    firstPart: firstPartTest ? firstPartTest[0] : null,
    buttonPart: buttonPartTest ? buttonPartTest[1] : null,
    lastPart: lastPart ? lastPart[1] : null,
  };
};

export const getEtaToDelivery = (
  countryCode,
  etaToDelivery,
  actualVesselArrivalDate,
  expectedVesselArrivalDate
) => {
  if (['TW', 'TH'].includes(countryCode)) {
    return actualVesselArrivalDate || expectedVesselArrivalDate || '';
  }
  return etaToDelivery || '';
};

/**
 * Get matching deltas
 *
 * @param  deltas Object of deltas
 * @param  object Params to match against
 * @return array Array of matching deltas
 */
export function getMatchingDeltas(deltas, params) {

  function parseParams (params, key, deltaVal) {
    if (has(params, key)) {
      const paramVal = params[key];
      if (isArray(paramVal)) {
          if (isArray(deltaVal)) {
              return paramVal.some(item => deltaVal.includes(item));
          }
          return deltaVal === 'any' || paramVal.includes(deltaVal);
      }
      else if (isArray(deltaVal) && deltaVal.includes(paramVal)) {
          return true;
      }
      return deltaVal === 'any' || deltaVal === paramVal
    }
  }

  return reduce(deltas, (res, delta) => {
    const isMatching = has(delta, 'selected_by') ? Object.keys(delta?.selected_by).every(key => {
      const deltaVal = delta.selected_by[key];
      switch(key) {
        case 'exclude':
          return isObject(deltaVal) && reduce(deltaVal, (res, val, key) => {
            res = !parseParams(params, key, val);
            return res;
          }, false);

        default:
          return parseParams(params, key, deltaVal) || false;
      }
    }) : true;
    if (isMatching && delta.content) {
      res.push(delta.content);
    }
    return res;
  }, []);
}

/**
 * Get banner content with selected by
 *
 * @param  object Banner content
 * @param  object Delta params
 * @return array Array of matching deltas
 */
export const getBannerContentSelectedBy = ({ bannerContent, params }) => {
  return getMatchingDeltas(bannerContent, params);
};

/**
 * Check if market is EU.
 *
 * @param {string} market
 * @returns bool Returns if is EU market.
 */
export const isEU = market => {
  return getSuperRegion(market)?.code === REGION_EU;
};

/**
 * Check if market is ME.
 *
 * @param {string} market
 * @returns bool Returns if is ME market.
 */
export const isME = market => {
  return getSuperRegion(market)?.code === REGION_ME;
};

/**
 * Check if market is EMEA.
 *
 * @param {string} market
 * @returns bool Returns if is EMEA market.
 */
export const isEMEAMarket = market => {
  return isEU(market) || isME(market);
};

/**
 * Check if federal chip is enabled and eligible
 *
 * @param {object} state
 * @param {object} vehicle
 * @returns object Returns federal tax eligibility.
 */
export const isFederalIncentiveEligible = ({ state, vehicle }) => {
  const {
    enableFederalIncentiveChip = [],
    federalIncentivePricePlusFeesCap, 
    isEnterpriseOrder = false,
    isSwap = false,
  } = state?.App || {};

  const { activeTab } = state?.Filters || {};
  const { FederalIncentives = {}, Model, TitleStatus, Price, PurchasePrice, InventoryPrice, TransportationFee = 0 } = vehicle;
  const transportFee = activeTab !== LOCATION_TAB ? TransportationFee : 0;
  const titleStatus = isSwap ? CONDITION_NEW : TitleStatus?.toLowerCase();
  const price = Price || PurchasePrice || InventoryPrice;
  const isFederalIncentiveChipEnabled = !isEnterpriseOrder && enableFederalIncentiveChip?.[titleStatus]?.includes(Model);
  const pricePlusTransport = price + transportFee;
  const priceCap = federalIncentivePricePlusFeesCap?.[titleStatus]?.[Model] || federalIncentivePricePlusFeesCap?.[titleStatus]?.default || 0;
  const overPriceCap = priceCap && pricePlusTransport > priceCap;

  if (!isFederalIncentiveChipEnabled || overPriceCap) {
    return {};
  } else {
    return {
      ...FederalIncentives,
      price,
    };
  }
}

/**
 * Get paymentType filter value
 * @param categories object
 * @return string
 */
export const getPaymentType = categories => get(categories, 'PaymentType.values[0].value', null);

/**
 * Get eligibile toggle filter value
 * @param categories object
 * @return bool
 */
export const getIncentiveFilter = state => {
  const showIncentiveToggle = get(state, 'App.showIncentiveToggle', []);
  const categories = get(state, 'Filters.options', {});
  const model = get(categories, 'Model.values[0].code', null);
  const titleStatus = get(categories, 'TitleStatus.values[0].code', null);
  const incentiveAvailability = get(categories, 'Incentive.availability', []);
  return showIncentiveToggle?.includes(model) && incentiveAvailability?.includes(`${model}_${titleStatus}`)  ? get(categories, 'Incentive.values[0].value', null) : null;
}

/**
 * Get duration in units
 * @param durationInMonths number
 * @param countryCode string
 * @return object
 */
export const getDutationInUnits = (durationInMonths, countryCode) => {
    let durationInUnits = {
      value: durationInMonths,
      units: '',
    };
    if (countryCode === 'CN') {
      durationInUnits.value = durationInMonths * 30;
    } else if ((durationInMonths % 12) == 0) {
      durationInUnits.value = 1;
      durationInUnits.units = '_year';
    }
    return durationInUnits;
}

/**
 * Get bucket options from initial state
 * @param state object
 * @return array
 */
export const getBucketOptions = state => {
  const { App, InventoryMatch } = state || {};
  const { condition } = App || {};
  const { bucketOptions } = InventoryMatch || {};
  return bucketOptions?.[condition];
}

/**
 * Get bucket options if Filter options selected are Federal Incentives eligible
 * @param state object
 * @return array
 */
export const getFederalIncentiveBuckets = state => {
  const paymentType = get(state, 'Filters.options.PaymentType.values[0].value', null);
  const categories = get(state, 'Filters.options');
  const incentiveEligiblity = getIncentiveFilter(state);
  
  return incentiveEligiblity && paymentType === 'cash'
    ? getBucketOptions(state)
    : null;
}

/**
 * Set preferred location cookie
 * @param state object
 * @return array
 */
export const setPreferredLocationCookie = (data, cookieDomain) => {
  if (!data || !data?.postalCode || !data?.stateCode) {
    return;
  }
  const mappedData = {
    ip: get(data, 'ip', null),
    location: {
      latitude: get(data, 'latitude', ''),
      longitude: get(data, 'longitude', ''),
    },
    region: {
      longName: get(data, 'stateProvince', ''),
      regionCode: get(data, 'stateCode', ''),
    },
    city: get(data, 'city', ''),
    county: get(data, 'county', ''),
    country: get(data, 'countryName', ''),
    countryCode: get(data, 'countryCode', ''),
    postalCode: get(data, 'postalCode', ''),
  };
  const domainPart = cookieDomain ? `domain=${cookieDomain};` : '';
  document.cookie = `preferred_address=${JSON.stringify(mappedData)};${domainPart}expires=0;path=/`;
};

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from 'utils';
import { formatCurrency } from '@tesla/coin-common-components';

const BriefCalculatedResult = ({ downPayment, interest, terms }) => {
  return (
    <p className="tds-text--caption tds-o-padding_bottom-8">
      {i18n('SummaryPanel.disclaimers.lease__tesla_co_lease', {
        DOWNPAYMENT: formatCurrency(downPayment),
        RATE: interest,
        MONTHS: terms,
      })}
    </p>
  );
};

BriefCalculatedResult.propTypes = {
  downPayment: PropTypes.number,
  interest: PropTypes.number,
  terms: PropTypes.number,
};

BriefCalculatedResult.defaultProps = {
  downPayment: 0,
  interest: 0,
  terms: 0,
};

const mapStateToProps = () => {
  return {
    downPayment: 1000,
    interest: 0.1,
    terms: 60,
  };
};

export default connect(mapStateToProps)(BriefCalculatedResult);

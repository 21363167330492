import _reduce from 'lodash/reduce';
import _get from 'lodash/get';
import { formatCurrency } from '@tesla/coin-common-components';
import { getOptionByPartNumber, isEMEAMarket, isAPACMarket, i18n } from 'utils';
import {
  TYPE_BIDDING,
  REGISTRATION_TYPE_BUSINESS,
  REGISTRATION_TYPE_PRIVATE,
  E_INVOICE,
  INVOICE_TYPE_PERSONAL,
  INVOICE_TYPE_BUSINESS,
  CUSTOM_GROUP_TYPE,
  ACCESSORIES_GROUP,
  TYPE_ELECTRONIC,
} from 'dictionary';

export const getAccessoriesList = (state, type) => {
  return state?.Accessories?.accessories?.list?.filter(itm => type ? type === itm?.productType : itm) || [];
}
export const getSelectedAccessories = state => state?.Accessories?.selectedItems || {};
export const getSelectedAccessoriesList = state =>
  Object.values(getSelectedAccessories(state)).sort();
export const getAccessoriesTaxAmount = state => {
  const taxAmountSelectorValue = state?.Accessories?.taxData?.taxAmount;
  return isNaN(parseInt(taxAmountSelectorValue)) ? undefined : taxAmountSelectorValue;
};
export const getAccessoriesRawTotal = state => state?.Accessories?.grossTotal;
export const getShipToAddress = state => state?.Accessories?.shipToAddress || {};
export const getShipFromAddress = state => state?.Accessories?.shipFromAddress || {};
export const getShippingAddressText = state => state?.App?.enableCyberpunk ? ' ' : state?.Accessories?.shippingAddressText || null;
export const hasShippingAddress = state =>
  !!state?.Accessories?.shipToAddress?.country &&
  !!state?.Accessories?.shipToAddress?.city &&
  !!state?.Accessories?.shipToAddress?.street1;
export const isAccessoriesSelected = state => !!getSelectedAccessoriesList(state).length;
export const isTaxInclusive = state => state?.Accessories?.isTaxInclusive || false;
export const isNoPostalCodeMarket = state => state?.Accessories?.isNoPostalCodeMarket || false;
export const isCountryWithStateCode = state => state?.Accessories?.isCountryWithStateCode || false;
export const getSavedAccessories = state =>
  state?.Configuration?.savedConfiguration?.accessories || [];
export const getAccessoriesTotal = state => {
  if (!isAccessoriesSelected(state)) {
    // Check for saved accessories
    return getSavedAccessories(state).reduce((a, b) => a + (b?.price || 0), 0);
  }
  const itemTotal = getAccessoriesRawTotal(state);
  const salesTax = getAccessoriesTaxAmount(state) || 0;
  const isTaxInclusiveMarket = isTaxInclusive(state);
  const totalAmount = isTaxInclusiveMarket ? itemTotal : itemTotal + salesTax;
  return Number(parseFloat(totalAmount).toFixed(2)) || 0;
};
export const getAccessoryBiddableFlowOptIn = state => state?.Accessories?.isOptInForBiddingFlow;

export const getStaticTaxPayload = state => {
  const shipFromAddress = getShipFromAddress(state);
  const {
    city: shipFromCity,
    country: shipFromCountry,
    postalCode: shipFromPostal,
    state: shipFromState,
    street1: shipFromStreet1,
  } = shipFromAddress;
  const shipToAddress = getShipToAddress(state);
  const selectedAccessories = getSelectedAccessories(state);
  const {
    city: shipToCity,
    country: shipToCountry,
    postalCode: shipToPostal,
    state: shipToState,
    street1: shipToStreet1,
  } = shipToAddress;
  return {
    taxAmountSigned: {
      totalTaxAmount: 0,
      deliveryAddress: {
        Address1: shipToStreet1,
        City: shipToCity,
        Country: shipToCountry,
        PostalCode: shipToPostal,
        State: shipToState,
      },
      items: _reduce(
        selectedAccessories,
        (res, val, key) => {
          return {
            ...res,
            [key]: {
              grossAmount: val.options.find(option => option.partNumber === key)?.purchasePrice
                ?.amount,
              quantity: 1,
            },
          };
        },
        {}
      ),
      shipFromAddress: {
        Address1: shipFromStreet1,
        City: shipFromCity,
        Country: shipFromCountry,
        PostalCode: shipFromPostal,
        State: shipFromState,
      },
    },
  };
};

export const getAccessoriesPayload = state => {
  const market = _get(state, 'OMS.oms_params.market');
  const regAccountType = _get(state, 'ReviewDetails.AccountDetail.AccountType');
  const showFiscalCode = _get(state, 'Payment.showFiscalCode', false);
  const storeBusinessFieldValues = !!(regAccountType === REGISTRATION_TYPE_BUSINESS);
  const storePrivateFieldValues = !!(regAccountType === REGISTRATION_TYPE_PRIVATE);
  const invoicePayload =
    isEMEAMarket(market) || isAPACMarket(market)
      ? {
          invoiceDetails: {
            companyName: storeBusinessFieldValues
              ? _get(state, 'ReviewDetails.AccountDetail.CompanyName')
              : null,
            firstName: _get(state, 'ReviewDetails.AccountDetail.FirstName'),
            invoiceType: E_INVOICE,
            invoiceUsage:
              regAccountType === REGISTRATION_TYPE_PRIVATE
                ? INVOICE_TYPE_PERSONAL
                : INVOICE_TYPE_BUSINESS,
            lastName: _get(state, 'ReviewDetails.AccountDetail.LastName'),
            phoneNumber: _get(state, 'ReviewDetails.AccountDetail.PhoneNumber'),
            taxId: storePrivateFieldValues
              ? _get(state, 'ReviewDetails.AccountDetail.PrivateVatId') || ''
              : _get(state, 'ReviewDetails.AccountDetail.VatId') || '',
            // emailId: PecEmail, @TODO: What should be used here?
            fiscalCode: showFiscalCode
              ? _get(state, 'ReviewDetails.AccountDetail.PrivateVatId')
              : null,
            companyCode: storeBusinessFieldValues
              ? _get(state, 'ReviewDetails.AccountDetail.CompanyId')
              : null,
          },
        }
      : {};
  const selectedAccessories = getSelectedAccessories(state);
  const lineItems = Object.entries(selectedAccessories).map(([partNumber, accessory]) => ({
    name: accessory.name,
    partNumber,
    productCode: partNumber,
    productType: accessory?.productType,
    grossAmount: getOptionByPartNumber(partNumber, accessory)?.purchasePrice?.amount,
    quantity: 1,
    isOptInForBiddingFlow: accessory?.isBiddingFlowEnabled
      ? getAccessoryBiddableFlowOptIn(state)
      : false,
  }));
  const overrides = isTaxInclusive(state) ? getStaticTaxPayload(state) : {};
  return {
    shipToAddress: getShipToAddress(state),
    lineItems,
    signedList: state?.Accessories?.accessories?.signedList,
    amount: getAccessoriesTotal(state),
    taxAmount: getAccessoriesTaxAmount(state) || 0,
    taxAmountSigned: state?.Accessories?.taxData?.signaturePayload,
    signature: state?.Accessories?.accessories?.signature,
    taxAmountSignature: state?.Accessories?.taxData?.signature,
    ...overrides,
    ...invoicePayload,
    isElectronicOnly: isOnlyElectronicAccessories(state),
  };
};

export const getAccessoriesPayloadEnterprise = state => {
  const selectedAccessories = getSelectedAccessories(state);
  const lineItems = Object.entries(selectedAccessories).map(([partNumber, accessory]) => ({
    name: accessory.name,
    partNumber,
    productCode: partNumber,
    productType: accessory?.productType,
    grossAmount: getOptionByPartNumber(partNumber, accessory)?.purchasePrice?.amount,
    quantity: 1,
    isOptInForBiddingFlow: accessory?.isBiddingFlowEnabled
      ? getAccessoryBiddableFlowOptIn(state)
      : false,
  }));
  if (!lineItems || !lineItems?.length) {
    return null;
  }
  return {
    lineItems,
    signedList: state?.Accessories?.accessories?.signedList,
    signature: state?.Accessories?.accessories?.signature,
    amount: getAccessoriesTotal(state),
  };
};

export const getAccessoriesLineItems = state => {
  const accessories = state?.Accessories?.selectedItems || {};
  return Object.keys(accessories).map(acc => {
    const { name, grossAmount } = accessories[acc] || {};
    return {
      label: name,
      amount: grossAmount,
    };
  });
};
export const getCommerceTaxEndpoint = state => state?.App?.routes?.postTaxCalculation || '';
export const getCommerceTaxPayload = state => {
  const { useShipFromAddressPerProduct } = state?.App || {};
  const shipToAddress = getShipToAddress(state);
  const { street1: address1, street2: address2, country } = shipToAddress;
  const countryCode = state?.App?.countryCode;
  const selectedAccessories = getSelectedAccessories(state);
  const taxInformationPartDetailList = Object.entries(selectedAccessories).map(
    ([partNumber, accessory]) => {
      const opt = accessory.options.find(option => option.partNumber === partNumber);
      return {
        name: accessory.name,
        productCode: partNumber,
        taxCode: opt?.taxCode,
        grossAmount: opt?.purchasePrice?.amount,
        quantity: 1,
      };
    }
  );

  return {
    countryCode: country || countryCode,
    currencyCode: state?.App?.pricingContext,
    deliveryAddress: {
      ...shipToAddress,
      address1,
      address2,
    },
    shipFromAddress: getShipFromAddress(state),
    taxInformationPartDetailList,
    useShipFromAddressPerProduct,
  };
};
export const getAccessoriesModalIsOpen = state => state?.Accessories?.modal?.isOpen;
export const getAccessoriesModalKeys = state => state?.Accessories?.modal?.accessories || [];
export const getAccessoriesFromKeys = state => key =>
  getAccessoriesList(state)?.find(accessory => accessory.name === key);
export const isErrorOnAccessoriesTax = state => {
  const { error = false, taxAmount, signature } = state?.Accessories?.taxData || {};
  return error && !signature && taxAmount === null;
};
export const getAccessoriesAssetsBase = state => state?.Accessories?.assetsBase || '';
export const isAccessoriesBiddingFlowEnabled = state => {
  return (
    state?.App?.isItemEligibilityFlowEnabled &&
    getSelectedAccessoriesList(state).some(item => item.isBiddingFlowEnabled)
  );
};
export const isAccessoriesBiddingEligible = state => {
  return getSelectedAccessoriesList(state).some(item => item.isBiddingEligible);
};
export const getAccessoriesInstallationEligibility = state => {
  return !!(
    isAccessoriesBiddingFlowEnabled(state) &&
    getShippingAddressText(state) &&
    Object.values(getShipToAddress(state)).length
  );
};
export const getItemEligibilityEndpoint = state => state?.App?.routes?.itemEligibility || '';
export const getAccessoriesPartNumbers = state =>
  Object.keys(getSelectedAccessories(state)).map(partNumber => ({
    partNumber,
    quantity: 1,
  }));
export const getItemEligibilityRequestPayload = state => {
  return {
    partNumbers: getAccessoriesPartNumbers(state),
    address: getShipToAddress(state),
    eligibilityCheckType: TYPE_BIDDING,
  };
};
export const getEditDesignAccessoriesData = state => {
  const list = getAccessoriesList(state);
  const selectedItems = getSelectedAccessories(state);
  const selectedItemsArray = Object.keys(selectedItems);
  const currentSelected = [];
  const listMapped = list.reduce((res, i) => {
    const { name, options = [] } = i;
    if (!options.length) {
      return res;
    }
    options.forEach(({ partNumber: code, purchasePrice = {} }) => {
      const { amount = 0 } = purchasePrice;
      const selected = selectedItemsArray.includes(code);
      const item = {
        code,
        name,
        long_name: name,
        price: amount,
        formattedPrice: formatCurrency(amount),
        selected,
      };
      res.push(item);
      if (selected) {
        currentSelected.push(item);
      }
    });
    return res;
  }, []);
  return {
    totalAvailable: listMapped,
    currentSelected,
    name: i18n('Accessories.category__home_charging'),
    code: ACCESSORIES_GROUP,
    type: CUSTOM_GROUP_TYPE,
  };
};

export const getAccessoriesPayloadPO = state => {
  const selectedItems = getSelectedAccessories(state);
  return _reduce(
    selectedItems,
    (res, val, key) => {
      const { productGroup, name, options = [] } = val;
      res.push({
        name: name,
        part_number: key,
        quantity: 1,
        gross_amount: (options && options[0]?.purchasePrice?.amount) || 0,
        product_group: productGroup,
      });
      return res;
    },
    []
  );
};

export const isChargingConnectorIncluded = state =>
  state?.ReviewDetails?.product?.data?.IsChargingConnectorIncluded || false;

export const isAccessoriesEligibleForTax = state => {
  return getSelectedAccessoriesList(state).some(x => x?.productType !== TYPE_ELECTRONIC);
}

export const isOnlyElectronicAccessories = state => {
  return getSelectedAccessoriesList(state).every(x => x?.productType === TYPE_ELECTRONIC);
}

export const getAccessoriesListByGroup = (state, { productType = '', listType }) => {
  if (!listType) {
    return [];
  }
  return (
    state?.Accessories?.accessories?.types?.[listType]?.filter(itm =>
      productType ? productType === itm?.productType : itm
    ) || []
  );
};
export const getSelectedPartNumbers = state => Object.keys(getSelectedAccessories(state)) || [];
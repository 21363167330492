import React from 'react';
import { connect } from 'react-redux';
import { arrayOf, shape, bool } from 'prop-types';
import classnames from 'classnames';

import LexiconGroup from './LexiconGroup';

const InventoryFeatureHighlights = ({ highlightGroups = [], isLayoutMobile }) => {
  if (!highlightGroups.length) {
    return null;
  }

  return (
    <div
      className={classnames('feature-highlights-container', {
        'tds--vertical_padding--6x': isLayoutMobile,
      })}
    >
      {highlightGroups.map(option => {
        const { components = [] } = option || {};
        return (
          <If key={option.name} condition={components.length}>
            <LexiconGroup
              key={`inventoryFeatureHighlights__${option.name}`}
              group={option}
              id="InventoryFeatureHighlights"
            />
          </If>
        );
      })}
    </div>
  );
};

InventoryFeatureHighlights.propTypes = {
  highlightGroups: arrayOf(shape({})),
  isLayoutMobile: bool,
};

InventoryFeatureHighlights.defaultProps = {
  highlightGroups: [],
  isLayoutMobile: false,
};

const mapStateToProps = state => {
  const { App, ReviewDetails } = state;
  const { isLayoutMobile = false } = App;
  const { vehicleUpgrades: upgrades = [], inventoryFeatureHighlights = {} } = ReviewDetails;
  const { featureIncludedInConfig = [], highlightFeatureGroups = [] } = inventoryFeatureHighlights;
  let featuresToRender = [];
  if (highlightFeatureGroups.length) {
    featuresToRender.push(...highlightFeatureGroups);
  }
  if (upgrades.length) {
    featuresToRender.push(...upgrades);
  }
  const highlightGroups = featuresToRender.length ? featuresToRender : featureIncludedInConfig;
  return {
    highlightGroups,
    isLayoutMobile,
  };
};

export default connect(mapStateToProps)(InventoryFeatureHighlights);

import React from 'react';
import HtmlToReact from 'html-to-react';
import DOMPurify from 'dompurify';

const htmlToReactParser = new HtmlToReact.Parser(React)

/**
* @see - https://www.npmjs.com/package/html-to-react
*/

// used when doing custom node processing

/**
 * Accepts html string as input and returns a React component
 * (allows us to avoid most needs for dangerouslySetInnerHTML)
 * 
 * @param {String} strHtml - HTML string to convert into React components
 * @param {Array} processingInstructions - see README
 * @return {React.Component}
 */
export function htmlToReact(strHtml, processingInstructions){

	function __isValidNode() {
	    return true
	}

    if (processingInstructions) {
        let processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React)
        return htmlToReactParser.parseWithInstructions(strHtml, __isValidNode, processingInstructions)
    }
    
    // if strHtml does not begin with a tag, then wrap in span tag
    if(strHtml?.trim?.()?.substring(0,1) !== "<"){
    	strHtml = `<span>${strHtml}</span>`
    }
    let cleanString = '';
    if (strHtml) {
        cleanString = DOMPurify.sanitize(strHtml, { ADD_ATTR: ['target'] });
    } 

    return htmlToReactParser.parse(cleanString);
}

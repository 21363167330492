import _find from 'lodash/find';
import _get from 'lodash/get';
import _reduce from 'lodash/reduce';
import _includes from 'lodash/includes';
import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';
import _capitalize from 'lodash/capitalize';
import configureStore from '../store/configureStore';
import { i18n, isWeChatMini, isWeChatBrowser } from 'utils';
import { getFinanceTabs, isInventory, isUsedInventory, isComboInventory } from 'selectors';
import { MODEL_GIO_NAME, CURRENT_CONFIGURATION_SOURCE_URL_PARAM } from './dictionary';

/**
 *
 * GrowingIO Statistic for CN Only
 * Listen for click events those bubble to document.body
 * Usage:
 *
 *  <button
        data-gio-track={GIO_TAG_ENABLE}
        data-gio-eventname="web_design_overview_nextstep"
    >
    </button>
 *  data-gio-track={GIO_TAG_ENABLE} // true or false  true make statistics for this tag
 *  data-gio-eventname="custom_event_name" // agreed with PM, defined in the growingIO platform
 *
 */

// Event names
export const WEB_DESIGN_PAYMENT_PLACE = 'web_design_payment_place';
export const WEB_DESIGN_PAYMENT_SUBMIT = 'web_design_payment_submit';
export const WEB_DESIGN_OVERVIEW_NEXTSTEP = 'web_design_overview_nextstep';
export const WEB_CHECK_CHANGE_CLICK = 'web_check_change_click'; // edit design page -> check change button
export const WEB_GIVE_UP_CLICK = 'web_give_up_click'; // edit design page -> abandon change button
export const WEB_CONFIRM_CHANGE_CLICK = 'web_confirm_change_click'; // edit design page -> confirm change button
export const WEB_KEEP_CURRENT_DESIGN_CLICK = 'web_keep_current_design_click'; // edit design page -> keep current design button
export const WEB_SAVE_DESIGN_OPEN_MODAL_CLICK = 'web_save_design_open_modal_click'; // open save design modal
export const WEB_SAVE_DESIGN_SUBMIT_CLICK = 'web_save_design_submit_click'; // open save design modal
export const WEB_DESIGN_INVENTORY_PAYMENT_SUBMIT = 'web_design_inventory_payment_submit'; // inventory submit order button
export const WEB_DESIGN_VIEW_PAGE = 'web_design_view_page'; // configurator view page

export const WEB_CONFIGURATOR_BANNER_LINK = 'web_configurator_banner_link';
export const WEB_CONFIGURATOR_FINANCING_CALCULATOR = 'web_configurator_financing_calculator';
export const WEB_CONFIGURATOR_PAYMENT_FINANCING_CALCULATOR =
  'web_configurator_payment_financing_calculator';
export const WEB_CONFIGURATOR_TRIM_FEATURE_CARD = 'web_configurator_trim_feature_card';
export const WEB_CONFIGURATOR_INTERIOR_FEATURE_CARD = 'web_configurator_interior_feature_card';
export const WEB_CONFIGURATOR_EAP_FEATURE_CARD = 'web_configurator_eap_feature_card';
export const WEB_CONFIGURATOR_FSD_FEATURE_CARD = 'web_configurator_fsd_feature_card';
export const WEB_CONFIGURATOR_PAYMENT_PC_DETAILS = 'web_configurator_payment_pc_details';
export const WEB_CONFIGURATOR_PAYMENT_DETAILS = 'web_configurator_payment_details';
export const WEB_CONFIGURATOR_PAYMENT_GAS_SAVING_CALCULATION =
  'web_configurator_payment_gas_saving_calculation';

// For html tag level. enable true/false
export const GIO_TAG_ENABLE = true;

export default class GioStatistics {
  // CN market enabled
  static enabled;

  static start() {
    const state = configureStore?.store.getState() || {};
    const market = _get(state, 'OMS.oms_params.market', '');
    GioStatistics.enabled = market === 'CN' && window.gdp && !_isEmpty(state);
    if (GioStatistics.enabled) {
      document.body.addEventListener('click', GioStatistics.trackEvent);
      const referralCode = _get(state, 'ApplicationFlow.referral.referralCode', '');
      const isTeslaApp = GioStatistics.isTeslaApp(state);
      const modelCode = _get(state, 'Configuration.model_code', '');
      const { isComboNewInventory, isInternalNewInventory } = GioStatistics.getData(state);
      window.addEventListener('load', () => {
        window.gdp &&
          window.gdp('track', WEB_DESIGN_VIEW_PAGE, {
            referral: referralCode ? 'Yes' : 'No',
            is_wechat_miniprogram: isWeChatMini() ? 'Yes' : 'No',
            model_code: modelCode,
            is_tesla_app: isTeslaApp ? 'Tesla App - Yes' : 'Tesla App - No',
            source_env: GioStatistics.getSourceEnv(state),
            ...(isInventory(state)
              ? {
                  is_combo_new_inventory: isComboNewInventory ? 'Yes' : 'No',
                  is_internal_new_inventory: isInternalNewInventory ? 'Yes' : 'No',
                }
              : {}),
          });
      });
    }
  }

  static manuallyTrack(eventName, attribute = {}) {
    if (!GIO_TAG_ENABLE || !this.enabled) {
      return;
    }
    const state = configureStore.store.getState();
    const isTeslaApp = GioStatistics.isTeslaApp(state);
    const sourceEnv = GioStatistics.getSourceEnv(state);

    attribute['model_code'] = _get(state, 'Configuration.model_code', '');
    attribute['is_wechat_miniprogram'] = isWeChatMini() ? 'Yes' : 'No';
    attribute['is_tesla_app'] = isTeslaApp ? 'Tesla App - Yes' : 'Tesla App - No';
    attribute['source_env'] = sourceEnv;

    switch (eventName) {
      case WEB_CONFIGURATOR_FINANCING_CALCULATOR:
      case WEB_CONFIGURATOR_PAYMENT_FINANCING_CALCULATOR:
      case WEB_CONFIGURATOR_TRIM_FEATURE_CARD:
      case WEB_CONFIGURATOR_INTERIOR_FEATURE_CARD:
      case WEB_CONFIGURATOR_EAP_FEATURE_CARD:
      case WEB_CONFIGURATOR_FSD_FEATURE_CARD:
      case WEB_CONFIGURATOR_PAYMENT_PC_DETAILS:
      case WEB_CONFIGURATOR_PAYMENT_DETAILS:
      case WEB_CONFIGURATOR_PAYMENT_GAS_SAVING_CALCULATION: {
        window.gdp && window.gdp('track', eventName, attribute);
        break;
      }
      default: {
        return;
      }
    }
  }
  static remove() {
    if (GioStatistics.enabled) {
      document.body.removeEventListener('click', GioStatistics.trackEvent);
    }
  }

  static trackEvent(e) {
    const dom = e.target;
    let isFromSaveDesign = false;
    let configurationSource = null;
    if (GioStatistics.get(dom, 'track') === 'true') {
      const state = configureStore.store.getState();
      const modelCode = _get(state, 'Configuration.model_code', '');
      const isTeslaApp = GioStatistics.isTeslaApp(state);
      const sourceEnv = GioStatistics.getSourceEnv(state);
      const { isComboNewInventory, isInternalNewInventory } = GioStatistics.getData(state);
      const eventName =
        _get(dom.dataset, 'gioEventname', '') || _get(dom.attributes, 'data-gio-eventname', '');
      const obj = {};
      switch (eventName) {
        case WEB_DESIGN_PAYMENT_PLACE: {
          const financeTabs = getFinanceTabs(state);
          const label = _get(
            _find(financeTabs, item => item.selected),
            'label',
            ''
          );
          obj['payment_method'] = label;
          obj['model_code'] = modelCode;
          obj['is_tesla_app'] = isTeslaApp ? 'Tesla App - Yes' : 'Tesla App - No';
          obj['source_env'] = sourceEnv;
          if (isInventory(state)) {
            obj['is_combo_new_inventory'] = isComboNewInventory ? 'Yes' : 'No';
            obj['is_internal_new_inventory'] = isInternalNewInventory ? 'Yes' : 'No';
          }
          break;
        }

        case WEB_DESIGN_PAYMENT_SUBMIT:
          isFromSaveDesign = _get(state, 'App.isFromSaveDesignFlow', false);
          configurationSource = _get(state, 'Configuration.configurationSource', null);
          obj['configuration_from_save_design'] = isFromSaveDesign ? (configurationSource || '') : '';
        // eslint-disable-next-line no-fallthrough
        case WEB_DESIGN_INVENTORY_PAYMENT_SUBMIT: {
          const accountType = _get(state, 'ReviewDetails.AccountDetail.AccountType', '');
          const paymentType = _get(state, 'Payment.PaymentDetail.PaymentType', '');
          obj['payment_account'] = i18n(`Review.account_in_${accountType}`);
          obj['payment_tool'] = i18n(`Review.payment_type_${paymentType}`);
          GioStatistics.getOptionsData(state, obj);
          if (isInventory(state)) {
            obj['title_status'] =
              isInventory(state) && isUsedInventory(state)
                ? i18n(`Inventory.common.usedVehicle`)
                : i18n(`Inventory.common.new_vehicle`);
            obj['is_combo_new_inventory'] = isComboNewInventory ? 'Yes' : 'No';
            obj['is_internal_new_inventory'] = isInternalNewInventory ? 'Yes' : 'No';
          }
          const referralCode = _get(state, 'ApplicationFlow.referral.referralCode', '');
          obj['referral'] = referralCode ? 'Yes' : 'No';
          obj['is_wechat_miniprogram'] = isWeChatMini() ? 'Yes' : 'No';
          obj['model_code'] = modelCode;
          obj['is_tesla_app'] = isTeslaApp ? 'Tesla App - Yes' : 'Tesla App - No';
          obj['source_env'] = sourceEnv;
          break;
        }

        case WEB_DESIGN_OVERVIEW_NEXTSTEP: {
          GioStatistics.getOptionsData(state, obj);
          obj['model_code'] = modelCode;
          obj['is_tesla_app'] = isTeslaApp ? 'Tesla App - Yes' : 'Tesla App - No';
          obj['source_env'] = sourceEnv;
          break;
        }
        case WEB_CONFIGURATOR_BANNER_LINK:
        case WEB_SAVE_DESIGN_SUBMIT_CLICK:
        case WEB_SAVE_DESIGN_OPEN_MODAL_CLICK:
          obj['is_wechat_miniprogram'] = isWeChatMini() ? 'Yes' : 'No';
        // eslint-disable-next-line no-fallthrough
        case WEB_CHECK_CHANGE_CLICK:
        case WEB_GIVE_UP_CLICK:
        case WEB_KEEP_CURRENT_DESIGN_CLICK:
        case WEB_CONFIRM_CHANGE_CLICK: {
          const isMobile = _get(state, 'App.isLayoutMobile', false);
          const model = _get(state, 'Configuration.model_code', '');

          obj['model_type'] = MODEL_GIO_NAME[model] || '';
          obj['is_mobile'] = isMobile ? 'Mobile' : 'Web';
          obj['model_code'] = modelCode;
          obj['is_tesla_app'] = isTeslaApp ? 'Tesla App - Yes' : 'Tesla App - No';
          obj['source_env'] = sourceEnv;
          break;
        }
        default: {
          return;
        }
      }

      window.gdp && window.gdp('track', eventName, obj);
    }
  }

  static getData(state) {
    const isComboNewInventory =
      isInventory(state) && isComboInventory(state) && !isUsedInventory(state);
    const isInternalNewInventory =
      isInventory(state) && !isComboInventory(state) && !isUsedInventory(state);
    return {
      isComboNewInventory,
      isInternalNewInventory,
    };
  }

  // data-* compatibility
  static get(dom, attribute) {
    return dom.dataset
      ? dom.dataset['gio' + _capitalize(attribute)]
      : dom.getAttribute(`data-gio-${attribute}`);
  }

  static getOptionsData(state, obj) {
    const configs = ['TRIM', 'PAINT', 'WHEELS', 'INTERIOR', 'INTERIOR_COLORWAY', 'AUTOPILOT'];
    const lexiconOptions = _get(state, 'OMS.lexicon.options', {});
    const groups = _get(state, 'Configuration.options_by_group', []);
    _reduce(
      groups,
      (res, value, key) => {
        const code = _isArray(value) ? value[0] : value;
        if (_includes(configs, key) && lexiconOptions[code]) {
          res[/^INTERIOR_/g.test(key) ? 'model_interior' : `model_${key.toLowerCase()}`] =
            lexiconOptions[code].long_name || lexiconOptions[code].name;
        }
        return res;
      },
      obj
    );
  }

  static getSourceEnv(state) {
    const isTeslaApp = GioStatistics.isTeslaApp(state);
    const isWeChatMiniProgram = isWeChatMini();
    const isWeChatBrowserEnv = isWeChatBrowser();
    const isLayoutMobile = _get(state, 'App.isLayoutMobile', false);
    const isLayoutTablet = _get(state, 'App.isLayoutTablet', false);
    let env = 'PC browser';
    if (isTeslaApp) {
      env = 'Tesla App';
    } else if (isWeChatMiniProgram) {
      env = 'Wechat Mini Program';
    } else if (isWeChatBrowserEnv) {
      env = 'Wechat Browser';
    } else if (isLayoutMobile) {
      env = 'Phone browser';
    } else if (isLayoutTablet) {
      env = 'Tablet browser';
    }
    return env;
  }

  static isTeslaApp(state) {
    return _get(state, 'App.isDm', false);
  }
}

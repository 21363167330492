import React from "react";
import classnames from 'classnames';
import { Form, Checkbox } from '@tesla/informed-tds';
import { formatPhone } from '@tesla/intl-phone';
import { formatDate } from '@tesla/intl-date-time';
import { formatCurrency } from '@tesla/coin-common-components';

import { i18n, htmlToReact } from 'utils';
import {
  REGISTRATION_TYPE_BUSINESS,
  REGISTRATION_TYPE_PRIVATE,
  PRICE_ACCEPTANCE,
} from 'dictionary';
import Accordion from '../../../components/Accordion';

export default function PriceAcceptance({ locale, onSubmit }) {
  const {
    Order = {},
    rn,
  } = window?.tesla ?? {};

  const {
    buyerInfo,
    expiry,
    pickupLocation,
  } = window?.tesla?.priceAcceptance ?? {};

  const { orderAmount } = Order.orderDetails ?? {};

  return (
    <div className="tds--vertical_padding">
      <Accordion
        className="tds--vertical_padding"
        title={i18n('PriceAcceptance.title_buyer_information')}>
        <ul className="tds-list">
          {[
            {
              key: 'PriceAcceptance.label_company_name',
              value: buyerInfo.vat?.companyName,
              customerTypes: [REGISTRATION_TYPE_BUSINESS]
            },
            {
              key: 'PriceAcceptance.label_name',
              value: `${buyerInfo.user?.firstName} ${buyerInfo.user?.lastName}`,
              customerTypes: [REGISTRATION_TYPE_PRIVATE]
            },
            {
              key: 'PriceAcceptance.label_address',
              value: [
                buyerInfo.address?.address1,
                buyerInfo.address?.zipCode,
                buyerInfo.address?.city,
                buyerInfo.address?.stateProvince
              ].filter(Boolean).join(', '),
              customerTypes: [REGISTRATION_TYPE_PRIVATE, REGISTRATION_TYPE_BUSINESS]
            },
            {
              key: 'PriceAcceptance.label_email',
              value: buyerInfo.user?.emailAddress,
              customerTypes: [REGISTRATION_TYPE_PRIVATE, REGISTRATION_TYPE_BUSINESS]
            },
            {
              key: 'PriceAcceptance.label_phonenumber',
              value: formatPhone(buyerInfo.user?.phoneNumber, buyerInfo.user?.phonePrefix),
              customerTypes: [REGISTRATION_TYPE_PRIVATE, REGISTRATION_TYPE_BUSINESS]
            },
            {
              key: 'PriceAcceptance.label_tkcn',
              value: buyerInfo.vat?.privateVatId,
              customerTypes: [REGISTRATION_TYPE_PRIVATE]
            },
            {
              key: 'PriceAcceptance.label_taxoffice_buyer',
              value: `${buyerInfo.vat?.taxOfficeName}, ${buyerInfo.vat?.companyVatId}`,
              customerTypes: [REGISTRATION_TYPE_BUSINESS]
            },
          ].filter(item => !!item.value && item.customerTypes.includes(`${buyerInfo.vat?.customerType}`.toLowerCase()))
            .map((item, index) => (<AccordionListItem key={item.key} item={item} paddingTop={index > 0} />))}
        </ul>
      </Accordion>
      <Accordion
        className="tds--vertical_padding"
        title={i18n('PriceAcceptance.title_seller_information')}>
        <ul className="tds-list">
          {[
            { key: 'PriceAcceptance.label_company_name', value: i18n('PriceAcceptance.value_tesla_company_name') },
            { key: 'PriceAcceptance.label_address', value: i18n('PriceAcceptance.value_tesla_company_address') },
            { key: 'PriceAcceptance.label_email', value: i18n('PriceAcceptance.value_tesla_email') },
            { key: 'PriceAcceptance.label_vkn', value: i18n('PriceAcceptance.value_vkn') },
            { key: 'PriceAcceptance.label_taxoffice', value: i18n('PriceAcceptance.value_taxoffice') },
          ].filter(item => !!item.value)
            .map((item, index) => (<AccordionListItem key={item.key} item={item} paddingTop={index > 0} />))}
        </ul>
      </Accordion>
      <Accordion
        className="tds--vertical_padding"
        title={i18n('PriceAcceptance.title_order_information')}>
        <ul className="tds-list">
          {[
            { key: 'PriceAcceptance.label_rn', value: rn },
            { key: 'PriceAcceptance.label_today', value: formatDate(new Date(), locale) },
            { key: 'PriceAcceptance.label_order_deposit', value: orderAmount ? i18n('PriceAcceptance.value_order_deposit', { PRICE: formatCurrency(orderAmount) }) : '' },
            { key: 'PriceAcceptance.label_order_channel', value: i18n('PriceAcceptance.value_order_channel') },
            { key: 'PriceAcceptance.label_delivery_center', value: `${pickupLocation.title}<br/>${pickupLocation.address}` },
          ].filter(item => !!item.value)
            .map((item, index) => (<AccordionListItem key={item.key} item={item} paddingTop={index > 0} />))}
        </ul>
      </Accordion>
      <Form
        className="tds--vertical_padding"
        initialValues={{ priceAcceptance: false, orderAgreement: false }}>
        {({ formApi }) => {
          const { priceAcceptance, orderAgreement } = formApi.getFormState().values;
          const isAcceptPriceDisabled = !(priceAcceptance && orderAgreement);

          return (
            <>
              <Checkbox
                name={PRICE_ACCEPTANCE}
                id={PRICE_ACCEPTANCE}
                label={htmlToReact(i18n('PriceAcceptance.label_checkbox_price_acceptance'))}
              />
              <Checkbox
                name="orderAgreement"
                id='orderAgreement'
                label={htmlToReact(i18n('PriceAcceptance.label_checkbox_order_agreement'))}
              />
              <div className="priceAcceptance-disclaimer tds--vertical_padding">
                {htmlToReact(
                  i18n('PriceAcceptance.disclaimer', {
                  UNTIL_DATE: formatDate(expiry, locale),
                  BUSINESS_DAYS_COUNT: 5
                }))}
                {htmlToReact(i18n('PriceAcceptance.legal_disclaimer'))}
              </div>
              <div className="tds-btn_group tds-btn_group--vertical">
                <button
                  disabled={isAcceptPriceDisabled}
                  className={classnames('tds-btn tds-btn--primary tds-btn--width-full tds-btn--large', {
                    disabled: isAcceptPriceDisabled,
                  })}
                  data-id="btn-priceAcceptance-accept"
                  data-subtype="btn-price-acceptance--accept"
                  type="button"
                  onClick={() => onSubmit('ACCEPT')}
                >
                  {i18n('PriceAcceptance.btn_accept')}
                </button>
                <button
                  className={'reject-price-button tds-btn tds-btn--secondary tds-btn--width-full'}
                  data-id="btn-priceAcceptance-reject"
                  data-subtype="btn-price-acceptance--reject"
                  type="button"
                  onClick={() => onSubmit('REJECT')}
                >
                  {i18n('PriceAcceptance.btn_reject')}
                </button>
              </div>
            </>)
        }}
      </Form>
    </div>
  )
}
const AccordionListItem = ({ item = {}, paddingTop = false }) => (
  <li className={classnames({ 'tds--vertical_padding-top': paddingTop })}>
    <p style={{ color: 'var(--tds-color--grey30)' }}>{i18n(item.key)}</p>
    <strong style={{ color: 'var(--tds-color--block)' }}>{htmlToReact(item.value)}</strong>
  </li>
)
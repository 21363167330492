import { UPDATE_INCENTIVES } from 'dictionary';
import { toggleTrimSavingsPrice } from 'actions';

export const updateIncentives = value => {
  return (dispatch, getState) => {
    const state = getState();
    const { showAfterSavingsPriceForTrims } = state?.ReviewDetails || {};
    const { userSelectedIncentives, incentives } = state?.Financial || {};
    const current = (showAfterSavingsPriceForTrims && incentives?.current) || {};
    const selected = !userSelectedIncentives ? (Object.keys(current) || []) : userSelectedIncentives;
    let data = Array.isArray(value)
      ? value
      : selected.includes(value)
        ? selected.filter(v => v !== value)
        : [...selected, value];

    dispatch({
      type: UPDATE_INCENTIVES,
      incentives: data,
    });

    if (data?.length && Object.keys(data)?.length === Object.keys(incentives?.currentAvailable || {})?.length && !showAfterSavingsPriceForTrims) {
      return dispatch(toggleTrimSavingsPrice(true));
    }
  };
};

import {
  STREET_NUMBER,
  ROUTE,
  LOCALITY,
  ADMINISTRATIVE_AREA_LEVEL_1,
  ADMINISTRATIVE_AREA_LEVEL_2,
  ADMINISTRATIVE_AREA_LEVEL_3,
  POSTAL_TOWN,
  SUBLOCALITY_LEVEL_1,
  SUBLOCALITY_LEVEL_4,
  POSTAL_CODE,
  POSTAL_CODE_SUFFIX,
  COUNTRY,
  NEIGHBORHOOD,
  PREMISE,
} from 'dictionary';
import useScript from '../../hooks/useScript';

export const googleAutocomplete = async (text, countryCode) => {
  return new Promise(resolve => {
    if (!text || text.length < 3) {
      return resolve([]);
    }
    const { google } = window;
    if (!google) {
      return resolve([]);
    }
    const AutoComplete = new google.maps.places.AutocompleteService();
    AutoComplete.getPlacePredictions(
      {
        input: text,
        componentRestrictions: { country: countryCode },
        types: ['address'],
      },
      places => {
        if (places) {
          return resolve(places);
        }
        return resolve([]);
      }
    );
  });
};

export const googleGeocoder = async place_id => {
  const { google } = window;

  return new Promise(resolve => {
    if (!google) {
      return resolve(null);
    }
    const Geocoder = new google.maps.Geocoder();

    Geocoder.geocode({ placeId: place_id }, results => {
      if (results) {
        return resolve(results[0]);
      }
      return resolve(null);
    });
  });
};

/**
 * Get mapped address from Google geocoding.
 *
 * @param  object geocoding address
 *
 * @return object normalized address.
 */
export function getAddressFromGeocoding(address, googleAutocompleteRules) {
  const components = address?.address_components || [];
  const updatedComponents = components.reduce((acc, component) => {
    component.types.find(val => {
      switch (val) {
        case STREET_NUMBER:
          acc[STREET_NUMBER] = component.long_name;
          break;
        case ROUTE:
          acc[ROUTE] = component.long_name;
          break;
        case LOCALITY:
          acc[LOCALITY] = component.long_name;
          break;
        case ADMINISTRATIVE_AREA_LEVEL_1:
          acc[ADMINISTRATIVE_AREA_LEVEL_1] = component.short_name;
          break;
        case ADMINISTRATIVE_AREA_LEVEL_2:
          acc[ADMINISTRATIVE_AREA_LEVEL_2] = component.short_name;
          break;
        case ADMINISTRATIVE_AREA_LEVEL_3:
          acc[ADMINISTRATIVE_AREA_LEVEL_3] = component.long_name;
          break;
        case POSTAL_TOWN:
          acc[POSTAL_TOWN] = component.long_name;
          break;
        case SUBLOCALITY_LEVEL_1:
          acc[SUBLOCALITY_LEVEL_1] = component.long_name;
          break;
        case SUBLOCALITY_LEVEL_4:
          acc[SUBLOCALITY_LEVEL_4] = component.long_name;
          break;
        case POSTAL_CODE:
          acc[POSTAL_CODE] = component.long_name;
          break;
        case POSTAL_CODE_SUFFIX:
          acc[POSTAL_CODE_SUFFIX] = component.long_name;
          break;
        case COUNTRY:
          acc[COUNTRY] = component.short_name;
          break;
        case NEIGHBORHOOD:
          acc[NEIGHBORHOOD] = component.short_name;
          break;
        case PREMISE:
          acc[PREMISE] = component.long_name;
          break;
        default:
          break;
      }
    });
    return acc;
  }, {});
  const {
    street_number,
    route,
    locality,
    administrative_area_level_1,
    administrative_area_level_2,
    administrative_area_level_3,
    postal_town,
    sublocality_level_1,
    postal_code,
    postal_code_suffix,
    country,
    neighborhood,
  } = updatedComponents;

  return {
    street1: googleAutocompleteRules.street1Rule.map(val => updatedComponents[val]).join(' '),
    street2:
      updatedComponents[googleAutocompleteRules.street2Rule.find(val => updatedComponents[val])],
    suburb: googleAutocompleteRules.suburbRule
      ? updatedComponents[googleAutocompleteRules.suburbRule.find(val => updatedComponents[val])]
      : null,
    city: updatedComponents[googleAutocompleteRules.cityRule.find(val => updatedComponents[val])],
    state: googleAutocompleteRules.stateRule
      ? updatedComponents[googleAutocompleteRules.stateRule.find(val => updatedComponents[val])]
      : null,
    postalCode: postal_code,
    postalCodeExt: postal_code_suffix,
    country,
  };
}

// Method to run the google api script
export function ScriptWrapper({ gKey, gChannel }) {
  if (gKey && gChannel) {
    const googleMapJs = `https://maps.googleapis.com/maps/api/js?libraries=places&key=${gKey}&channel=${gChannel}`;
    useScript(googleMapJs, 'GoogleMap', 'script');
  }
  return null;
}


import {
  FINANCIAL_MODAL_STANDALONE,
  FIN_MODAL_CUSTOMIZE,
  MODAL_MEDIUM,
  FinanceTypes,
} from 'dictionary';
import { bool, number, shape, string } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import {
  getFinanceTabs,
  getSelectedFinanceProduct,
  getPricingSummary,
  getVATAmount,
} from 'selectors';
import { i18n } from 'utils';
import FinanceDisclaimer from '../../GroupComponents/FinanceDisclaimer';
import ModalTrigger from '../../ModalTrigger';
import PricingDisclaimers from './PricingDisclaimers';
import BannerModalTrigger from './BannerModalTrigger';
import { formatCurrency } from '@tesla/coin-common-components';
import { getFinanceType } from '../../../common/selectors';
import BriefCalculatedResult from './BriefCalculatedResult';

const FinanceTabs = ({
  incentives,
  paymentLabel,
  formattedPurchasePrice,
  financeProductId,
  vatDisclaimer,
  reviewRefactor,
  financeType,
}) => {
  return (
    <div className="tab-content">
      <div className="pricing-container tds-o-padding_bottom-24">
        <div className="price-block">
          <div className="finance-section--wrapper tds-o-padding_bottom-8">
            <ul className="tds-list">
              <If condition={reviewRefactor}>
                <li
                  className={cx('tds-list-item tds-o-margin_bottom', {
                    'tds-text--h4': financeType === FinanceTypes.CASH,
                  })}
                >
                  <span className="left tds-text_color--10 tds-o-padding_right-8">
                    {i18n('common.purchasePrice')}
                  </span>
                  <span className="tds-text--end tds-text_color--10">{formattedPurchasePrice}</span>
                </li>
              </If>
              <If condition={!reviewRefactor || financeType !== FinanceTypes.CASH}>
                <li className="tds-list-item tds-text--h4 tds-o-margin_bottom">
                  <span className="left tds-text--medium tds-text_color--10 tds-o-padding_right-8">
                    {paymentLabel}
                  </span>
                  <span className="tds-text--end tds-text--medium tds-text_color--10">
                    {formattedPurchasePrice}
                  </span>
                </li>
              </If>
              <If condition={incentives?.key}>
                <li className="tds-list-item tds-o-margin_bottom" data-id="tax-credit-line-item">
                  <span className="left tds-text--caption tds-o-padding_right-8">
                    <BannerModalTrigger contentSource={incentives?.key} label={incentives?.label} />
                  </span>
                  <span className="tds-text--caption">{incentives?.formattedPrice}</span>
                </li>
              </If>
            </ul>
            <PricingDisclaimers />
            <If condition={vatDisclaimer && !financeProductId}>
              <p className="tds-text--caption">{vatDisclaimer}</p>
            </If>
          </div>
          <If condition={!reviewRefactor}>
            <FinanceDisclaimer
              className="tds-text--caption finance-disclaimer--condensed"
              financeType={financeType}
            />
          </If>
          <If condition={reviewRefactor && financeType !== FinanceTypes.CASH}>
            <BriefCalculatedResult />
          </If>
          <ModalTrigger
            analyticsInteraction={FIN_MODAL_CUSTOMIZE}
            options={{
              props: {
                componentName: FINANCIAL_MODAL_STANDALONE,
                props: {
                  genericWrapper: true,
                  size: MODAL_MEDIUM,
                  classes: 'tds-link',
                  isMega: true,
                },
              },
            }}
          >
            <p className="tds-text--caption">{i18n('Review.explore_financing')}</p>
          </ModalTrigger>
          <If condition={reviewRefactor}>
            <FinanceDisclaimer
              className="tds-text--caption finance-disclaimer--condensed"
              reviewRefactor={reviewRefactor}
              financeType={financeType}
            />
          </If>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const financeTabs = getFinanceTabs(state);
  const selectedTab = getSelectedFinanceProduct(state);
  const financeType = getFinanceType(state);
  const { financeProductId } = financeTabs.find(tab => tab?.id === selectedTab) || {};
  const {
    ReviewDetails: { showVAT = false, showMyVAT = false, reviewRefactor = false },
  } = state;
  const pricingSummaryObj = getPricingSummary(state);

  let paymentLabel = pricingSummaryObj?.isMonthly
    ? i18n('SummaryPanel.est_payment_label__finplat', null, null, {
        specificOverride: financeProductId,
      })
    : i18n('Review.est_purchase_price');

  let vatDisclaimer =
    showVAT &&
    i18n('SummaryPanel.disclaimers.vatAmount', {
      VAT_AMOUNT: formatCurrency(getVATAmount(state)),
    });
  if (showMyVAT) {
    vatDisclaimer = i18n('SummaryPanel.disclaimers.myVatAmount');
  }

  if (reviewRefactor) {
    if (financeType === FinanceTypes.LEASE) {
      paymentLabel = i18n('SummaryPanel.payment__lease');
    }
    if (financeType === FinanceTypes.LOAN) {
      paymentLabel = i18n('SummaryPanel.payment__loan');
    }
  }

  return {
    paymentLabel,
    financeProductId,
    vatDisclaimer,
    financeType,
    reviewRefactor,
    ...pricingSummaryObj,
  };
}

FinanceTabs.propTypes = {
  paymentLabel: string,
  financeProductId: string,
  formattedPurchasePrice: string.isRequired,
  incentives: shape({
    price: number,
    key: string,
    label: string,
  }),
  vatDisclaimer: string,
  financeType: string,
  reviewRefactor: bool,
};

FinanceTabs.defaultProps = {
  paymentLabel: '',
  financeProductId: null,
  monthlyPayment: {
    price: 0,
    key: '',
    label: '',
  },
  vatDisclaimer: '',
  financeType: FinanceTypes.CASH,
  reviewRefactor: false,
};

export default connect(mapStateToProps)(FinanceTabs);

import React from 'react';
import { shape, string, bool, number, arrayOf } from 'prop-types';
import { connect } from 'react-redux';
import { i18n, getDutationInUnits } from 'utils';
import cx from 'classnames';
import { REFERRAL_TYPE_B, REFERRAL_MODAL, NAVIGATION_VIEW_OVERVIEW } from 'dictionary';
import { formatCurrency } from '@tesla/coin-common-components';
import { referralConfigValidation } from 'selectors';
import { openModal } from 'actions';
import _isEmpty from 'lodash/isEmpty';
import { iconStatusSuccess } from '@tesla/design-system-icons';
import { Icon } from '@tesla/design-system-react';
import '../../style/referral.css';

const ReferralBanner = ({
  validConfigForReferral,
  referralData,
  openReferralModal,
  section,
  creditCash,
  creditTrial,
  showCredits,
  isOverview,
  market,
  validityDisclaimer,
  showReferralZeroCreditDisclaimer,
}) => {
  if (_isEmpty(referralData) || !validConfigForReferral || referralData.type !== REFERRAL_TYPE_B) {
    return null;
  }

  const defaultCreditCashLabel = i18n('Referral.criteria.benefitsList.cash', {
    AMOUNT: formatCurrency(creditCash),
  });

  const creditCashLabel =  i18n('Referral.overrides.cashBenefit', { AMOUNT: formatCurrency(creditCash) }, null, { returnNullWhenEmpty: true }) ?? defaultCreditCashLabel;

  return (
    <div className="tds--vertical_padding--small">
      <div className={cx({ 'tds-banner coin-banner': !isOverview })}>
        <div
          className={cx('tds-o-flex_no-wrap tds-o-padding_bottom-4', {
            'tds-flex--justify-center': isOverview,
          })}
        >
          <Icon data={iconStatusSuccess} />
          <Choose>
            <When condition={isOverview || !showCredits}>
              <p className="tds--horizontal_padding--small">
                <span className="tds-text--medium">{i18n('Referral.referral_applied')}</span>
                <If condition={showCredits || showReferralZeroCreditDisclaimer}>
                <a className="tds-link tds--horizontal_margin-5" onClick={openReferralModal}>
                  {i18n('common.learn_more')}
                </a>
                </If>
              </p>
            </When>
            <When condition={creditCash || creditTrial?.length}>
              <div className="tds-flex-item tds--text-align-left tds--horizontal_margin-5">
                <div className="tds-text--medium tds-o-padding_bottom-4">
                  {i18n('Referral.referral_applied')}
                </div>
                <If condition={creditCash}>
                  <div className="tds-text--caption tds-o-padding_bottom-4">
                    {creditCashLabel}
                  </div>
                </If>
                <If condition={creditTrial?.length}>
                  {creditTrial?.map(x => {
                    const durationInUnits = getDutationInUnits(x?.durationInMonths, market);
                    if (!_isEmpty(durationInUnits)) {
                      const { units, value } = durationInUnits || {};
                      return (
                        <div className="tds-text--caption tds-o-padding_bottom-4">
                          {i18n(`Referral.criteria.benefitsList.trial_${x?.optionCode}${units}`, {
                            DURATION: value,
                          })}
                        </div>
                      );
                    }
                  })}
                </If>
                <If condition={validityDisclaimer?.length}>
                  <div className="tds-text--caption tds-o-margin-top">{validityDisclaimer}</div>
                </If>
              </div>
            </When>
          </Choose>
        </div>
      </div>
    </div>
  );
};

ReferralBanner.propTypes = {
  referralData: shape({
    isValid: bool,
    type: string,
  }),
  validConfigForReferral: bool,
  creditCash: number,
  creditTrial: arrayOf(shape({})),
  showCredits: bool,
  isOverview: bool,
  market: string,
  validityDisclaimer: string,
};

ReferralBanner.defaultProps = {
  referralData: {},
  validConfigForReferral: false,
  creditCash: 0,
  creditTrial: [],
  showCredits: false,
  isOverview: false,
  market: '',
  validityDisclaimer: '',
};

function mapStateToProps(state) {
  const { section = '' } = state?.Navigation || {};
  const referralData = state.ApplicationFlow.referral;
  const creditCash = referralData?.cash?.value || 0;
  const creditTrial = referralData?.trials || [];
  const market = state?.App?.countryCode || '';
  const validityDisclaimer =
    i18n('Referral.criteria.validity', null, null, {
      returnNullWhenEmpty: true,
    }) ?? '';

  return {
    referralData,
    section,
    creditCash,
    creditTrial,
    showCredits: !!(creditCash || creditTrial?.length),
    isOverview: section === NAVIGATION_VIEW_OVERVIEW,
    market,
    validityDisclaimer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openReferralModal: () => {
      dispatch(
        openModal(REFERRAL_MODAL, {
          genericWrapper: false,
        })
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferralBanner);

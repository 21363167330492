import {
  MODAL_DISCLAIMER,
  FULL_IMAGE,
  TABBED_CONTENT,
  MORE_INFO,
  FINANCE_CONTAINER,
  VIDEO_MODAL,
  ZOOM_IMAGE_MODAL,
  GAS_SAVING_MODAL,
  STANDARD_FEATURES_MODAL,
  SESSION_TIME_OUT_MODAL,
  COMMON_MODAL,
  REFERRAL_MODAL,
  FEDERAL_TAX_MODAL,
  BATTERY_SAVINGS_MODAL,
  RECOMMENDED_INFO_MODAL,
  FEATURE_LIST_MODAL,
  USER_LOCATION_MODAL,
  RANGE_INFO_MODAL,
  REQUEST_CALLBACK_MODAL,
  VIDEO_ASSET_MODAL,
  BANNER_MODAL,
  SHOW_INCENTIVES_MODAL,
  INSPECTION_MODAL,
  WARRANTY_MODAL,
  INVENTORY_FEATURES_MODAL,
  INVENTORY_COMPARE_MODAL,
  VEHICLE_UNAVAILABLE_MODAL,
  PREPROD_MODAL,
  ADDITIONAL_DETAILS_MODAL,
  TRANSPORTATION_FEE_MODAL,
  ASSET_GALLERY_MODAL,
  VEHICLE_DETAILS_MODAL,
  USER_ZIPCODE_MODAL,
  LOAD_LATEST_LEXICON_MODAL,
  DELIVERY_ZIP_MODAL,
  POST_ORDER_SWAP_ERROR_MODAL,
  CONFIRM_CITY_OUT_OF_STOCK_MODAL,
  SAVE_DESIGN_MODAL,
  ESIGN_FLOW_EXCEPTION_MODAL,
  FINANCIAL_MODAL_STANDALONE
} from 'dictionary';

const InitialState = {
  object: {
    open: false,
    selected: null,
    available: [],
    layouts: {
      disclaimer: {
        component: MODAL_DISCLAIMER,
      },
      full_image: {
        component: FULL_IMAGE,
      },
      tabbed_content: {
        component: TABBED_CONTENT,
      },
      more_info: {
        component: MORE_INFO,
      },
      finance_container: {
        component: FINANCE_CONTAINER,
      },
      success_modal: {
        component: 'SuccessModal',
      },
      video_modal: {
        component: VIDEO_MODAL,
      },
      standard_features_modal: {
        component: STANDARD_FEATURES_MODAL,
      },
      zoom_image: {
        component: ZOOM_IMAGE_MODAL,
      },
      gas_saving: {
        component: GAS_SAVING_MODAL,
      },
      session_time_out_modal: {
        component: SESSION_TIME_OUT_MODAL,
      },
      referral_modal: {
        component: REFERRAL_MODAL,
      },
      common_modal: {
        component: COMMON_MODAL,
      },
      federal_tax_modal: {
        component: FEDERAL_TAX_MODAL,
      },
      battery_savings_modal: {
        component: BATTERY_SAVINGS_MODAL,
      },
      recommended_info_modal: {
        component: RECOMMENDED_INFO_MODAL,
      },
      feature_list_modal: {
        component: FEATURE_LIST_MODAL,
      },
      user_location_modal: {
        component: USER_LOCATION_MODAL,
      },
      user_zipcode_modal: {
        component: USER_ZIPCODE_MODAL,
      },
      range_info_modal: {
        component: RANGE_INFO_MODAL,
      },
      request_callback_modal: {
        component: REQUEST_CALLBACK_MODAL,
      },
      video_asset_modal: {
        component: VIDEO_ASSET_MODAL,
      },
      banner_modal: {
        component: BANNER_MODAL,
      },
      incentives_modal: {
        component: SHOW_INCENTIVES_MODAL,
      },
      inspection_modal: {
        component: INSPECTION_MODAL,
      },
      warranty_modal: {
        component: WARRANTY_MODAL,
      },
      inventory_features_modal: {
        component: INVENTORY_FEATURES_MODAL,
      },
      inventory_compare_modal: {
        component: INVENTORY_COMPARE_MODAL,
      },
      vehicle_unavailable_modal: {
        component: VEHICLE_UNAVAILABLE_MODAL,
      },
      preprod_modal: {
        component: PREPROD_MODAL,
      },
      save_design_modal: {
        component: SAVE_DESIGN_MODAL,
      },
      additional_details_modal: {
        component: ADDITIONAL_DETAILS_MODAL,
      },
      transportation_fee_modal: {
        component: TRANSPORTATION_FEE_MODAL,
      },
      asset_gallery_modal: {
        component: ASSET_GALLERY_MODAL,
      },
      vehicle_details_modal: {
        component: VEHICLE_DETAILS_MODAL,
      },
      load_latest_lexicon_modal: {
        component: LOAD_LATEST_LEXICON_MODAL,
      },
      delivery_zip_modal: {
        component: DELIVERY_ZIP_MODAL,
      },
      post_order_swap_error_modal: {
        component: POST_ORDER_SWAP_ERROR_MODAL,
      },
      confirm_city_out_of_stock_modal: {
        component: CONFIRM_CITY_OUT_OF_STOCK_MODAL,
      },
      esign_flow_exception_modal: {
        component: ESIGN_FLOW_EXCEPTION_MODAL,
      },
      financial_modal: {
        component: FINANCIAL_MODAL_STANDALONE
      }
    },
  },
};

export default InitialState;

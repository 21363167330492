/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StatusMessage } from '@tesla/design-system-react';
import classnames from 'classnames';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import _indexOf from 'lodash/indexOf';
import _isEmpty from 'lodash/isEmpty';
import {
  MODAL_SMALL,
  PICKUP_LOCATION,
  FIELD_PICKUP_LOCATION,
  GAS_SAVING_MODAL,
  Models,
  PAYMENT_TYPE_APPLEPAY,
} from 'dictionary';
import {
  getCustomPricingIndicatorV2,
  i18n,
  getOrderDisclaimerSource,
  isPreOrder,
  htmlToReact,
  isPriceAcceptance,
  ScriptWrapper,
} from 'utils';
import useScript from '../../hooks/useScript';
import ModalTrigger from '../../components/ModalTrigger';
import DeliveryDate from '../../components/GroupComponents/DeliveryDate';

import Summary from './Summary';
import Pricing from './Pricing';
import PaymentForms from './PaymentForms';

import PrintPdfButton from '../../components/SaveDesign/PrintPdfButton';
import SelectionForm from '../../components/SelectionForm';
import Icon from '../../components/GroupComponents/Icon';

import InsuranceDisclaimer from './Sections/InsuranceDisclaimer';
import ApplyForFinancingLink from './Sections/ApplyForFinancingLink';
import SaveDesignLink from './Sections/SaveDesignLink';
import DepositAmountTable from './Sections/DepositAmountTable';
import LegalDisclaimers, {
  OrderLegalDisclaimers,
  PostOrderSwapDisclaimers,
} from './Sections/LegalDisclaimers';
import VatDisclaimer from './Sections/VatDisclaimer';
import SummaryPageDisclaimer from './Sections/SummaryPageDisclaimer';
import ContactUsLink from './Sections/ContactUsLink';
import ZipCodeLink from '../../components/ZipCodeLink';
import VehicleLocationDisclaimer from './Sections/VehicleLocationDisclaimer';
import { formatCurrency } from '@tesla/coin-common-components';
import IRAIncentiveCopy from '../../components/GroupComponents/IRAIncentiveCopy';
import PriceAcceptance from './Sections/PriceAcceptance';
import RegistrationTypeSection from './Sections/RegistrationTypeSection';
import LegalConsent from '../../components/AccountForm/LegalConsent';

export default class PaymentOverviewRepresentation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPayxPaymentOptionSelected: false,
    };
    this.getOptionIndicatorOverwite = this.getOptionIndicatorOverwite.bind(this);
  }

  triggerAnalytics = event => {
    const target = _get(event, 'target');
    if (typeof _get(target, 'getAttribute') === 'function') {
      const dataType = target.getAttribute('data-cfg-type');
      const href = target.getAttribute('href');
      const _target = target.getAttribute('target'); // eslint-disable-line no-underscore-dangle

      if (dataType && href) {
        const { externalLinkClicked } = this.props;
        event.preventDefault();
        externalLinkClicked({
          analytics: { 'cfg-type': dataType, interaction: 'expand' },
          href,
          _target,
        });
      }
    }
  };

  checkIfpayxPaymentOptionSelected = payxPaymentOptionSelected => {
    this.setState({
      isPayxPaymentOptionSelected: payxPaymentOptionSelected,
    });
  };

  getOptionIndicatorOverwite = option => {
    const { configuration, priceData } = this.props;
    return getCustomPricingIndicatorV2([option], configuration, priceData);
  };

  handlePostConnect = () => {
    const { triggerPostConnectRedirect, postConnectUrl } = this.props;
    setTimeout(() => {
      triggerPostConnectRedirect(postConnectUrl);
    }, 500);
  };

  getPricingSectionProps = () => {
    return _pick(this.props, [
      'annualLoanInterestRate',
      'annualLeaseInterestRate',
      'modelCode',
      'trimCode',
      'selectedTab',
      'model',
      'baseConfiguration',
      'discounts',
      'options',
      'vehiclePrice',
      'monthlyPayment',
      'amountAtSigning',
      'leaseAmountDueAtSigning',
      'loanTerm',
      'leaseTerm',
      'interestRate',
      'downPayment',
      'downPaymentPercentage',
      'loanAfterSaving',
      'leaseAfterSaving',
      'destinationAndDocFee',
      'recyclingFee',
      'tireFee',
      'winterTireFee',
      'summaryRegionalFees',
      'showIncentivesSectionTitle',
      'showdestinationAndDocFee',
      'incentives',
      'incentivesTotal',
      'tcc',
      'configuration',
      'fuelYears',
      'leaseMileage',
      'activeFinanceTypes',
      'modelCode',
      'showVAT',
      'vatAmount',
      'vatPercent',
      'reversePricingOrder',
      'hideAfterSavingsPrice',
      'showSavingsAmount',
      'savingsAmount',
      'hideTaxesAndFeesExcluded',
      'showOtherLoanTerms',
      'loanDistance',
      'effectiveRate',
      'residualAmount',
      'extraIncentives',
      'showPrpLink',
      'showLeaseSummaryFields',
      'showLeaseSummaryDisclaimer',
      'shortPrice',
      'cashPrice',
      'cashPriceExVAT',
      'showAmountIncludingFees',
      'hideDestinationDocFee',
      'showLoanSummaryFields',
      'showLoanSummaryDisclaimer',
      'ecoBonus',
      'leaseData',
      'loanData',
      'regionalTax',
      'registrationTax',
      'showRegistrationTax',
      'specialTax',
      'ecoTaxReduction',
      'governmentGrant',
      'governmentIncentivesTotal',
      'governmentIncentivesPeriod',
      'market',
      'showCustomizeLabel',
      'leaseRate',
      'loanRate',
      'debitRate',
      'startFee',
      'hideAfterSavingsModal',
      'showOptionsPricing',
      'showBeforeSavingsPriceFinancingLabel',
      'sibling',
      'locale',
      'showSecondDepositDisclaimer',
      'useModelYContent',
      'showRegionalTaxTypes',
      'showDistinctTaxTotal',
      'vehiclePriceInclFees',
      'regionalTaxValues',
      'showDriveAwayPrice',
      'showFormattedMonthlyPayment',
      'showSavingsDisclaimer',
      'deliveryFee',
      'frtFee',
      'exlFrtPrice',
      'OMVICFee',
      'grossPrice',
      'balloonPayment',
      'totalAmtPaid',
      'gstSalePrice',
      'onRoadfeesTotal',
      'showFeesDisclaimer',
      'regionalTax',
      'showRegionalTax',
      'setPricingDetails',
      'showChargerDisclaimer',
      'includeTeslaInsurance',
      'insuranceAmount',
      'showOtherFeesinSummary',
      'showLoanFieldsOrderPage',
      'showOrderPageSummaryOtherFees',
      'showTaxCalcDisclaimer',
      'showSavingsDisclaimeronCashTab',
      'cashTabIncentives',
      'isReservation',
      'isUsedInventory',
      'isFalconDeliverySelectionEnabled',
      'isInventoryPriceAdjustmentsEnabled',
      'discount',
      'reservationAmt',
      'VehicleDetail',
      'showDOCandOMVICfees',
      'showVATBreakdown',
      'ACFee',
      'registrationType',
      'showNonRefundPolicy',
      'purchaseTax',
      'purchaseTaxAmount',
      'vehiclePriceWithoutDiscounts',
      'showTaxAndFeeDisclaimerForUsed',
      'isDomestic',
      'isPreSale',
      'showGrossPriceTotal',
      'netPrice',
      'reservationPriceLabel',
      'showOrderFee',
      'showPaymentDisclaimers',
      'showPurchasePrice',
      'financeTabs',
      'monthlyLeasePayment',
      'financeProductId',
      'USLegalDisclaimer',
      'deliveryPostalCode',
      'transportationFee',
      'isDeliveryDetailsValid',
      'isTransportFeeEnabled',
      'isTransportFeeCollectionEnabled',
      'userHasApplePay',
      'modifyOrder',
      'isEstimatedPrice',
      'showMonthlyPaymentQuote',
      'disclaimerIntlDateFormat',
      'loanType',
      'incentiveTotalInPurchasePrice',
      'hidePurchasePriceIncentive',
      'sellerDiscount',
      'stateContribution',
      'showVatDisclaimer',
      'showGovernmentSubsidy',
      'totalSavings',
      'fuelReward',
      'onTypeChange',
      'setDrivertype',
      'financeProductByType',
      'driverType',
      'triggerSelectTab',
      'selectedFinanceProduct',
      'showTerritoryList',
      'showFleetSalesRegionList',
      'showRegistrationTypeSelector',
      'setRegion',
      'showRegistrationAboveSummary',
      'incentivesTotalPurchasePrice',
      'feesTotal',
      'financeMiniDisclaimer',
      'firstMonthDisclaimer',
      'showProvincialIncentives',
      'extraPricingContextAmounts',
      'dutyAndSurcharge',
      'monthlyPaymentDisclaimer',
      'showEVCredit',
      'priceAfterEVCredit',
      'evCredit',
      'fuelSavingsPerMonth',
      'showFuelMonthlySavings',
      'hideIncentivesOrderPage',
      'showAvailableInState',
      'tradeInType',
      'showElectronicPayment',
      'setAccountRegistrationType',
      'showVehiclePricePlusFees',
      'financeSelectionDislaimer',
      'regionalFeeRebate',
      'governmentIncentive',
      'showEvSubsidyDisclaimer',
      'depositAmount',
      'showFeesSummary',
      'secondPaymentAltCurrency',
      'isEditDesignPreserved',
      'financeDisclaimer',
      'showLeaseDisclaimer',
      'firstRegistrationDate',
      'showFirstRegistrationDatePaymentPage',
      'postOrderDeposit',
      'isNativePaymentEnabled',
      'isPostOrderSwap',
      'postOrderSwapLocation',
      'showFinancialTabsEnabled',
      'showVehicleSubtotal',
      'feeLineItems',
      'feesDisclaimer',
      'showFinancingBanner',
      'displayDoubleCurrency',
      'leaseMonthlyPaymentInIntlCurrency',
      'vehicleCondition',
      'productType',
      'showCoEBid',
      'coeBidAmount',
      'showPriceWithCOE',
      'priceExcludingCoE',
      'monthlyPaymentWithCredit',
      'deliveryCredit',
      'showVehicleConfigurationPrice',
      'finplatProductName',
      'showVehiclePriceWithoutSavings',
      'regionalDisclaimer',
      'hasRegionalBannerModalCopy',
      'sctTax',
      'referralCredit',
      'showIRABusinessEligibility',
      'productType',
      'hideFinanceProduct',
      'isReservationToOrderFlow',
      'showPricesDetail',
      'showPricesDetailIconTrigger',
      'monthlyPaymentAfterTaxCredit',
      'showVisitTeslaFinancing',
      'isTradeInEnabled',
      'tradeInAmount',
      'tradeInYear',
      'tradeInMake',
      'tradeInModel',
      'selectFinanceTab',
      'remainingTradInCredit',
      'tradeInDown',
      'tradeinDisclaimers',
      'enableCyberpunk',
      'hideBaseField',
      'interstateTransitFee',
      'taxCredit',
      'priceAfterTaxCredit',
      'isInventory',
      'verificationStatus',
    ]);
  };

  render() {
    const { handlePostConnect, checkIfpayxPaymentOptionSelected } = this;

    const {
      options,
      discounts,
      baseConfiguration,
      model,
      modelCode,
      paymentTabsType,
      showInvoiceOption,
      validateAndPlaceOrder,
      validateAndPlacePublicOrder,
      cancelUpdateOrder,
      saveMarketingConfig,
      submitTried,
      ccDetailsValid,
      isDeliveryDetailsValid,
      isDeliveryDetailsRequired,
      orderPlaced,
      userHasApplePay,
      depositAmount,
      modifyOrder,
      rnLoaded,
      isMobile,
      isTablet,
      configuration,
      isPaymentDetailsValid,
      isInvoiceDetailsValid,
      connect,
      financeTabs,
      // grouping finance related vars together
      validateAndSaveDesign,
      showRightOrder,
      hideBaseField,
      validationErrors,
      showRegistrationTypeSelector,
      showPricesDetail,
      showDistinctTaxTotal,
      destinationAndDocFee,
      hideEstimatedDeliveryOrderPage,
      showOtherFeesinSummary,
      fuelYears,
      isReservation,
      isReservedPreOrder,
      isBookedPreOrder,
      showOrderFeeDisclaimer,
      showNonRefundPolicy,
      showOrderRefundDisclaimer,
      isPaymentFormOpen,
      isPaymentInteractionRequired,
      isNativePaymentEnabled,
      displayOtherEUDisclaimer,
      isPostOrderSwap,
      hidePostOrderSwapLocation,
      postOrderSwapLocation,
      showOrderFee,
      showNonRefundableDisclaimer,
      interstateTransitFee,
      transportationFee,
      deliveryPostalCode,
      isTransportFeeEnabled,
      isTransportFeeCollectionEnabled,
      depositAmountBase,
      isSessionSet,
      dispatchCheckSession,
      isSinglePage,
      isCollapsedFlow,
      displayAsset,
      isAsset,
      withAsset,
      classes,
      isSaveDesignEnabled,
      isFixedPickupLocation,
      isSaveDesignLiteEnabled,
      isDownloadPDFEnabled,
      fuelReward,
      disclaimerSectionAboveOrderButton: disclaimersType,
      showRegistrationAboveSummary,
      showContactUs,
      isAccountDetailsValid,
      legalConsentValid,
      showLegalConsentAfterPayX,
      orderAgreementUrl,
      enableCyberpunk,
      isInventory,
      isPickupOnlyEnabled,
      hasAlerts,
      payXUIRef,
      isUnbuildable,
      deliveryAndRegistrationDisclaimer,
      showFeesSummary,
      isQtyInfoValid,
      financeDisclaimer,
      showFinanceDisclaimerOrderPage,
      isAccessoriesValid,
      isDeliverySelectionEnabled,
      isDm,
      market,
      locale,
      postPriceAcceptance,
      referralCredit,
      isUsedInventory,
      showLegalConsentEditDesign,
      setTimeOfClickPlaceOrderButton,
      sectionsToHide,
      showPricesDetailIconTrigger,
      showSavingsAfterTaxCredit,
      shouldShowEsignProcess,
      paymentType,
      hideMobilePaymentSummary,
      hasAccessories,
      ggConf,
    } = this.props;

    const hasApplePayDisclaimerBelow = market === 'US';
    if (isAsset && !displayAsset) {
      return null;
    }

    const { isPayxPaymentOptionSelected = false } = this.state;

    const pricingSectionProps = this.getPricingSectionProps();
    const invitedOrderButtonDisabled =
      orderPlaced || !isPaymentDetailsValid() || !legalConsentValid;
    const payxPaymentOptionSelectedValid = () => {
      if (market === 'CN') {
        return isPayxPaymentOptionSelected;
      }
      return true;
    };
    const orderButtonDisabled =
      orderPlaced ||
      !isPaymentDetailsValid() ||
      !isAccountDetailsValid() ||
      !isInvoiceDetailsValid() ||
      !isDeliveryDetailsValid ||
      !isQtyInfoValid ||
      !isAccessoriesValid ||
      !payxPaymentOptionSelectedValid();

    const saveDesignButtonDisabled = !isAccountDetailsValid();
    const orderRefund = getOrderDisclaimerSource('orderRefundShort', modelCode, {
      showNonRefundPolicy,
    });
    const modelName = _get(model, 'long_name', '');
    const modelCommonName = _get(model, 'common_name', modelName);

    const orderRefundWithTransportFee =
      isTransportFeeCollectionEnabled && transportationFee
        ? i18n('SummaryPanel.disclaimers.orderNotRefundShortWithTransportFee', {
            FEE: formatCurrency(transportationFee),
            ORDER_DEPOSIT: formatCurrency(depositAmountBase),
            POSTAL_CODE: deliveryPostalCode,
          })
        : '';

    const hidePaymentTable = market === 'CN' && (isReservedPreOrder || isBookedPreOrder);
    const pickupLocationError = _get(validationErrors, `${FIELD_PICKUP_LOCATION}`, false);
    const showPickupLocation = _indexOf(hideBaseField, PICKUP_LOCATION) < 0 && !hidePaymentTable;
    const assetLabel = isReservation
      ? `Reservation.${modelCode}.model_label`
      : `Review.model_label`;

    if (showRightOrder) {
      financeTabs.unshift(financeTabs.reverse().pop());
    }

    const displayTransportFeeInfo =
      !isDeliverySelectionEnabled &&
      userHasApplePay &&
      isTransportFeeEnabled &&
      isDeliveryDetailsValid;

    if (isAsset && !displayAsset) {
      return null;
    }

    const title = isPostOrderSwap
      ? market === 'CN'
        ? i18n('Review.confirm_inventory_config')
        : i18n('Review.summary_label')
      : isDeliverySelectionEnabled
      ? i18n('common.orderYourModel', { MODEL: modelCommonName })
      : i18n(assetLabel, { MODEL: modelCommonName });

    return (
      <>
        <If condition={hasAccessories && !_isEmpty(ggConf)}>
          <ScriptWrapper {...ggConf} />
        </If>
        <If condition={!isAsset || withAsset}>
          <div
            className={classnames(
              {
                'tds-content_container tds--horizontal_padding': !enableCyberpunk,
              },
              'cf-content_container',
              {
                'option-widget--container': isMobile,
              },
              { 'tds-theme--dk': isDm },
              { [classes]: classes }
            )}
          >
            <If condition={!sectionsToHide && !enableCyberpunk && !hideMobilePaymentSummary}>
              <div
                className={classnames(
                  { 'tds--vertical_padding': !isReservation },
                  { 'tds--vertical_padding-top': isReservation }
                )}
              >
                {/* SECTION START */}
                <SummaryPageDisclaimer withoutPadding />
                {/* Title Section */}
                <h2
                  className={classnames('cf-h2-title tds-text--center', {
                    'cf-payment-overview__title': !isPostOrderSwap && isDeliverySelectionEnabled,
                  })}
                >
                  {title}
                </h2>
                <If condition={!hidePostOrderSwapLocation && isPostOrderSwap}>
                  <div className="review-page--container tds-o-vertical_padding-top tds-text--center">
                    <span>{i18n('common.pickupAt', { LOCATION: postOrderSwapLocation })}</span>
                  </div>
                </If>
                <If condition={isReservation}>
                  <div className="review-page--container">
                    <SelectionForm />
                  </div>
                </If>
                {/* delivery and registration disclaimer */}
                <If condition={deliveryAndRegistrationDisclaimer}>
                  <div className="tds-text--center tds-text--caption deliveryAndRegistrationDisclaimer paymentDeliveryAndRegistrationDisclaimer">
                    <Icon id="tds-info" className="deliveryAndRegistrationDisclaimerIcon" />
                    <span className="deliveryAndRegistrationDisclaimerText">
                      {deliveryAndRegistrationDisclaimer}
                    </span>
                  </div>
                </If>
                {/* Transport Fee Info Section */}
                <If condition={!isPickupOnlyEnabled && displayTransportFeeInfo}>
                  <StatusMessage
                    className="tds--fade-in"
                    body={<ZipCodeLink />}
                    type="info"
                    bodyTag="div"
                  />
                </If>
                {/* Save Design Lite Section */}
                <If condition={isSaveDesignLiteEnabled}>
                  <SaveDesignLink />
                </If>
                {/* Download PDF Section */}
                <If condition={!modifyOrder && !isSaveDesignLiteEnabled && isDownloadPDFEnabled}>
                  <div className="tds-text--center">
                    <PrintPdfButton label={i18n('share.downloadPDF')} />
                  </div>
                </If>
                {/* Delivery Date Section */}
                <If condition={!isSinglePage && !isUsedInventory}>
                  <DeliveryDate
                    className="delivery-date--link tds-text--caption"
                    hideEstimatedDeliveryOrderPage={hideEstimatedDeliveryOrderPage}
                  />
                </If>
                <If condition={isPreOrder()}>
                  <div className="preorder-disclaimer tds-text--caption tds--vertical_padding tds-text--center">
                    {i18n('PreOrder.disclaimer', null, null, { returnNullWhenEmpty: true })}
                  </div>
                </If>
              </div>
            </If>

            {/* Display Pickup only disclaimer */}
            <If condition={!isDeliverySelectionEnabled && isPickupOnlyEnabled}>
              <VehicleLocationDisclaimer />
            </If>

            {/* registration type and registration territory before summary */}
            <RegistrationTypeSection />

            {/* Group Section for Cybertruck was here */}
            <If condition={!sectionsToHide}>
              {/* Summary Section */}
              <If condition={!isReservation}>
                <Summary
                  discounts={discounts}
                  baseConfiguration={baseConfiguration}
                  options={options}
                  isPriceIndicatorVisible={!showPricesDetail || isPriceAcceptance()}
                  configuration={configuration}
                  hideBaseField={hideBaseField}
                  showRegistrationTypeSelector={
                    !showRegistrationAboveSummary && showRegistrationTypeSelector
                  }
                  showDistinctTaxTotal={showDistinctTaxTotal}
                  destinationAndDocFee={destinationAndDocFee}
                  showOtherFeesinSummary={showOtherFeesinSummary}
                  depositAmount={depositAmount}
                  showOrderFee={showOrderFee}
                  interstateTransitFee={interstateTransitFee}
                  fuelReward={fuelReward}
                  showFeesSummary={showFeesSummary}
                  referral={referralCredit}
                />
              </If>
              {/* Pricing Section */}
              <Pricing {...pricingSectionProps}>
                <div className="savings-disclaimer">
                  {i18n(
                    'SummaryPanel.disclaimers.savingsAfterPurchase',
                    {
                      NUM_FUEL_YEAR: fuelYears,
                    },
                    null,
                    {
                      specificOverride: modelCode,
                    }
                  )}
                  <ModalTrigger
                    options={{
                      props: {
                        componentName: GAS_SAVING_MODAL,
                        props: { genericWrapper: true, size: MODAL_SMALL, modelName },
                      },
                    }}
                  >
                    <a className="tds-link"> {i18n('Review.savings_link_label')}</a>
                  </ModalTrigger>
                </div>
              </Pricing>
            </If>
            <If condition={!modifyOrder}>
              {/* Modify Order & Deposits Section */}
              <If condition={isSinglePage}>
                <div className="pricing-container review-page--due-today review-page--due-today-container">
                  {/* Vat Disclaimer */}
                  <VatDisclaimer />
                  {/* Apply for Financing Disclaimer */}
                  <ApplyForFinancingLink />
                  {/* Deposit Amount Section */}

                  <DepositAmountTable bolded prepayment />
                </div>
              </If>

              <IRAIncentiveCopy showSavingsAfterTaxCredit={showSavingsAfterTaxCredit} />

              <If condition={!isSinglePage && !hidePaymentTable}>
                <div className="pricing-container review-page--due-today review-page--due-today-container">
                  {/* Deposit Amount Section */}
                  <DepositAmountTable prepayment />
                </div>
              </If>
            </If>
            <If condition={!isSinglePage && !isPriceAcceptance() && !isPostOrderSwap}>
              <div className={classnames('tds-text--caption', { 'tds--vertical_padding': market !== 'US' })}>
                {/* Vat Disclaimer */}
                <VatDisclaimer />
                {/* Apply for Financing Disclaimer */}
                {!isPreOrder() && <ApplyForFinancingLink />}
                {/* Financial Disclaimer Section */}
                <If condition={showFinanceDisclaimerOrderPage}>
                  {
                    <p className="tds-text--caption tds--vertical_padding summary-container--finance-disclaimer">
                      {htmlToReact(financeDisclaimer)}
                    </p>
                  }
                </If>
                {/* Insurance Disclaimer Section */}
                <InsuranceDisclaimer />
                {disclaimersType && userHasApplePay && !hasApplePayDisclaimerBelow && (
                  <LegalDisclaimers type={disclaimersType} withRefundPolicy={false} />
                )}
              </div>
            </If>
            <If condition={isSinglePage && disclaimersType && userHasApplePay && !hasApplePayDisclaimerBelow}>
              <div className="tds-text--caption tds--vertical_padding">
                <LegalDisclaimers type={disclaimersType} withRefundPolicy={false} />
              </div>
            </If>

            <If condition={isPriceAcceptance()}>
              <PriceAcceptance onSubmit={postPriceAcceptance} locale={locale} />
            </If>

            <If condition={isNativePaymentEnabled && !isPostOrderSwap}>
              <If condition={showLegalConsentAfterPayX?.length}>
                <div className="tds--vertical_padding-bottom">
                  <LegalConsent orderAgreementUrl={orderAgreementUrl} showLegalConsentAfterPayX={showLegalConsentAfterPayX} />
                </div>
              </If>
              <If condition={displayOtherEUDisclaimer}>
                <p className="order-disclaimer">{htmlToReact(i18n('SummaryPanel.disclaimers.otherEU'))}</p>
              </If>
              <OrderLegalDisclaimers displayInline={!!isInventory} />
            </If>

            <If condition={isPostOrderSwap}>
              <PostOrderSwapDisclaimers />
            </If>

            {/* Payment Section */}
            <PaymentForms
              userHasApplePay={userHasApplePay}
              isCollapsedFlow={isCollapsedFlow}
              rnLoaded={rnLoaded}
              depositAmount={depositAmount}
              ccDetailsValid={ccDetailsValid}
              submitTried={submitTried}
              orderButtonDisabled={orderButtonDisabled}
              invitedOrderButtonDisabled={invitedOrderButtonDisabled}
              saveMarketingConfig={saveMarketingConfig}
              validateAndPlacePublicOrder={validateAndPlacePublicOrder}
              validateAndPlaceOrder={validateAndPlaceOrder}
              cancelUpdateOrder={cancelUpdateOrder}
              modifyOrder={modifyOrder}
              isSaveDesignEnabled={isSaveDesignEnabled}
              isReservedPreOrder={isReservedPreOrder}
              isBookedPreOrder={isBookedPreOrder}
              enableCyberpunk={enableCyberpunk}
              validateAndSaveDesign={validateAndSaveDesign}
              saveDesignButtonDisabled={saveDesignButtonDisabled}
              showPickupLocation={showPickupLocation}
              pickupLocationError={pickupLocationError}
              isReservation={isReservation}
              isFixedPickupLocation={isFixedPickupLocation}
              showInvoiceOption={showInvoiceOption}
              isPaymentFormOpen={isPaymentFormOpen}
              isPaymentInteractionRequired={isPaymentInteractionRequired}
              isDeliveryDetailsRequired={isDeliveryDetailsRequired}
              isDeliveryDetailsValid={isDeliveryDetailsValid}
              showOrderRefundDisclaimer={showOrderRefundDisclaimer}
              showOrderFeeDisclaimer={showOrderFeeDisclaimer}
              orderRefundWithTransportFee={orderRefundWithTransportFee}
              orderRefund={orderRefund}
              showNonRefundableDisclaimer={showNonRefundableDisclaimer}
              isLayoutMobile={isMobile}
              isLayoutTablet={isTablet}
              isSessionSet={isSessionSet}
              dispatchCheckSession={dispatchCheckSession}
              modelCode={modelCode}
              modelName={modelName}
              hasAlerts={hasAlerts}
              payXUIRef={payXUIRef}
              paymentTabsType={paymentTabsType}
              isUnbuildable={isUnbuildable}
              isDeliverySelectionEnabled={isDeliverySelectionEnabled}
              market={market}
              isNativePaymentEnabled={isNativePaymentEnabled}
              legalConsentValid={legalConsentValid}
              isInventory={isInventory}
              isPostOrderSwap={isPostOrderSwap}
              isPayxPaymentOptionSelected={checkIfpayxPaymentOptionSelected}
              showLegalConsentEditDesign={showLegalConsentEditDesign}
              setTimeOfClickPlaceOrderButton={setTimeOfClickPlaceOrderButton}
              shouldShowEsignProcess={shouldShowEsignProcess}
            >
              <div
                className={classnames(
                  'disclaimer',
                  { 'edit-design': modifyOrder },
                  { 'tds-text--caption tds--vertical_padding': isInventory && !isPostOrderSwap }
                )}
              >
                <OrderLegalDisclaimers displayInline={!!isInventory} />
              </div>
            </PaymentForms>

            {hasApplePayDisclaimerBelow && (paymentType === '' || paymentType === 'CC' || paymentType === PAYMENT_TYPE_APPLEPAY) && disclaimersType && userHasApplePay && (
              <LegalDisclaimers isBelow type={disclaimersType} withRefundPolicy={false} />
            )}

            <If condition={showContactUs}>
              <ContactUsLink />
            </If>

            {/* User auto login */}
            <If condition={connect}>
              <img
                className="user-profile-image"
                src={connect}
                style={{ width: '1px', height: '1px', border: 0 }}
                alt=""
                onLoad={handlePostConnect}
                onError={handlePostConnect}
              />
            </If>
            {/* SECTION END */}
          </div>
        </If>
      </>
    );
  }
}

const { shape, string, number, bool, func, oneOfType, arrayOf } = PropTypes;

PaymentOverviewRepresentation.propTypes = {
  annualLoanInterestRate: number.isRequired,
  annualLeaseInterestRate: number.isRequired,
  modelCode: string.isRequired,
  sibling: string.isRequired,
  locale: string.isRequired,
  paymentTabsType: string.isRequired,
  isEmployee: bool,
  useOrderAgreementRenderM3: bool,
  configuration: arrayOf(string).isRequired,
  externalLinkClicked: func,
  triggerPostConnectRedirect: func.isRequired,
  postConnectUrl: string,
  onPlaceOrder: func.isRequired,
  surveyCompleted: bool.isRequired,
  isSaveDesignLiteEnabled: bool.isRequired,
  isSaveDesignEnabled: bool.isRequired,
  isDownloadPDFEnabled: bool.isRequired,
  options: arrayOf(shape({})).isRequired,
  discounts: arrayOf(shape({})),
  baseConfiguration: arrayOf(shape({})).isRequired,
  model: shape({
    name: string,
  }).isRequired,
  market: string,
  isProfileExists: bool.isRequired,
  showInvoiceOption: bool.isRequired,
  validateAndPlaceOrder: func.isRequired,
  validateAndPlacePublicOrder: func.isRequired,
  cancelUpdateOrder: func.isRequired,
  saveMarketingConfig: func.isRequired,
  submitTried: bool,
  ccDetailsValid: bool.isRequired,
  isDeliveryDetailsValid: bool.isRequired,
  isDeliveryDetailsRequired: bool.isRequired,
  orderPlaced: bool.isRequired,
  userHasApplePay: bool.isRequired,
  depositAmount: number.isRequired,
  modifyOrder: bool.isRequired,
  rnLoaded: bool.isRequired,
  vinLoaded: bool.isRequired,
  isMobile: bool.isRequired,
  isTablet: bool.isRequired,
  financeModalProps: shape({}).isRequired,
  reversePricingOrder: bool.isRequired,
  hideAfterSavingsPrice: bool.isRequired,
  showSavingsAmount: bool.isRequired,
  savingsAmount: string.isRequired,
  paymentTypes: arrayOf(string).isRequired,
  isPaymentDetailsValid: func.isRequired,
  isInvoiceDetailsValid: func.isRequired,
  connect: string,
  asset: shape({}).isRequired,
  financeTabs: arrayOf(shape({})).isRequired,
  selectedFinanceType: string.isRequired,
  beforeSavingsPrice: string.isRequired,
  afterSavingsPrice: string.isRequired,
  priceIncludingSaving: string,
  priceExcludingSaving: string,
  monthlyPayment: string,
  financedAmount: string,
  amountAtSigning: string,
  downPayment: number,
  loanTerm: number,
  loanAfterSaving: string,
  leaseTerm: number,
  leaseAmountDueAtSigning: string,
  leaseMileage: number,
  leaseAfterSaving: string,
  setSaveDesignFlag: func,
  validateAndSaveDesign: func.isRequired,
  cashPrice: shape({}).isRequired,
  cashPriceExVAT: shape({}).isRequired,
  hideDestinationDocFee: bool.isRequired,
  showRightOrder: bool.isRequired,
  showReturnPolicy: bool.isRequired,
  financeTypeLabel: string.isRequired,
  hideAfterSavingsModal: bool.isRequired,
  ecoBonus: number.isRequired,
  hidePaymentModalTrigger: bool.isRequired,
  navigationSelected: bool.isRequired,
  isInventory: bool,
  isUsedInventory: bool,
  isInventoryPriceAdjustmentsEnabled: bool,
  showSecondDepositDisclaimer: bool.isRequired,
  useModelYContent: bool.isRequired,
  hideBaseField: arrayOf(string).isRequired,
  validationErrors: shape({}).isRequired,
  showFormattedMonthlyPayment: bool.isRequired,
  loanApr: number,
  showLegalDataTable: bool,
  showSavingsDisclaimer: bool.isRequired,
  deliveryFee: number.isRequired,
  frtFee: string,
  exlFrtPrice: string,
  OMVICFee: number.isRequired,
  balloonPayment: number,
  grossPrice: oneOfType([bool, number]).isRequired,
  totalAmtPaid: number,
  showOrderPlacementDisclaimerCash: bool.isRequired,
  interestRate: number,
  refundPolicySource: shape({}).isRequired,
  showRegistrationTypeSelector: bool.isRequired,
  showPrpLink: bool,
  isPreSale: oneOfType([string, bool]),
  loanType: string,
  showOrderFee: bool,
  showDOCandOMVICfees: bool,
  showVATBreakdown: bool,
  ACFee: number,
  purchaseTax: string,
  purchaseTaxAmount: number,
  showOrderRefundDisclaimer: bool.isRequired,
  isPaymentFormOpen: bool,
  isPaymentInteractionRequired: bool,
  vehiclePriceWithoutDiscounts: number,
  showTaxAndFeeDisclaimerForUsed: bool,
  isDomestic: bool,
  isOldVersionOrderAgreement: oneOfType([bool, string, shape({})]),
  oldOrderAgreementVersion: string,
  showBeforeSavingsPriceFinancingLabel: bool,
  showNonRefundPolicy: oneOfType([bool, shape({})]),
  showPaymentDisclaimers: oneOfType([bool, string]),
  interstateTransitFee: number,
  deliveryPostalCode: string,
  transportationFee: number,
  isTransportFeeEnabled: bool,
  isTransportFeeCollectionEnabled: bool,
  depositAmountBase: number.isRequired,
  showDistinctTaxTotal: bool,
  destinationAndDocFee: number,
  includeTeslaInsurance: bool,
  insuranceAmount: number,
  hideEstimatedDeliveryOrderPage: bool,
  showOtherFeesinSummary: bool,
  displayPaymentDisclaimer: bool,
  fuelYears: number,
  isReservation: bool,
  isReservedPreOrder: bool,
  isBookedPreOrder: bool,
  monthlyLeasePayment: number,
  financeProductId: string,
  showPricesDetail: bool,
  showOrderFeeDisclaimer: string,
  loanEffectiveRate: number,
  showOtherLoanTerms: bool,
  isEstimatedPrice: bool,
  showDownPaymentInFooter: bool,
  onclickFinanceLink: func,
  showPurchasePrice: bool,
  showPaymentModalTriggerBelowPrice: bool,
  showSavingsAmountTrigger: bool,
  showSavingsAmountInFooter: bool,
  reservationPriceLabel: string,
  showNonRefundableDisclaimer: bool,
  setPricingDetails: func,
  orderPageDisclaimerCopySrc: string,
  disclaimerIntlDateFormat: shape({}),
  isSessionSet: bool.isRequired,
  dispatchCheckSession: func,
  isCollapsedFlow: bool.isRequired,
  isSinglePage: bool,
  displayAsset: bool,
  isAsset: bool,
  withAsset: bool,
  classes: string,
  showRefundPolicyDisclaimer: bool,
  isFixedPickupLocation: bool,
  fuelReward: shape({
    amount: number,
    title: string,
    formattedAmtWithMinus: string,
  }),
  enableCyberpunk: bool,
  disclaimerSectionAboveOrderButton: string,
  showTerritoryList: shape({}),
  showFleetSalesRegionList: bool,
  showRegistrationAboveSummary: bool,
  showContactUs: bool,
  financeProductByType: oneOfType([shape({}), arrayOf(shape({}))]),
  setRegion: func,
  setDrivertype: func,
  triggerSelectTab: func,
  selectedFinanceProduct: string,
  isAccountDetailsValid: func.isRequired,
  legalConsentValid: bool,
  showLegalConsentAfterPayX: arrayOf(string),
  orderAgreementUrl: string,
  financeMiniDisclaimer:  oneOfType([shape({}), string]),
  firstMonthDisclaimer: string,
  extraPricingContextAmounts: arrayOf(
    shape({
      currency: string,
      grossPrice: number,
    })
  ),
  isPickupOnlyEnabled: bool,
  hasAlerts: bool,
  showAvailableInState: bool,
  showElectronicPayment: bool,
  isUnbuildable: bool,
  deliveryAndRegistrationDisclaimer: string,
  showVehiclePricePlusFees: bool,
  deliveryRegionDisclaimer: string,
  fleetSalesRegions: arrayOf(shape({})),
  showEvSubsidyDisclaimer: bool,
  showFeesSummary: bool,
  secondPaymentAltCurrency: oneOfType([string, number]),
  isEditDesignPreserved: bool,
  isQtyInfoValid: bool,
  showFinanceDisclaimerOrderPage: bool,
  isAccessoriesValid: bool,
  financeDisclaimer: string,
  payXUIRef: shape({}),
  isNativePaymentEnabled: bool,
  displayOtherEUDisclaimer: bool,
  isDeliverySelectionEnabled: bool,
  showFinancialTabsEnabled: bool,
  showVehicleConfigurationPrice: bool,
  showPriceWithCOE: bool,
  priceData: shape({}),
  regionalDisclaimer: string,
  hasRegionalBannerModalCopy: bool,
  showLegalConsentEditDesign: bool,
  setTimeOfClickPlaceOrderButton: func,
  isReservationFlow: bool,
  shouldShowEsignProcess: bool,
};

PaymentOverviewRepresentation.defaultProps = {
  annualLoanInterestRate: 0.0,
  annualLeaseInterestRate: 0.0,
  showTaxAndFeeDisclaimerForUsed: false,
  isDomestic: false,
  isEmployee: false,
  useOrderAgreementRenderM3: false,
  externalLinkClicked: () => {},
  postConnectUrl: '',
  submitTried: false,
  connect: null,
  priceIncludingSaving: null,
  priceExcludingSaving: null,
  monthlyPayment: null,
  paymentTabsType: 'tabs',
  financedAmount: null,
  amountAtSigning: null,
  leaseAfterSaving: null,
  loanAfterSaving: null,
  leaseTerm: null,
  leaseAmountDueAtSigning: null,
  leaseMileage: null,
  setSaveDesignFlag: null,
  isInventory: false,
  isUsedInventory: false,
  isInventoryPriceAdjustmentsEnabled: false,
  showLegalDataTable: false,
  frtFee: null,
  balloonPayment: null,
  totalAmtPaid: null,
  showPrpLink: false,
  isPreSale: null,
  loanApr: null,
  interestRate: null,
  loanTerm: null,
  loanType: null,
  showDOCandOMVICfees: true,
  showVATBreakdown: false,
  showNonRefundPolicy: false,
  isPaymentFormOpen: true,
  isPaymentInteractionRequired: false,
  vehiclePriceWithoutDiscounts: 0,
  showBeforeSavingsPriceFinancingLabel: true,
  isOldVersionOrderAgreement: null,
  oldOrderAgreementVersion: '',
  showOrderFee: false,
  ACFee: 0,
  purchaseTax: '',
  purchaseTaxAmount: 0,
  showPaymentDisclaimers: true,
  interstateTransitFee: 0,
  deliveryPostalCode: '',
  transportationFee: 0,
  isTransportFeeEnabled: false,
  isTransportFeeCollectionEnabled: false,
  showDistinctTaxTotal: false,
  destinationAndDocFee: 0,
  includeTeslaInsurance: false,
  insuranceAmount: 0,
  hideEstimatedDeliveryOrderPage: false,
  showOtherFeesinSummary: false,
  displayPaymentDisclaimer: '',
  fuelYears: 0,
  isReservation: false,
  isReservedPreOrder: false,
  isBookedPreOrder: false,
  monthlyLeasePayment: 0,
  market: '',
  financeProductId: '',
  showPricesDetail: false,
  showOrderFeeDisclaimer: false,
  loanEffectiveRate: 0,
  showOtherLoanTerms: false,
  isEstimatedPrice: false,
  showDownPaymentInFooter: false,
  onclickFinanceLink() {},
  showPurchasePrice: false,
  showPaymentModalTriggerBelowPrice: false,
  showSavingsAmountTrigger: false,
  showSavingsAmountInFooter: false,
  reservationPriceLabel: '',
  showNonRefundableDisclaimer: false,
  setPricingDetails() {},
  orderPageDisclaimerCopySrc: '',
  dispatchCheckSession: () => {},
  disclaimerIntlDateFormat: {},
  isSinglePage: false,
  isAsset: false,
  withAsset: false,
  classes: '',
  showRefundPolicyDisclaimer: false,
  exlFrtPrice: null,
  isFixedPickupLocation: false,
  discounts: [],
  fuelReward: null,
  enableCyberpunk: false,
  disclaimerSectionAboveOrderButton: null,
  showTerritoryList: [],
  showFleetSalesRegionList: false,
  showRegistrationAboveSummary: false,
  showContactUs: false,
  financeProductByType: [],
  setRegion: () => {},
  setDrivertype: () => {},
  triggerSelectTab: () => {},
  selectedFinanceProduct: '',
  legalConsentValid: true,
  showLegalConsentAfterPayX: [],
  orderAgreementUrl: '',
  financeMiniDisclaimer: '',
  firstMonthDisclaimer: '',
  extraPricingContextAmounts: [],
  isPickupOnlyEnabled: false,
  hasAlerts: false,
  displayAsset: false,
  downPayment: 0,
  showAvailableInState: false,
  showElectronicPayment: false,
  isUnbuildable: false,
  deliveryAndRegistrationDisclaimer: '',
  showVehiclePricePlusFees: false,
  deliveryRegionDisclaimer: '',
  fleetSalesRegions: [],
  showEvSubsidyDisclaimer: false,
  showFeesSummary: false,
  secondPaymentAltCurrency: '',
  isEditDesignPreserved: false,
  options: [],
  baseConfiguration: [],
  isQtyInfoValid: true,
  showFinanceDisclaimerOrderPage: false,
  isAccessoriesValid: false,
  financeDisclaimer: '',
  isNativePaymentEnabled: false,
  displayOtherEUDisclaimer: false,
  isDeliverySelectionEnabled: false,
  showVehicleConfigurationPrice: false,
  showPriceWithCOE: false,
  priceData: null,
  regionalDisclaimer: '',
  hasRegionalBannerModalCopy: false,
  showLegalConsentEditDesign: false,
  setTimeOfClickPlaceOrderButton: () => {},
  isReservationFlow: false,
  shouldShowEsignProcess: false,
};

PaymentOverviewRepresentation.contextTypes = {
  i18n: func,
};

import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { string, bool } from 'prop-types';
import { Form, Select } from '@tesla/informed-tds';
import { updatePickupLocationProvinceData } from 'actions';
import { pickupLocationChange, resetPickupLocationEntityId } from '../../reducers';
import _get from "lodash/get";

const getSelectedValue = (pickupLocationsFormatted, defaultPickupLocation) => {
  const selectedVal = pickupLocationsFormatted?.find(
    elem => parseInt(elem.value) === defaultPickupLocation
  );
  return selectedVal?.value || '';
};
const getSelectedProvince = (pickupLocationsFormatted, defaultPickupLocation) => {
  const selectedElem = pickupLocationsFormatted?.find(
    elem => parseInt(elem.value) === defaultPickupLocation
  );
  return selectedElem?.province || '';
};

const PickupLocation = props => {
  const { label, error, locationLabel } = props;
  const ReviewDetails = useSelector(state => state.ReviewDetails);
  const Payment = useSelector(state => state.Payment);
  const trigger = _get(Payment, 'PaymentDetail.BillingInfoDetail.trigger', false);
  const { isFixedPickupLocation = false, product = {} } = ReviewDetails;
  const fleetAssignedLocationId = ReviewDetails?.product?.data?.FleetAssignedLocationId;
  const defaultPickupLocation =
    Payment?.PaymentDetail?.BillingInfoDetail?.PickupLocation || Payment?.defaultPickupLocation;
  const pickupLocationsFormatted = Payment?.PickupLocationsFormatted;
  const dispatch = useDispatch();
  const formApiRef = useRef();
  const pickupLocation = getSelectedValue(pickupLocationsFormatted, defaultPickupLocation);
  const showLabelOnPickupLocation = isFixedPickupLocation && fleetAssignedLocationId;

  const isComboInventory = product?.isComboInventory;
  const isUsedInventory = product?.isUsedInventory;
  const isComboHashInventory =
    ReviewDetails?.enableInventoryNewVehicleCombo && isComboInventory && !isUsedInventory;
  const [isProvinceChange, setIsProvinceChange] = useState(false);
  useEffect(() => {
    if (pickupLocation > 0) {
      const pickupLocationProvince = getSelectedProvince(
        pickupLocationsFormatted,
        defaultPickupLocation
      );

      if (isProvinceChange) {
        formApiRef?.current?.setValue('pickupLocationsProvince', pickupLocationProvince);
        formApiRef?.current?.setValue('pickupLocation', pickupLocation);
      }
    } else {
      formApiRef?.current?.setValue('pickupLocation', '');
    }

    if (error) {
      formApiRef?.current?.setError('pickupLocation', error, { touch: true });
    }
  }, [pickupLocation, error, trigger]);

  const handleProvinceChange = value => {
    setIsProvinceChange(true);
    dispatch(updatePickupLocationProvinceData(value));
  };

  const handlePickupLocationChange = value => {
    setIsProvinceChange(false);
    const currentPickupLocation = parseInt(value);
    if (currentPickupLocation > 0) {
      dispatch(pickupLocationChange(currentPickupLocation));
    }
  };

  useEffect(() => {
    const options = Payment?.PickupLocationsFormatted ?? [];
    const validPickupLocationOption = options.filter(({ value }) => !!value);
    const optionInfo = _.first(validPickupLocationOption);
    if (validPickupLocationOption.length === 1 && pickupLocation === optionInfo?.value) {
      handlePickupLocationChange(pickupLocation);
    }
  }, []);

  return (
    <Form formApiRef={formApiRef}>
      <If condition={!showLabelOnPickupLocation && !isComboHashInventory}>
        <Select
          name="pickupLocationsProvince"
          label={label}
          options={Payment?.PickupLocationsProvinceFormatted}
          onChange={({ value }) => handleProvinceChange(value)}
        />
      </If>
      <Select
        name="pickupLocation"
        label={isComboHashInventory ? '' : showLabelOnPickupLocation ? label : locationLabel}
        options={Payment?.PickupLocationsFormatted}
        initialValue={pickupLocation}
        onChange={({ value }) => handlePickupLocationChange(value)}
      />
    </Form>
  );
};

PickupLocation.propTypes = {
  label: string.isRequired,
  error: bool.isRequired,
  locationLabel: string.isRequired,
};

export default PickupLocation;

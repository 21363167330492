import React from 'react';
import { connect } from 'react-redux';
import { bool, shape, oneOfType } from 'prop-types';
import _indexOf from 'lodash/indexOf';
import _get from 'lodash/get';
import { i18n, getLoadedRN } from 'utils';
import { PICKUP_LOCATION, FIELD_PICKUP_LOCATION } from 'dictionary';
import AccountForm from '../../../components/AccountForm';
import PickupLocation from '../../../components/PickupLocation';
import InvoiceOptions from '../../../components/InvoiceOptions';
import DeliveryDetailsForm from '../../../components/AccountForm/DeliveryDetails';
import AccessoriesShipping from './AccessoriesShipping';
import AccessoriesInstallation from './AccessoriesInstallation';
import cx from 'classnames';
import { isAccessoriesSelected, isAccessoriesEligibleForTax } from 'selectors';

const AccountSection = ({
  rnLoaded,
  showPickupLocation,
  isFixedPickupLocation,
  pickupLocationError,
  showInvoiceOption,
  hasUserSelectedAccesories,
  showDeliveryForm,
  isTaxApplicableForAccessories,
}) => {
  const accountDisclaimer = i18n('Review.account_disclaimer', null, null, { returnNullWhenEmpty: true });
  return (
    <>
      <If condition={showDeliveryForm}>
        <form>
          <DeliveryDetailsForm classNames="delivery-location--container__padded" />
        </form>
      </If>
      <If condition={!rnLoaded}>
        <legend className="tds-text--h4">{i18n('Review.account_label')}</legend>
        {accountDisclaimer && <p className="tds-text--caption">{accountDisclaimer}</p>}
        <div className={cx({'tds--vertical_padding-bottom--4x': !hasUserSelectedAccesories && !isTaxApplicableForAccessories})}>
        <AccountForm title={i18n('Review.account_label')} />
        </div>
      </If>
      <If condition={hasUserSelectedAccesories && isTaxApplicableForAccessories}>
        <AccessoriesShipping />
        <AccessoriesInstallation />
      </If>
      <If condition={showPickupLocation}>
        <div className="pickup-location--container">
          <div className="field-container pickup_location">
            <div className="review-page--header">{i18n('Review.pickup_location_title')}</div>
            <>
              <PickupLocation
                label={
                  isFixedPickupLocation
                    ? i18n('Review.BillingInfo.Form.pickup_fixed_location_label')
                    : i18n('Review.BillingInfo.Form.pickup_select_province')
                }
                locationLabel={i18n('Review.BillingInfo.Form.pickup_select_location')}
                error={pickupLocationError}
              />
            </>
          </div>
        </div>
      </If>
      <If condition={showInvoiceOption}>
        <div className="invoicing-options--container">
          <div className="field-container invoice_options">
            <div className="review-page--header">{i18n('InvoiceType.InvoiceOptions')}</div>
            <InvoiceOptions />
          </div>
        </div>
      </If>
    </>
  );
};

function mapStateToProps(state) {
  const { ReviewDetails, Payment, App } = state;
  const {
    isDeliveryDetailsRequired,
    hideBaseField = [],
    isFixedPickupLocation = false,
    isDeliveryDetailsRequiredOnOrderFlow,
  } = ReviewDetails;
  const { isDeliverySelectionEnabled } = App;
  const { validationErrors = {}, showInvoiceOption = false } = Payment;
  const { [FIELD_PICKUP_LOCATION]: pickupLocationError = false } = validationErrors;
  const showPickupLocation = _indexOf(hideBaseField, PICKUP_LOCATION) < 0;

  return {
    rnLoaded: getLoadedRN(),
    showPickupLocation,
    isFixedPickupLocation,
    pickupLocationError,
    showInvoiceOption,
    hasUserSelectedAccesories: isAccessoriesSelected(state),
    showDeliveryForm:
      !isDeliverySelectionEnabled &&
      (isDeliveryDetailsRequired || isDeliveryDetailsRequiredOnOrderFlow),
    isTaxApplicableForAccessories: isAccessoriesEligibleForTax(state),
  };
}

AccountSection.propTypes = {
  rnLoaded: bool,
  isDeliveryDetailsRequired: bool,
  showPickupLocation: bool,
  isFixedPickupLocation: bool,
  pickupLocationError: oneOfType([shape({}), bool]),
  showInvoiceOption: bool,
  isDeliveryDetailsRequiredOnOrderFlow: bool,
  hasUserSelectedAccesories: bool,
  showDeliveryForm: bool,
};

AccountSection.defaultProps = {
  rnLoaded: false,
  isDeliveryDetailsRequired: false,
  showPickupLocation: false,
  isFixedPickupLocation: false,
  pickupLocationError: {},
  showInvoiceOption: false,
  isDeliveryDetailsRequiredOnOrderFlow: false,
  hasUserSelectedAccesories: false,
  showDeliveryForm: false,
};

export default connect(mapStateToProps)(AccountSection);
import React from 'react';
import { shape, arrayOf, string } from 'prop-types';
import classnames from 'classnames';
import _has from 'lodash/has';
import Copy from './Copy';
import ImproveYourDrive from './ImproveYourDrive';
import Table from './Table';
import Title from './Title';
import Disclaimer from './Disclaimer';
import DynamicGrid from './DynamicGrid';
import ActionTarget from '../ActionTarget';
import FormattedPrice from './FormattedPrice';
import Options from './Options';
import SelectedPrice from './SelectedPrice';
import CompareFeatures from './CompareFeatures';
import DeliveryDate from './DeliveryDate';
import MediaControls from '../MediaControls';

const availableComponents = {
  Copy,
  Title,
  DynamicGrid,
  ActionTarget,
  FormattedPrice,
  Disclaimer,
  Options,
  SelectedPrice,
  Table,
  ImproveYourDrive,
  CompareFeatures,
  DeliveryDate,
  MediaControls,
};

/* DynamicComponent used inside a CarouselModal & CarouselModal FeatureGroup */
const GroupComponent = ({ components, group, classes }) => {
  if (!components || !components.length || !group) {
    return null;
  }
  return (
    <div className={classnames({ [classes]: classes })}>
      <If condition={components.length}>
        {components.map((component, index) => {
          const { type, props = {} } = component;
          if (!_has(availableComponents, type)) {
            return null;
          }
          const componentProps = { group, ...props };
          const ComponentName = availableComponents[type];
          return <ComponentName key={`GroupComponent_${type}_${group.code}_${index}`} {...componentProps} />;
        })}
      </If>
    </div>
  );
};

GroupComponent.propTypes = {
  group: shape({}).isRequired,
  components: arrayOf(shape({})).isRequired,
  classes: string,
};

GroupComponent.defaultProps = {
  classes: '',
};

export default GroupComponent;
